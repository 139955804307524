import { useEffect, useState } from "react";
// import { ProgressBar } from "react-bootstrap";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

interface progressProps {
  completedStatus?: number;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width:128,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D8D8D8",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? "#1CBA8A" : '#308fe8',
  },
}));

const ProgressBarComponent = ({ completedStatus }: progressProps) => {
  return (
    <>
      <label>{`${completedStatus}%`} completed</label>
      <BorderLinearProgress variant="determinate" value={completedStatus} />
    </>
  );
};

export default ProgressBarComponent;
