import { gql } from '@apollo/client';

export const IDFC_FIRST_LOAN = gql`
	mutation ApplyIDFCLoan($input: ApplyLTLoanInput) {
		applyIDFCLoan(input: $input) {
			applyIDFCLoanErrors {
				message
			}
			message
			status
			url
		}
	}
`;
