import { gql } from '@apollo/client';

export const REGISTER = gql`mutation Register($input: UserRegisterInput) {
        register(input: $input) {
          RegisterErrors {
            message
          }
          message
          status
        }
      }`;


