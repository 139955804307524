import ReactGA from 'react-ga4';
import env from '../config/config';

type EventParams = {
	[key: string]: string | number | boolean;
};

const trackEvent = (
	category: string,
	action: string,
	label: string,
	params: EventParams = {},
) => {
	ReactGA.initialize(env.GOOGLE_ANALYTICS_ID as string);
	ReactGA.event({
		category,
		action,
		label,
		...params,
	});
};

export const gaEvents = {
	trackHomepageView: () => {
		trackEvent('Website Interaction', 'Home_Page_view', 'Home Page', {
			Page_Name: 'Home Page',
			Platform: detectPlatform(),
			is_first_session: checkIfFirstSession(),
			Time_Stamp_Homepage_View: new Date().toISOString(),
		});
		ReactGA.send({ hitType: "pageview", page: "/", page_title:"Website Interaction" });

	},

	trackLoginClick: (userId: string) => {
		trackEvent('Button Click', 'Login_clicked', 'Login', {
			Login_Button: 'Login',
			User_ID: userId,
			Time_Stamp_Login: new Date().toISOString(),
		});
	},

	trackGetStartedClick: (userId: string) => {
		trackEvent('Button Click', 'Get_Started_Clicked', 'Get Started', {
			Get_Started_Button: 'Get Started',
			User_ID: userId,
			Time_Stamp_Get_Started: new Date().toISOString(),
		});
	},

	trackCreditReportClick: (userId: string) => {
		trackEvent(
			'Button Click',
			'Credit_Report_CTA_click',
			'Free_Credit_Report',
			{
				Credit_Report_Button: 'Free_Credit_Report',
				User_ID: userId,
				Time_Stamp_Credit_Report: new Date().toISOString(),
			},
		);
	},

	trackBookConsultClick: (userId: string) => {
		trackEvent('Button Click', 'Book_Consult_CTA_click', 'Book_Consult', {
			Book_Consult_Button: 'Book_Consult',
			User_ID: userId,
			Time_Stamp_Consult: new Date().toISOString(),
		});
	},

	trackGuidesClick: (userId: string) => {
		trackEvent('Link Click', 'Guides_clicked', 'User Guides', {
			Link_Guides: 'User Guides',
			Screen_name_Guides: 'Home_Page',
			Time_Stamp_Guides: new Date().toISOString(),
			User_ID: userId,
		});
	},

	trackToolsClick: (userId: string) => {
		trackEvent('Link Click', 'Tools_clicked', 'Tools', {
			Link_Tools: 'Tools',
			Screen_name_Tools: 'Home_Page',
			Time_Stamp_Guides: new Date().toISOString(),
			User_ID: userId,
		});
	},

	trackHelpCentreClick: (userId: string) => {
		trackEvent('Link Click', 'Help_Centre_clicked', 'Help Centre', {
			Link_Help_Centre: 'Help Centre',
			Screen_name_Help_Centre: 'Home_Page',
			Time_Stamp_Help_Centre: new Date().toISOString(),
			User_ID: userId,
		});
	},

	trackEmiCalculatorInteraction: (
		userId: string,
		interactionType: 'loan' | 'tenure' | 'interest',
		value: number,
	) => {
		const eventName = {
			loan: 'Loan_entered_EMI_calculator',
			tenure: 'Tenure_EMI_calculator',
			interest: 'Interest_EMI_calculator',
		}[interactionType];

		const valueKey = {
			loan: 'Loan_amount_entered',
			tenure: 'Tenure_amount',
			interest: 'Interest_amount',
		}[interactionType];

		trackEvent('Website Interaction', eventName, '', {
			User_ID: userId,
			[`Time_Stamp_EMI_${
				interactionType.charAt(0).toUpperCase() + interactionType.slice(1)
			}`]: new Date().toISOString(),
			[valueKey]: value,
		});
	},

	trackEmiCalculateClick: (userId: string) => {
		trackEvent('Button Click', 'Calculate_clicked_EMI_calculator', '', {
			User_ID: userId,
			Time_Stamp_EMI_Calculate: new Date().toISOString(),
			Calculate_EMI_clicked: 'Calculate',
		});
	},
};

const detectPlatform = (): string => {
	const userAgent = window.navigator.userAgent;
	const platform = window.navigator.platform;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

	if (macosPlatforms.indexOf(platform) !== -1) {
		return 'Mac OS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		return 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		return 'Windows';
	} else if (/Android/.test(userAgent)) {
		return 'Android';
	} else if (/Linux/.test(platform)) {
		return 'Linux';
	}

	return 'Unknown';
};
const checkIfFirstSession = (): boolean => {
	const firstSessionKey = 'first_session';
	const storedValue = localStorage.getItem(firstSessionKey);

	if (storedValue === null) {
		localStorage.setItem(firstSessionKey, 'false');
		return true;
	}

	return false;
};

export default gaEvents;
