import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExperianIcon from './ExperianIcon';
import { formatMonthYear } from '../utils';

const GaugeContainer = styled(Box)({
    position: 'relative',
    width: 300,
    height: 180,
    backgroundColor: 'white',
    textAlign:'center'
});

const DateText = styled(Typography)({
    position: 'absolute',
    top: 80,
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: 18,
    fontWeight: 500,
    color: '#666',
});

const ScoreText = styled(Typography)({
    position: 'absolute',
    top: 120,
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: 40,
    fontWeight: 'bold',
    color: '#333',
});

const MinMaxText = styled(Typography)({
    position: 'absolute',
    bottom: 10,
    fontSize: 12,
    color: '#666',
});




const CreditScoreGauge = ({ score = 600 }) => {
    const [animatedScore, setAnimatedScore] = useState(300);
    const [animatedAngle, setAnimatedAngle] = useState(0);

    const minScore = 300;
    const maxScore = 900;

    useEffect(() => {
        const animationDuration = 1000; 
        const steps = 60; 
        const scoreStep = (score - minScore) / steps;
        const angleStep = (180 * ((score - minScore) / (maxScore - minScore))) / steps;

        let currentStep = 0;

        const interval = setInterval(() => {
            if (currentStep < steps) {
                setAnimatedScore(prev => Math.min(prev + scoreStep, score));
                setAnimatedAngle(prev => Math.min(prev + angleStep, 180 * ((score - minScore) / (maxScore - minScore))));
                currentStep++;
            } else {
                clearInterval(interval);
            }
        }, animationDuration / steps);

        return () => clearInterval(interval);
    }, [score]);

    const radius = 120;
    const centerX = 150;
    const centerY = 150;
    const startAngle = Math.PI;
    const endAngle = startAngle - (animatedAngle * Math.PI / 180);
    const circleX = centerX + radius * Math.cos(endAngle);
    const circleY = centerY - radius * Math.sin(endAngle);

    return (
        <GaugeContainer>
            
            <svg width="300" height="180" viewBox="0 0 300 180">
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="30%" stopColor="#FF5C5C" />
                        <stop offset="50%" stopColor="#FF7F7F" />
                        <stop offset="70%" stopColor="#FFA726" />
                        <stop offset="100%" stopColor="#66BB6A" />
                    </linearGradient>
                </defs>
                <path
                    d={`M 30 150 A ${radius} ${radius} 0 0 1 270 150`}
                    fill="none"
                    stroke="#EEEEEE"
                    strokeWidth="7"
                    strokeLinecap="round"
                />
                <path
                    d={`M 30 150 A ${radius} ${radius} 0 0 1 ${circleX} ${circleY}`}
                    fill="none"
                    stroke="url(#gradient)"
                    strokeWidth="7"
                    strokeLinecap="round"
                />
                <circle
                    cx={circleX}
                    cy={circleY}
                    r="7"
                    fill="white"
                    stroke="#66BB6A"
                    strokeWidth="2"
                />
            </svg>
            <DateText>{formatMonthYear(new Date())}</DateText>
            <ScoreText>{Math.round(animatedScore)}</ScoreText>
            <Box sx={{marginTop:"5px","display":"flex",alignItems:"center",marginLeft:"75px"}}>
            <ExperianIcon height={38} width={250}/>
            </Box>
            <MinMaxText sx={{ left: 22 }}>{minScore}</MinMaxText>
            <MinMaxText sx={{ right: 22 }}>{maxScore}</MinMaxText>
        </GaugeContainer>
    );
};

export default CreditScoreGauge;