export const DecideExpense = () => {
  return (
    <>
      <section className="blog-container-width">
        <p
          style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}
        >
          <span style={{ color: "#000000" }}>
            <span>Pre-Wedding Loan Checklist:</span>
            <span>&nbsp;</span>
          </span>
          <span>How Do You Decide Your </span>
          <br />
          <span>Wedding Expenses?</span>
        </p>
        <div className="mt-4">
          <span>
            Determining the financial scope of your wedding can be both exciting
            and daunting. With countless decisions to make—from the venue and
            catering to entertainment and decor—managing your wedding expenses
            can feel overwhelming. Deciding the expenses requires careful
            planning, prioritisation and a clear understanding of your financial
            limitations. If you’re considering applying for a loan to finance
            your wedding, it’s essential to create a well-thought-out budget to
            understand how your wedding expenses will pan out. Having a clear
            financial plan will help you determine how much to borrow and ensure
            that you don’t overspend.{" "}
          </span>
          <br />
          <span>&nbsp;</span>
        </div>
        <span>
          So, we’ve created a pre-wedding loan checklist that will guide you in
          making smart financial decisions as you plan for your dream wedding! |{" "}
        </span>
        <div>&nbsp;</div>
        <div className="heading-style text-center">
          How Do You Decide Your Wedding Expenses?
        </div>
        <div>
          <span className="subheading">1. Establish An Overall Budget</span>
          <br />
          <span>
            The first step in deciding your wedding expenses is to set an
            overall budget. This figure will be the foundation of all your
            financial decisions and will help you prioritise and allocate funds
            properly. Here are some things to consider while deciding this:
          </span>
          <ul className="expenses-list">
            <li>
              <strong>Savings:</strong>
              <span className="ml-10">
                Evaluate how much you and your partner have saved specifically
                for the wedding. This will give you a solid starting point.
              </span>
            </li>
            <li>
              <strong>Family Contributions:</strong>
              <span className="ml-10">
                Often, family members offer financial support. Have an open
                discussion to understand how much they are willing to
                contribute.
              </span>
            </li>
            <li>
              <strong>Wedding Loan:</strong>
              <span className="ml-10">
                If savings and contributions fall short of covering the entire
                cost, consider taking a wedding loan. However, be mindful of the
                interest rates and repayment terms and avoid borrowing more than
                you can comfortably repay.
              </span>
            </li>
          </ul>
          <div>&nbsp;</div>
          <span className="subheading">2. Prioritise What Matters Most</span>
          <br />
          <span className="mt-10">
            Every couple has different priorities when it comes to their
            wedding. Some focus on having a luxurious venue, while others place
            higher importance on catering or entertainment. To allocate your
            funds properly, you must decide what matters the most to you.
            Consider the following high-priority wedding expenses while making
            these decisions:
          </span>
          <ul className="expenses-list">
            <li>
              <strong>Venue:</strong>
              <span className="ml-10">
                This is a major expense, typically consuming 30-40% of your
                budget. Costs vary based on location and season, whether you
                choose a grand hall or an intimate setting.
              </span>
            </li>
            <li>
              <strong>Catering:</strong>
              <span className="ml-10">
                Food and drinks can account for 25-35% of your budget. Plan your
                guest list and menu early to manage costs effectively.
              </span>
            </li>
            <li>
              <strong>Photography/Videography:</strong>
              <span className="ml-10">
                Services can range from ₹50,000 to ₹5 lakhs, depending on your
                preferences. While capturing memories is crucial, high quality
                can be costly.
              </span>
            </li>
            <li>
              <strong>Attire:</strong>
              <span className="ml-10">
                Bridal and groom outfits can take up 10-15% of the budget.
                Decide how much to invest in attire, jewellery and accessories
                based on your priorities.{" "}
              </span>
            </li>
          </ul>
          <div>&nbsp;</div>
          <span className="subheading">
            3. Divide Your Expenses Into Categories
          </span>
          <br />
          <span>
            To manage your wedding finances efficiently, it's crucial to divide
            your budget into specific categories. This approach provides a clear
            overview of your spending and ensures that no costs are overlooked.
            By creating a detailed breakdown, you can stay organized and make
            adjustments within each category to align with your overall budget.
            Typical categories to consider include Venue and Decor, Catering,
            Photography/ Videography, Invitations, Entertainment, Wedding
            Attire, Accommodations, Transportation and Miscellaneous (e.g.
            beauty services, mehndi, licenses).
          </span>
          <div>&nbsp;</div>

          <span className="subheading">4. Research Costs and Get Quotes</span>
          <br />
          <span>
            After identifying your expense categories, the next step is to
            research the costs of each service and item. Collect quotes from
            several vendors to understand what fits within your budget. Keep in
            mind that prices can differ significantly based on factors like
            location, season and the quality of service.
          </span>
          <div>&nbsp;</div>
          <span>
            For example, in metro cities like Delhi or Mumbai, a venue could
            cost ₹5 lakhs or more, while a similar one in a smaller city might
            be half the price. Understanding the market rates for your area will
            help you make informed decisions and negotiate better deals.
          </span>
          <br />
          <div>&nbsp;</div>

          <span className="subheading">5. Limit the Guest List</span>
          <br />
          <span>
            One of the most effective ways to manage your wedding expenses is by
            limiting the guest list. The number of attendees directly affects
            costs such as catering, venue size and seating arrangements. Each
            guest increases the per-plate cost, which in Indian weddings can
            range from ₹1,000 to ₹3,000 or higher. Trimming the guest list to
            close family and friends not only reduces your overall expenses but
            also allows for a more intimate and personal celebration.
          </span>
          <br />
          <div>&nbsp;</div>
          <span className="subheading">6. Factor in Hidden Costs</span>
          <br />
          <span className="mt-10">
            When deciding on your wedding expenses, be aware of hidden or
            unexpected costs. These might include:
          </span>
          <ul className="expenses-list">
            <li>
              Taxes and service charges (which can generally add 10-20% to your
              venue or catering bills)
            </li>
            <li>Last-minute vendor upgrades or changes</li>
            <li>Transportation or accommodations for vendors</li>
            <li>
              Weather-related contingencies (like renting tents or heaters for
              an outdoor wedding)
            </li>
            <li>Gratuities for staff and vendors</li>
          </ul>
          <div className="mt-10">
            Set aside 10-15% of your budget for such unexpected expenses. This
            emergency fund will ensure you’re prepared for any last-minute
            changes.{" "}
          </div>
          <div>&nbsp;</div>
          <span className="subheading">7. Track Your Spending</span>
          <br />
          <span>
            As you make payments and deposits, track your expenses in real time
            using a spreadsheet or budgeting app. Record all payments, quotes
            and balances to stay within budget and adjust as needed. For
            instance, if catering costs exceed expectations, you might reduce
            spending on decor or entertainment. Tracking helps you stay on top
            of outstanding payments and deadlines, avoiding surprises as the
            wedding date nears.
          </span>
          <div>&nbsp;</div>
          <span className="subheading">8. Evaluate Financing Options Carefully</span>
          <br />
          <span>
             Lastly, if you're considering a wedding loan, review all financing options carefully. Choose loans with favourable interest rates, flexible repayment terms and low fees. Compare offers from banks and NBFCs, but avoid high-interest debt. Make sure the monthly repayments fit comfortably within your post-wedding budget.
        </span>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        In conclusion, deciding on your wedding expenses requires thoughtful planning and a realistic approach to budgeting. Careful planning ensures you stay on track, avoid debt and enjoy your special day without worrying about your financial future!

        </div>
      </section>
    </>
  );
};
