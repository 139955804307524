import { Button } from '@mui/material';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';

interface buttonProps {
    text: string;
    isDisabled?: boolean;
    type?: "button" | "submit";
    className?: string;
    loading?: boolean;
    key?:number;
    onClick?:() => void;
}

 
const AppButton = ({ text, isDisabled = false, type, className = "app-btn", loading = false, key,onClick}: buttonProps) => {
    return (
        <Button
            className={`${className + " button-default-class"}`}
            disabled={isDisabled}
            type={type}
            disableRipple
            onClick={onClick}
            key={key}
        >{text}
            {loading && <span className="ms-2 d-flex align-items-center"><LoadingSpinner/></span>}</Button>
    )
}

export default AppButton;



