import { validateMobileNumber } from "../../utils";
import { ValidationErrorMessage } from "../../ValidationError";
import mobileIcon from "../../assets/mobile-Icon.gif";
import { TextField, FormControl, Box, Checkbox, FormHelperText } from '@mui/material';
import {checkBoxInputStyle} from "../../pages/DefaultCheckboxInputStyle";


interface mobileNumberProps {
    mobileNumber: string;
    isChecked: boolean;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}




const MobileNumberModal = ({ mobileNumber, isChecked, handleInputChange }: mobileNumberProps) => {

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/^\d$/.test(e.key)  && !["Backspace", "Delete", "Tab", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(e.key)) {
            e.preventDefault();
        }
    }

    return (
        <>
            <img src={mobileIcon} alt="mobile-Icon" className="icon-gif" />
            <div className='fs-24 heading mt-1'>What is your 10-digit mobile number</div>
            <Box sx={{ mt: 4 }}>
                <FormControl fullWidth>
                    <TextField id="outlined-basic"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                        // onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                        label="Mobile Number" variant="outlined"
                        name='mobileNumber'
                        type='number'
                        InputProps={{ inputProps: { min: 0, max: 10 } }}
                        error={mobileNumber !== "" && validateMobileNumber(mobileNumber) === false}
                        autoFocus
                        onKeyDown={(e:React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
                        value={mobileNumber} />
                    <FormHelperText
                        // error={Boolean(mobileNumber !== "" && validateMobileNumber(mobileNumber) === false)}
                        sx={{color:"#FF3333 !important",margin:"6px 0px 0px 0px !important"}}
                    >
                        <span>{(mobileNumber !== "" && validateMobileNumber(mobileNumber) === false) ? ValidationErrorMessage.mobileValidationError : ""}</span>
                    </FormHelperText>
                </FormControl>
                <div className="d-flex">
                    <Checkbox
                        name="isChecked"
                        checked={isChecked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                        sx={checkBoxInputStyle}
                        // className="check-box-input"
                        disableRipple />
                    <div className="check-label">
                        By logging in, you agree to the following {" "}
                        {/* <a href="#" className="app-text-color">Credit Report,</a> */}
                        <a target="_blank" href="/terms-conditions" className="app-text-color"> Terms of Use</a>, and <a target="_blank" href="/privacy-policy" className="app-text-color">Privacy Policy</a>
                    </div>
                </div>
            </Box>


            {/* 
            <Form.Group className="mb-3 mt-35" controlId="phoneNumber">
                <InputGroup>
                    <InputGroup.Text id="basic-addon1" className={(mobileNumber !== "" && validateMobileNumber(mobileNumber) === false) ? "prefix-input-group error-bor" : "prefix-input-group"}>+91</InputGroup.Text>
                    <Form.Control
                        name='mobileNumber'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                        placeholder="Enter mobile number"
                        aria-label="mobilenumber"
                        type='number'
                        pattern="/^\d{10}$/"
                        required
                        className="mobile-number-input"
                        onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                        isInvalid={mobileNumber !== "" && validateMobileNumber(mobileNumber) === false}
                        autoFocus
                        value={mobileNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                        {ValidationErrorMessage.mobileValidationError}
                    </Form.Control.Feedback>
                </InputGroup>

            </Form.Group> */}
            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type={"checkbox"} >
                    <Form.Check.Input
                        name="isChecked"
                        checked={isChecked}
                        type={"checkbox"}
                        className="check-box-input"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)} />
                    <Form.Check.Label className="check-label">
                        By logging in, you agree to the following {" "}
                        <a href="#" className="app-text-color">Credit Report Terms of Use</a>,and <a href="#" className="app-text-color">Privacy Policy</a>
                    </Form.Check.Label>
                </Form.Check>
            </Form.Group> */}
        </>
    )
}

export default MobileNumberModal;