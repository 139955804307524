export const DocReq = () => {
  return (
    <section className="blog-container-width">
      <div
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}
      >
        <span style={{ color: "#000000" }}>
          <span>What Are The Key Documents Required For A Wedding Loan:</span>
        </span>
      </div>
      <div className="mt-4">
        <span>
          As enjoyable as it is to plan and manage a wedding, no one can deny
          that it makes a huge hole in one's cash. For many couples, acquiring a
          loan to pay for their wedding provides some financial relief. Wedding
          loans can be an excellent means for couples to pay for their ideal
          wedding and make it a reality. However, securing a wedding loan
          requires thorough preparation, including submitting the right
          documents and creating a financial profile that lenders feel confident
          about.
        </span>
        <br />
        <div>
          So, let’s explore the key documents you need to have ready when
          applying for a wedding loan!
        </div>
      </div>
      <div className="heading-style text-center">
        Documents Required For A Wedding Loan
      </div>
      <span className="subheading">1. Proof of Identity</span>
      <div className="mt-10">
        The first set of documents required is proof of identity. This helps
        lenders verify that you are who you say you are and ensures compliance
        with government regulations. Documents accepted as identity verification
        include Aadhaar Card, PAN Card, Voter ID, Passport and Driving License.
        Lenders typically ask for at least one or two of these documents. When
        applying for a wedding loan, ensure these documents are all up-to-date
        and accessible.
      </div>
      <div>&nbsp;</div>
      <span className="subheading">2. Proof of Address</span>
      <div className="mt-2">
        Lenders also need proof of your residence to verify that you have a
        permanent address. Like identity proof, address proof documents are a
        standard requirement for loan applications. Some commonly accepted
        address proof documents include an Aadhaar Card, Utility Bills
        (electricity, water, gas) not older than three months, a Passport, a
        Driver’s License, a Ration Card, a Lease Agreement or Property Tax
        Receipts. If you’ve recently moved, make sure that your documentation
        reflects your new address, as discrepancies can lead to delays in the
        approval process.
      </div>
      <div>&nbsp;</div>
      <span className="subheading">3. Proof of Income</span>
      <div className="mt-10">
        Proof of income is one of the most critical aspects of a wedding loan
        application. Lenders need to assess your financial capability to repay
        the loan. However, documents that need to be submitted vary between
        salaried and self-employed individuals.
      </div>
      <div>&nbsp;</div>
      <strong>For Salaried Individuals:</strong>
      <div>- Salary Slips (usually for the last 3 to 6 months).</div>
      <div>
        - Bank Statements showing salary credits (the last 3 to 6 months).
      </div>
      <div> - Form 16 or Income Tax Returns.</div>
      <div className="mt-10"></div>
      <strong>For Self-Employed Individuals:</strong>
      <div>- Income Tax Returns for the last 2 to 3 years.</div>
      <div>
        - Bank Statements showing business transactions and income (usually for
        the last 6 to 12 months).
      </div>
      <div>
        - Profit and Loss Account or Balance Sheet (for business owners).
      </div>
      <div>&nbsp;</div>

      <span className="subheading">4. Employment Proof</span>
      <div className="mt-10">
        For salaried individuals, lenders often require proof of employment to
        verify your job stability and income source. Providing proof of
        employment reassures the lender of your steady income stream, reducing
        the risk associated with lending. Common documents include an Offer
        Letter from your current employer, an Employee ID Card, an Employment
        Certificate or an HR Letter.
      </div>
      <div>&nbsp;</div>
      <span className="subheading">5. Credit Scoref</span>
      <div className="mt-10">
        While not a physical document, your credit score plays an important role
        in the wedding loan approval process. A good credit score increases your
        chances of getting the loan approved at favorable terms. Lenders
        typically check your credit score through credit bureaus such as CIBIL
        or Equifax. A score above 700 is generally considered good and can help
        you secure lower interest rates. Furthermore, it’s advisable to check
        your credit score before applying, as this gives you an idea of your
        eligibility.
      </div>
      <div>&nbsp;</div>
      <span className="subheading">6. Loan Application Form</span>
      <div className="mt-10">
        Finally, every lender requires you to fill out a loan application form.
        This form captures your personal details, loan amount, tenure and other
        key information. The form can be filled out online or in person,
        depending on the lender’s process.
      </div>
      <div>&nbsp;</div>
      <span className="subheading">7. Additional Documents (If Required)</span>
      <div className="mt-10">
        In certain cases, lenders may ask for additional documents depending on
        your loan amount, financial history or specific requirements. These
        could include:
      </div>
      <div className="mt-10"></div>
      <div>- Guarantor Form (if a guarantor is required).</div>
      <div>- Business Proof (for self-employed individuals).</div>
      <div>
        - Marriage-related documents: such as wedding invitations (some lenders
        may request this for verification purposes).
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>
        In conclusion, applying for a wedding loan requires submitting the
        necessary documentation to ensure a smooth and speedy approval process.
        Additionally, maintaining a good credit score and thoroughly
        understanding the loan application process will help you make informed
        decisions about financing your wedding. With the right documents in
        hand, you can focus on planning your big day without financial stress!
      </div>
    </section>
  );
};
