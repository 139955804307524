

export const NotAWeddingLoan = () => {
    return (
        <>
            <section className="blog-container-width">
                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}><span
                    style={{ color: "#000000" }}>
                    <span>Wedding Loan 101:</span>
                    <span>&nbsp;</span>
                </span>
                    <span> What is Not a Wedding Loan?</span>
                </p>
                <div className="mt-4">
                    <span>
                        When planning their dream wedding, many couples explore various financial options to cover the expenses. Although a wedding loan is a popular and structured solution to this, it’s important to understand what doesn’t fall under this category.
                    </span><br />
                    <span>&nbsp;</span>
                </div>
                <span>Not all forms of financing are designed specifically for weddings. Options like credit cards, payday loans or home equity loans come with higher risks or unfavourable terms. So, let’s explore what is not a wedding loan and what expenses don’t qualify for a loan so that you can make an informed choice and avoid costly alternatives while you plan your big day!</span>
                <div>&nbsp;</div>
                <div className="heading-style text-center">Expenses That Do Not Qualify For Wedding Loans
                </div>
                <div>
                    <span className="subheading">1. Gifts for Guests or Wedding Parties</span><br />
                    <span>
                        Gifts for guests or the wedding party, like personalized favors or hampers, are considered personal expenses. Though a wedding tradition, they are typically not covered by wedding loans as they are viewed as optional and non-essential.
                    </span>
                    <div>&nbsp;</div>
                    <span className="subheading">2.  Extravagant or Non-Wedding-Related Entertainment
                    </span><br />
                    <span>
                        Extravagant entertainment, celebrity performers, or non-wedding events may not qualify for a wedding loan. Wedding loans typically focus on financing basic costs like a DJ or live band.                    </span>
                    <div>&nbsp;</div>

                    <span className="subheading">3. Personal Items Not Related to the Wedding</span><br />
                    <span>
                        Home furnishings, electronics, or personal items unrelated to the wedding are not covered by wedding loans. Lenders expect the funds to be used for wedding-specific expenses.Using them for personal purchases may violate loan terms.
                    </span>
                    <div>&nbsp;</div>
                    <span className="subheading">4. Investment Purchases</span><br />
                    <span className="ml-10">
                        Wedding loans cover short-term, wedding-related expenses, not investments like starting a business or a house down payment. Using the funds for such purposes may violate loan terms set up by the lender.
                    </span>
                    <div>&nbsp;</div>
                    <span className="subheading">5. Excessive Luxury Items</span><br />
                    <span className="ml-10">
                        Wedding loans may cover luxury items like designer attire or lavish venues, but limits depend on your credit score and lender policies. Extravagant purchases beyond your borrowing capacity may not be fully covered, especially if deemed non-essential.                        </span>
                    <div>&nbsp;</div>
                    <span className="subheading">6. Late Fees and Penalties</span>
                    <span className="">
                        Any late fees or penalties incurred with vendors due to delayed payments or contract violations are not convered under wedding loans. These loans are meant for standard costs associated with planning and executing the wedding, not for handling poor financial planning on part of those managing the expenses.                     </span>
                    <div>&nbsp;</div>
                </div>
                <div className="heading-style">What is Not a Wedding Loan?</div>
                <div>
                    Before diving in, let’s briefly talk about what is a wedding loan. A wedding loan is a type of personal loan specifically designed to cover the expenses associated with hosting a wedding. The loan amount averages between ₹50,000 to ₹25 lakhs and doesn’t typically require any collateral. A wedding loan should not be confused with other forms of financing that might be used to cover wedding expenses. Unlike a wedding loan, these alternatives often come with higher interest rates, shorter repayment periods, or the risk of losing assets like your home if you default.                </div>
                <div>&nbsp;</div>
                <strong>Credit Cards</strong>
                <div>Although credit cards provide a convenient way to cover wedding expenses, they typically come with higher interest rates compared to wedding loans. Excessive reliance on credit cards can result in substantial debt, particularly if the balance isn’t paid off promptly.</div>
                <div>&nbsp;</div>
                <strong>Payday Loans</strong>
                <div>
                Payday loans are short-term, high-interest loans intended for emergencies. They are unsuitable for wedding expenses due to their steep interest rates and the high risk of leading to a debt cycle.
                </div>
                <div>&nbsp;</div>
                <strong>Home Equity Loans</strong>
                <div>
                Home equity loans let you borrow against your home's value. Although they may have lower interest rates than personal loans, they pose a risk to your property if you fail to make repayments, making them a less ideal choice for financing a wedding.
                </div>
                <div>&nbsp;</div>
                <strong>Marry Now, Pay Later’ Schemes</strong>
                <div>
                    ‘Marry Now, Pay Later’ schemes are often used interchangeably with the term ‘wedding loan’. However, they’re fundamentally different financial products. ‘Marry Now, Pay Later’ schemes are financing options that allow couples to plan and host their wedding without immediate payment, spreading the costs over time through easy instalments. MNPL schemes are exclusively operated by fintech lenders partnering with hotel chains. Fintech companies evaluate the customer's credit profile and employment background, then pay the hotel on their behalf. For repayment, they set up EMIs with their NBFC partners. Repayment tenures can extend from 3 months to 36 months, with no penalties for early repayment. These schemes provide flexibility for those who want to organize their dream wedding without the financial burden of paying everything at once. However, it’s important to carefully review the terms, as delayed payments or hidden fees could lead to higher costs in the long run.
                </div>
                <div>&nbsp;</div>
                <strong>Borrowing from Friends & Family</strong>
                <div>
                    Borrowing money from family members or friends can be an appealing option due to its potential for low or zero interest, which can significantly reduce the cost of financing your wedding compared to other methods. However, it requires careful handling to preserve relationships and ensure that both parties are comfortable with the terms and conditions of the loan.
                </div>
                <div>&nbsp;</div>
                <div>
                    In essence, a wedding loan is specifically tailored for wedding-related expenses, offering a structured and often more affordable way to manage the costs compared to these other financial products. Understanding the difference between a dedicated wedding loan and other financing options is crucial for making sound financial decisions. By being informed, you can choose the right solution that aligns with both your wedding plans and your financial well-being.
                </div>

            </section>
        </>
    )
}

