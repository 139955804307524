import { useLocation } from "react-router-dom";
import "./banklisting.scss";
import Logo from "../../assets/logo.svg";
import HDFCICON from "../../components/HdfcIcon";

const CashePage = () => {
    const { state: casheUrl } = useLocation();

    return (
        <>
            {/* <header className="header">
                <nav className="d-flex jc-sb align-items-center">
                    <div className="d-flex align-items-center">
                        <img src={Logo} alt="logo" />
                        <span className="fw-600">Logo</span>
                    </div>
                    <div>
                        <HDFCICON/>
                    </div>
                </nav>
            </header> */}
            <div>
                <iframe src={casheUrl?.url} width="100%" height="100%"
                    className="cashe-login-view"
                    title="Cashe-Login-Page" />
            </div>
        </>
    )
}

export default CashePage;