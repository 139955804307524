import * as React from "react"

// width={220}
// height={38}
const LogoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="234" height="44" viewBox="0 0 234 44" fill="none">
  <path d="M139.68 34.7294C140.428 34.7294 141.033 34.9599 141.497 35.4208C141.961 35.8741 142.193 36.5314 142.193 37.3927V41.0534H141.183V37.5401C141.183 36.9205 141.03 36.4483 140.723 36.1234C140.416 35.791 139.998 35.6248 139.467 35.6248C138.928 35.6248 138.498 35.7948 138.176 36.1348C137.862 36.4748 137.705 36.9696 137.705 37.6194V41.0534H136.684V34.8428H137.705V35.7268C137.907 35.4094 138.18 35.1639 138.524 34.9901C138.876 34.8163 139.261 34.7294 139.68 34.7294Z" fill="#020164"/>
  <path d="M127.488 37.9254C127.488 37.2908 127.615 36.7354 127.869 36.2594C128.123 35.7759 128.471 35.4019 128.912 35.1374C129.361 34.873 129.858 34.7408 130.404 34.7408C130.943 34.7408 131.41 34.8579 131.807 35.0921C132.203 35.3263 132.499 35.621 132.693 35.9761V34.8428H133.725V41.0534H132.693V39.8974C132.491 40.2601 132.188 40.5623 131.784 40.804C131.388 41.0383 130.924 41.1554 130.393 41.1554C129.847 41.1554 129.354 41.0194 128.912 40.7474C128.471 40.4754 128.123 40.0938 127.869 39.6027C127.615 39.1116 127.488 38.5525 127.488 37.9254ZM132.693 37.9367C132.693 37.4683 132.6 37.0603 132.413 36.7128C132.226 36.3652 131.971 36.1008 131.65 35.9194C131.336 35.7305 130.988 35.6361 130.606 35.6361C130.225 35.6361 129.877 35.7268 129.563 35.9081C129.249 36.0894 128.998 36.3539 128.811 36.7014C128.624 37.049 128.531 37.457 128.531 37.9254C128.531 38.4014 128.624 38.817 128.811 39.1721C128.998 39.5196 129.249 39.7878 129.563 39.9767C129.877 40.1581 130.225 40.2487 130.606 40.2487C130.988 40.2487 131.336 40.1581 131.65 39.9767C131.971 39.7878 132.226 39.5196 132.413 39.1721C132.6 38.817 132.693 38.4052 132.693 37.9367Z" fill="#020164"/>
  <path d="M122.14 41.1554C121.564 41.1554 121.041 41.0232 120.569 40.7587C120.106 40.4943 119.739 40.1203 119.47 39.6367C119.208 39.1456 119.077 38.579 119.077 37.9367C119.077 37.3021 119.212 36.743 119.481 36.2594C119.758 35.7683 120.132 35.3943 120.603 35.1374C121.074 34.873 121.602 34.7408 122.185 34.7408C122.768 34.7408 123.296 34.873 123.767 35.1374C124.238 35.3943 124.608 35.7645 124.877 36.2481C125.154 36.7316 125.293 37.2945 125.293 37.9367C125.293 38.579 125.15 39.1456 124.866 39.6367C124.59 40.1203 124.212 40.4943 123.733 40.7587C123.254 41.0232 122.723 41.1554 122.14 41.1554ZM122.14 40.2487C122.507 40.2487 122.851 40.1618 123.172 39.9881C123.494 39.8143 123.752 39.5536 123.946 39.2061C124.148 38.8585 124.249 38.4354 124.249 37.9367C124.249 37.4381 124.152 37.015 123.958 36.6674C123.763 36.3199 123.509 36.063 123.195 35.8968C122.881 35.723 122.54 35.6361 122.174 35.6361C121.8 35.6361 121.456 35.723 121.142 35.8968C120.835 36.063 120.588 36.3199 120.401 36.6674C120.214 37.015 120.121 37.4381 120.121 37.9367C120.121 38.443 120.21 38.8698 120.39 39.2174C120.577 39.5649 120.824 39.8256 121.13 39.9994C121.437 40.1656 121.774 40.2487 122.14 40.2487Z" fill="#020164"/>
  <path d="M116.485 32.6668V41.0534H115.464V32.6668H116.485Z" fill="#020164"/>
  <path d="M102.042 37.9254C102.042 37.2908 102.169 36.7354 102.424 36.2594C102.678 35.7759 103.026 35.4019 103.467 35.1374C103.916 34.873 104.413 34.7408 104.959 34.7408C105.498 34.7408 105.965 34.8579 106.361 35.0921C106.758 35.3263 107.053 35.621 107.248 35.9761V34.8428H108.28V41.0534H107.248V39.8974C107.046 40.2601 106.743 40.5623 106.339 40.804C105.943 41.0383 105.479 41.1554 104.948 41.1554C104.402 41.1554 103.908 41.0194 103.467 40.7474C103.026 40.4754 102.678 40.0938 102.424 39.6027C102.169 39.1116 102.042 38.5525 102.042 37.9254ZM107.248 37.9367C107.248 37.4683 107.154 37.0603 106.967 36.7128C106.78 36.3652 106.526 36.1008 106.204 35.9194C105.89 35.7305 105.542 35.6361 105.161 35.6361C104.78 35.6361 104.432 35.7268 104.118 35.9081C103.804 36.0894 103.553 36.3539 103.366 36.7014C103.179 37.049 103.086 37.457 103.086 37.9254C103.086 38.4014 103.179 38.817 103.366 39.1721C103.553 39.5196 103.804 39.7878 104.118 39.9767C104.432 40.1581 104.78 40.2487 105.161 40.2487C105.542 40.2487 105.89 40.1581 106.204 39.9767C106.526 39.7878 106.78 39.5196 106.967 39.1721C107.154 38.817 107.248 38.4052 107.248 37.9367Z" fill="#020164"/>
  <path d="M94.1233 35.6928V39.3534C94.1233 39.6556 94.1869 39.8709 94.3141 39.9994C94.4412 40.1203 94.6618 40.1807 94.976 40.1807H95.7276V41.0534H94.8077C94.2393 41.0534 93.813 40.9212 93.5287 40.6567C93.2445 40.3923 93.1024 39.9578 93.1024 39.3534V35.6928H92.3059V34.8428H93.1024V33.2788H94.1233V34.8428H95.7276V35.6928H94.1233Z" fill="#020164"/>
  <path d="M87.959 41.1554C87.4878 41.1554 87.0653 41.076 86.6913 40.9174C86.3174 40.7512 86.0219 40.5245 85.805 40.2374C85.5881 39.9427 85.4685 39.6065 85.446 39.2287H86.5006C86.5305 39.5385 86.6726 39.7916 86.9269 39.9881C87.1887 40.1845 87.529 40.2827 87.9478 40.2827C88.3367 40.2827 88.6434 40.1958 88.8677 40.0221C89.0921 39.8483 89.2043 39.6292 89.2043 39.3647C89.2043 39.0927 89.0846 38.8925 88.8453 38.7641C88.606 38.6281 88.2358 38.4958 87.7347 38.3674C87.2784 38.2465 86.9045 38.1256 86.6128 38.0047C86.3286 37.8763 86.0818 37.6912 85.8724 37.4494C85.6704 37.2001 85.5695 36.8752 85.5695 36.4748C85.5695 36.1574 85.6629 35.8665 85.8499 35.6021C86.0369 35.3377 86.3024 35.1299 86.6464 34.9788C86.9905 34.8201 87.3831 34.7408 87.8244 34.7408C88.505 34.7408 89.0547 34.9146 89.4736 35.2621C89.8924 35.6097 90.1168 36.0857 90.1467 36.6901H89.1258C89.1033 36.3652 88.9725 36.1045 88.7331 35.9081C88.5013 35.7117 88.1871 35.6134 87.7908 35.6134C87.4243 35.6134 87.1326 35.6928 86.9157 35.8514C86.6988 36.0101 86.5904 36.2179 86.5904 36.4748C86.5904 36.6788 86.6539 36.8488 86.7811 36.9848C86.9157 37.1132 87.0802 37.219 87.2747 37.3021C87.4766 37.3776 87.7534 37.4645 88.1049 37.5627C88.5461 37.6836 88.9052 37.8045 89.1819 37.9254C89.4586 38.0387 89.6942 38.2125 89.8887 38.4467C90.0906 38.681 90.1953 38.987 90.2028 39.3647C90.2028 39.7047 90.1093 40.0107 89.9223 40.2827C89.7353 40.5547 89.4698 40.77 89.1258 40.9287C88.7892 41.0798 88.4003 41.1554 87.959 41.1554Z" fill="#020164"/>
  <path d="M82.8248 34.8428V41.0534H81.8039V40.1354C81.6094 40.4527 81.3364 40.702 80.9849 40.8834C80.6409 41.0572 80.2594 41.144 79.8406 41.144C79.362 41.144 78.9319 41.0458 78.5505 40.8494C78.169 40.6454 77.8661 40.3432 77.6418 39.9427C77.4249 39.5423 77.3164 39.055 77.3164 38.4807V34.8428H78.3261V38.3447C78.3261 38.9567 78.4794 39.4289 78.7861 39.7614C79.0927 40.0863 79.5115 40.2487 80.0426 40.2487C80.5885 40.2487 81.0186 40.0787 81.3327 39.7387C81.6468 39.3987 81.8039 38.9038 81.8039 38.2541V34.8428H82.8248Z" fill="#020164"/>
  <path d="M73.9054 33.8341C73.711 33.8341 73.5464 33.7661 73.4118 33.6301C73.2847 33.4941 73.2211 33.3279 73.2211 33.1315C73.2211 32.935 73.2847 32.7688 73.4118 32.6328C73.5464 32.4968 73.711 32.4288 73.9054 32.4288C74.0999 32.4288 74.2607 32.4968 74.3878 32.6328C74.5225 32.7688 74.5898 32.935 74.5898 33.1315C74.5898 33.3279 74.5225 33.4941 74.3878 33.6301C74.2607 33.7661 74.0999 33.8341 73.9054 33.8341ZM74.4103 42.402C74.4103 42.9536 74.2719 43.3578 73.9952 43.6147C73.7184 43.8716 73.3146 44 72.7836 44H72.189V43.1273H72.6153C72.8995 43.1273 73.0977 43.0707 73.2099 42.9574C73.3295 42.844 73.3894 42.6514 73.3894 42.3794V34.8428H74.4103V42.402Z" fill="#020164"/>
  <path d="M63.7159 34.7294C64.4638 34.7294 65.0696 34.9599 65.5333 35.4208C65.997 35.8741 66.2288 36.5314 66.2288 37.3927V41.0534H65.2192V37.5401C65.2192 36.9205 65.0658 36.4483 64.7592 36.1234C64.4526 35.791 64.0337 35.6248 63.5027 35.6248C62.9642 35.6248 62.5341 35.7948 62.2125 36.1348C61.8984 36.4748 61.7414 36.9696 61.7414 37.6194V41.0534H60.7205V34.8428H61.7414V35.7268C61.9433 35.4094 62.2163 35.1639 62.5603 34.9901C62.9118 34.8163 63.297 34.7294 63.7159 34.7294Z" fill="#020164"/>
  <path d="M51.5237 37.9254C51.5237 37.2908 51.6508 36.7354 51.9051 36.2594C52.1594 35.7759 52.5072 35.4019 52.9485 35.1374C53.3972 34.873 53.8946 34.7408 54.4405 34.7408C54.979 34.7408 55.4465 34.8579 55.8429 35.0921C56.2393 35.3263 56.5347 35.621 56.7292 35.9761V34.8428H57.7613V41.0534H56.7292V39.8974C56.5272 40.2601 56.2243 40.5623 55.8205 40.804C55.4241 41.0383 54.9603 41.1554 54.4293 41.1554C53.8834 41.1554 53.3897 41.0194 52.9485 40.7474C52.5072 40.4754 52.1594 40.0938 51.9051 39.6027C51.6508 39.1116 51.5237 38.5525 51.5237 37.9254ZM56.7292 37.9367C56.7292 37.4683 56.6357 37.0603 56.4487 36.7128C56.2617 36.3652 56.0074 36.1008 55.6858 35.9194C55.3717 35.7305 55.0239 35.6361 54.6425 35.6361C54.261 35.6361 53.9133 35.7268 53.5991 35.9081C53.285 36.0894 53.0345 36.3539 52.8475 36.7014C52.6605 37.049 52.567 37.457 52.567 37.9254C52.567 38.4014 52.6605 38.817 52.8475 39.1721C53.0345 39.5196 53.285 39.7878 53.5991 39.9767C53.9133 40.1581 54.261 40.2487 54.6425 40.2487C55.0239 40.2487 55.3717 40.1581 55.6858 39.9767C56.0074 39.7878 56.2617 39.5196 56.4487 39.1721C56.6357 38.817 56.7292 38.4052 56.7292 37.9367Z" fill="#020164"/>
  <path d="M46.5464 34.7295C47.0101 34.7295 47.4289 34.8314 47.8029 35.0354C48.1768 35.2319 48.4685 35.5303 48.6779 35.9308C48.8948 36.3312 49.0033 36.8185 49.0033 37.3928V41.0534H47.9936V37.5401C47.9936 36.9205 47.8403 36.4483 47.5336 36.1234C47.227 35.791 46.8081 35.6248 46.2771 35.6248C45.7386 35.6248 45.3086 35.7948 44.987 36.1348C44.6728 36.4748 44.5158 36.9696 44.5158 37.6194V41.0534H43.4949V32.6668H44.5158V35.7268C44.7177 35.4094 44.9944 35.1639 45.346 34.9901C45.705 34.8163 46.1051 34.7295 46.5464 34.7295Z" fill="#020164"/>
  <path d="M39.4197 35.6928V39.3534C39.4197 39.6556 39.4833 39.8709 39.6104 39.9994C39.7376 40.1203 39.9582 40.1807 40.2724 40.1807H41.024V41.0534H40.1041C39.5357 41.0534 39.1093 40.9212 38.8251 40.6567C38.5409 40.3923 38.3988 39.9578 38.3988 39.3534V35.6928H37.6023V34.8428H38.3988V33.2788H39.4197V34.8428H41.024V35.6928H39.4197Z" fill="#020164"/>
  <path d="M31.368 37.7101C31.368 37.9065 31.3568 38.1143 31.3343 38.3334H26.4205C26.4579 38.9454 26.6636 39.4252 27.0376 39.7727C27.419 40.1127 27.879 40.2827 28.4175 40.2827C28.8587 40.2827 29.2252 40.1807 29.5169 39.9767C29.8161 39.7652 30.0255 39.4856 30.1452 39.1381H31.2446C31.08 39.7349 30.751 40.2223 30.2573 40.6C29.7637 40.9703 29.1504 41.1554 28.4175 41.1554C27.8341 41.1554 27.3106 41.0232 26.8468 40.7587C26.3906 40.4943 26.0316 40.1203 25.7699 39.6367C25.5081 39.1456 25.3772 38.579 25.3772 37.9367C25.3772 37.2945 25.5043 36.7316 25.7586 36.2481C26.0129 35.7645 26.3682 35.3943 26.8244 35.1374C27.2881 34.873 27.8191 34.7408 28.4175 34.7408C29.0008 34.7408 29.5169 34.8692 29.9657 35.1261C30.4144 35.383 30.7584 35.7381 30.9978 36.1914C31.2446 36.6372 31.368 37.1434 31.368 37.7101ZM30.3134 37.4948C30.3134 37.1019 30.2274 36.7656 30.0554 36.4861C29.8834 36.199 29.6478 35.9837 29.3486 35.8401C29.0569 35.689 28.7316 35.6134 28.3726 35.6134C27.8565 35.6134 27.4153 35.7797 27.0488 36.1121C26.6898 36.4445 26.4841 36.9054 26.4318 37.4948H30.3134Z" fill="#020164"/>
  <path d="M21.3603 35.8514C21.5398 35.4963 21.794 35.2205 22.1231 35.0241C22.4597 34.8277 22.8673 34.7294 23.346 34.7294V35.7948H23.0767C21.9324 35.7948 21.3603 36.4219 21.3603 37.6761V41.0534H20.3394V34.8428H21.3603V35.8514Z" fill="#020164"/>
  <path d="M14.6106 41.1554C14.0347 41.1554 13.5111 41.0232 13.0399 40.7587C12.5762 40.4943 12.2098 40.1203 11.9405 39.6367C11.6787 39.1456 11.5479 38.579 11.5479 37.9367C11.5479 37.3021 11.6825 36.743 11.9517 36.2594C12.2285 35.7683 12.6024 35.3943 13.0736 35.1374C13.5448 34.873 14.0721 34.7408 14.6554 34.7408C15.2388 34.7408 15.7661 34.873 16.2373 35.1374C16.7085 35.3943 17.0787 35.7645 17.3479 36.2481C17.6247 36.7316 17.763 37.2945 17.763 37.9367C17.763 38.579 17.6209 39.1456 17.3367 39.6367C17.06 40.1203 16.6823 40.4943 16.2036 40.7587C15.725 41.0232 15.1939 41.1554 14.6106 41.1554ZM14.6106 40.2487C14.977 40.2487 15.3211 40.1618 15.6427 39.9881C15.9643 39.8143 16.2223 39.5536 16.4168 39.2061C16.6187 38.8585 16.7197 38.4354 16.7197 37.9367C16.7197 37.4381 16.6225 37.015 16.428 36.6674C16.2335 36.3199 15.9792 36.063 15.6651 35.8968C15.351 35.723 15.0107 35.6361 14.6442 35.6361C14.2703 35.6361 13.9262 35.723 13.6121 35.8968C13.3055 36.063 13.0586 36.3199 12.8717 36.6674C12.6847 37.015 12.5912 37.4381 12.5912 37.9367C12.5912 38.443 12.6809 38.8698 12.8604 39.2174C13.0474 39.5649 13.2942 39.8256 13.6009 39.9994C13.9075 40.1656 14.2441 40.2487 14.6106 40.2487Z" fill="#020164"/>
  <path d="M8.96386 33.2108V41.0534H7.94296V35.2055L5.36265 41.0534H4.64465L2.05313 35.1941V41.0534H1.03223V33.2108H2.13166L5.00365 39.6934L7.87564 33.2108H8.96386Z" fill="#020164"/>
  <path d="M234 13.9104V21.7869H232.721V16.3923L230.343 21.7869H229.456L227.067 16.3923V21.7869H225.788V13.9104H227.168L229.905 20.087L232.631 13.9104H234Z" fill="#020164"/>
  <path d="M219.313 21.8663C218.587 21.8663 217.918 21.6963 217.305 21.3563C216.699 21.0087 216.216 20.529 215.857 19.917C215.506 19.2974 215.33 18.6023 215.33 17.8317C215.33 17.061 215.506 16.3697 215.857 15.7577C216.216 15.1457 216.699 14.6697 217.305 14.3297C217.918 13.9821 218.587 13.8084 219.313 13.8084C220.046 13.8084 220.715 13.9821 221.321 14.3297C221.934 14.6697 222.417 15.1457 222.768 15.7577C223.12 16.3697 223.295 17.061 223.295 17.8317C223.295 18.6023 223.12 19.2974 222.768 19.917C222.417 20.529 221.934 21.0087 221.321 21.3563C220.715 21.6963 220.046 21.8663 219.313 21.8663ZM219.313 20.7443C219.829 20.7443 220.289 20.6272 220.693 20.393C221.096 20.1512 221.411 19.8112 221.635 19.373C221.867 18.9272 221.983 18.4134 221.983 17.8317C221.983 17.2499 221.867 16.7399 221.635 16.3017C221.411 15.8635 221.096 15.5272 220.693 15.293C220.289 15.0588 219.829 14.9417 219.313 14.9417C218.797 14.9417 218.337 15.0588 217.933 15.293C217.529 15.5272 217.211 15.8635 216.979 16.3017C216.755 16.7399 216.643 17.2499 216.643 17.8317C216.643 18.4134 216.755 18.9272 216.979 19.373C217.211 19.8112 217.529 20.1512 217.933 20.393C218.337 20.6272 218.797 20.7443 219.313 20.7443Z" fill="#020164"/>
  <path d="M205.419 17.8317C205.419 17.061 205.595 16.3697 205.946 15.7577C206.305 15.1457 206.788 14.6697 207.393 14.3297C208.007 13.9821 208.676 13.8084 209.402 13.8084C210.232 13.8084 210.968 14.0161 211.612 14.4317C212.262 14.8397 212.734 15.4215 213.025 16.177H211.488C211.286 15.7615 211.006 15.4517 210.647 15.2477C210.288 15.0437 209.873 14.9417 209.402 14.9417C208.886 14.9417 208.426 15.0588 208.022 15.293C207.618 15.5272 207.3 15.8635 207.068 16.3017C206.844 16.7399 206.732 17.2499 206.732 17.8317C206.732 18.4134 206.844 18.9234 207.068 19.3616C207.3 19.7998 207.618 20.1398 208.022 20.3816C208.426 20.6158 208.886 20.7329 209.402 20.7329C209.873 20.7329 210.288 20.631 210.647 20.427C211.006 20.223 211.286 19.9132 211.488 19.4976H213.025C212.734 20.2532 212.262 20.8349 211.612 21.2429C210.968 21.6509 210.232 21.8549 209.402 21.8549C208.669 21.8549 207.999 21.6849 207.393 21.3449C206.788 20.9974 206.305 20.5176 205.946 19.9056C205.595 19.2936 205.419 18.6023 205.419 17.8317Z" fill="#020164"/>
  <path d="M202.421 21.8663C202.189 21.8663 201.995 21.7869 201.838 21.6283C201.681 21.4696 201.602 21.2732 201.602 21.0389C201.602 20.8047 201.681 20.6083 201.838 20.4496C201.995 20.291 202.189 20.2116 202.421 20.2116C202.646 20.2116 202.836 20.291 202.993 20.4496C203.15 20.6083 203.229 20.8047 203.229 21.0389C203.229 21.2732 203.15 21.4696 202.993 21.6283C202.836 21.7869 202.646 21.8663 202.421 21.8663Z" fill="#020164"/>
  <path d="M191.358 6.44631C193.117 6.44631 194.517 7.02657 195.558 8.18709C196.617 9.32948 197.147 10.9071 197.147 12.9198V21.7869H192.57V13.5454C192.57 12.53 192.309 11.7412 191.789 11.1791C191.268 10.6169 190.568 10.3359 189.689 10.3359C188.809 10.3359 188.109 10.6169 187.589 11.1791C187.068 11.7412 186.808 12.53 186.808 13.5454V21.7869H182.204V6.60951H186.808V8.62228C187.274 7.95136 187.903 7.4255 188.693 7.0447C189.482 6.64578 190.371 6.44631 191.358 6.44631Z" fill="#020164"/>
  <path d="M162.475 14.1711C162.475 12.6116 162.763 11.2426 163.337 10.0639C163.929 8.88525 164.728 7.97859 165.733 7.34393C166.738 6.70928 167.86 6.39194 169.099 6.39194C170.158 6.39194 171.082 6.60954 171.872 7.04474C172.68 7.47993 173.299 8.05113 173.73 8.75832V6.60954H178.334V21.787H173.73V19.6382C173.281 20.3454 172.653 20.9166 171.845 21.3518C171.055 21.787 170.131 22.0046 169.072 22.0046C167.851 22.0046 166.738 21.6872 165.733 21.0526C164.728 20.3998 163.929 19.4841 163.337 18.3054C162.763 17.1086 162.475 15.7305 162.475 14.1711ZM173.73 14.1983C173.73 13.0377 173.407 12.122 172.761 11.4511C172.132 10.7802 171.361 10.4447 170.445 10.4447C169.53 10.4447 168.749 10.7802 168.103 11.4511C167.474 12.1039 167.16 13.0105 167.16 14.1711C167.16 15.3316 167.474 16.2564 168.103 16.9454C168.749 17.6163 169.53 17.9518 170.445 17.9518C171.361 17.9518 172.132 17.6163 172.761 16.9454C173.407 16.2745 173.73 15.3588 173.73 14.1983Z" fill="#020164"/>
  <path d="M136.943 18.1966H142.974V21.7869H132.339V2.69275H136.943V18.1966Z" fill="#020164"/>
  <path d="M119.721 6.39194C120.78 6.39194 121.704 6.60954 122.494 7.04474C123.302 7.47993 123.921 8.05113 124.352 8.75832V6.60954H128.956V21.7598C128.956 23.156 128.678 24.4163 128.122 25.5405C127.583 26.6829 126.748 27.5896 125.618 28.2605C124.505 28.9314 123.113 29.2669 121.444 29.2669C119.218 29.2669 117.414 28.7319 116.032 27.6621C114.65 26.6104 113.86 25.1779 113.663 23.3645H118.213C118.357 23.9448 118.698 24.3981 119.236 24.7245C119.775 25.0691 120.439 25.2413 121.229 25.2413C122.18 25.2413 122.934 24.9603 123.49 24.3981C124.065 23.8541 124.352 22.9747 124.352 21.7598V19.611C123.903 20.3182 123.284 20.8984 122.494 21.3518C121.704 21.787 120.78 22.0046 119.721 22.0046C118.482 22.0046 117.361 21.6872 116.355 21.0526C115.35 20.3998 114.551 19.4841 113.959 18.3054C113.385 17.1086 113.097 15.7305 113.097 14.1711C113.097 12.6116 113.385 11.2426 113.959 10.0639C114.551 8.88525 115.35 7.97859 116.355 7.34393C117.361 6.70928 118.482 6.39194 119.721 6.39194ZM124.352 14.1983C124.352 13.0377 124.029 12.122 123.383 11.4511C122.754 10.7802 121.983 10.4447 121.067 10.4447C120.152 10.4447 119.371 10.7802 118.725 11.4511C118.096 12.1039 117.782 13.0105 117.782 14.1711C117.782 15.3316 118.096 16.2564 118.725 16.9454C119.371 17.6163 120.152 17.9518 121.067 17.9518C121.983 17.9518 122.754 17.6163 123.383 16.9454C124.029 16.2745 124.352 15.3588 124.352 14.1983Z" fill="#020164"/>
  <path d="M104.486 6.44631C106.245 6.44631 107.645 7.02657 108.686 8.18709C109.745 9.32948 110.275 10.9071 110.275 12.9198V21.7869H105.698V13.5454C105.698 12.53 105.437 11.7412 104.917 11.1791C104.396 10.6169 103.696 10.3359 102.817 10.3359C101.937 10.3359 101.237 10.6169 100.717 11.1791C100.196 11.7412 99.9357 12.53 99.9357 13.5454V21.7869H95.3315V6.60951H99.9357V8.62228C100.402 7.95136 101.031 7.4255 101.82 7.0447C102.61 6.64578 103.499 6.44631 104.486 6.44631Z" fill="#020164"/>
  <path d="M89.168 5.03194C88.3602 5.03194 87.6961 4.79621 87.1755 4.32475C86.6729 3.83515 86.4216 3.23676 86.4216 2.52957C86.4216 1.80425 86.6729 1.20585 87.1755 0.734391C87.6961 0.244797 88.3602 0 89.168 0C89.9578 0 90.604 0.244797 91.1066 0.734391C91.6271 1.20585 91.8874 1.80425 91.8874 2.52957C91.8874 3.23676 91.6271 3.83515 91.1066 4.32475C90.604 4.79621 89.9578 5.03194 89.168 5.03194ZM91.4566 6.60952V21.7869H86.8524V6.60952H91.4566Z" fill="#020164"/>
  <path d="M44.0157 13.9535C44.0157 14.3886 43.9888 14.842 43.9349 15.3134H33.515C33.5868 16.2564 33.883 16.9817 34.4035 17.4894C34.942 17.979 35.5972 18.2238 36.369 18.2238C37.5178 18.2238 38.3166 17.7342 38.7653 16.755H43.6657C43.4144 17.7523 42.9567 18.6499 42.2925 19.4478C41.6463 20.2456 40.8296 20.8712 39.8423 21.3246C38.8551 21.7779 37.7512 22.0046 36.5306 22.0046C35.0587 22.0046 33.7483 21.6872 32.5995 21.0526C31.4507 20.4179 30.5532 19.5113 29.907 18.3326C29.2608 17.1539 28.9377 15.7758 28.9377 14.1983C28.9377 12.6207 29.2519 11.2426 29.8801 10.0639C30.5263 8.88525 31.4238 7.97859 32.5726 7.34393C33.7214 6.70928 35.0407 6.39194 36.5306 6.39194C37.9845 6.39194 39.2769 6.70021 40.4078 7.31674C41.5386 7.93326 42.4182 8.81272 43.0464 9.9551C43.6926 11.0975 44.0157 12.4303 44.0157 13.9535ZM39.3038 12.7295C39.3038 11.9316 39.0346 11.297 38.4961 10.8255C37.9576 10.354 37.2845 10.1183 36.4767 10.1183C35.7049 10.1183 35.0497 10.345 34.5112 10.7983C33.9907 11.2516 33.6676 11.8953 33.5419 12.7295H39.3038Z" fill="#020164"/>
  <path d="M27.0057 2.69275L22.0784 21.7869H16.505L13.4894 9.22067L10.3661 21.7869H4.79264L0 2.69275H4.92726L7.64668 16.5918L11.0123 2.69275H16.0742L19.3052 16.5918L22.0515 2.69275H27.0057Z" fill="#020164"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M152.687 21.6734C156.838 21.6734 160.203 18.2738 160.203 14.0803C160.203 9.88669 156.838 6.48713 152.687 6.48713C148.535 6.48713 145.17 9.88669 145.17 14.0803C145.17 18.2738 148.535 21.6734 152.687 21.6734ZM153.925 11.3328H155.813V9.98658H149.873V11.3855H151.113C151.481 11.3855 151.781 11.446 152.013 11.567C152.244 11.6879 152.412 11.8518 152.516 12.0586H149.873V13.4049H152.474C152.362 13.6039 152.197 13.7659 151.977 13.8907C151.757 14.0117 151.469 14.0722 151.113 14.0722H149.873V15.3599H151.113C151.269 15.3599 151.389 15.3853 151.473 15.436C151.557 15.4828 151.633 15.5589 151.701 15.6643L153.152 18.1402C153.3 18.39 153.543 18.5148 153.883 18.5148H155.717L153.859 15.5472C153.751 15.3833 153.627 15.2487 153.487 15.1433C153.351 15.0341 153.202 14.9463 153.038 14.8799C153.413 14.716 153.719 14.5073 153.955 14.2536C154.195 14 154.365 13.7171 154.464 13.4049H155.813V12.0586H154.464C154.417 11.9221 154.349 11.7933 154.261 11.6723C154.177 11.5475 154.065 11.4343 153.925 11.3328Z" fill="#020164"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M63.0773 0.537201H59.0666V6.66882C57.8495 5.94467 56.4308 5.52929 54.9161 5.52929C50.3929 5.52929 46.7261 9.23353 46.7261 13.8029C46.7261 18.3723 50.3929 22.0766 54.9161 22.0766C59.4394 22.0766 63.1062 18.3723 63.1062 13.8029C63.1062 13.567 63.0964 13.3334 63.0773 13.1024V0.537201ZM59.4388 13.8028C59.4388 16.3262 57.4139 18.3718 54.9159 18.3718C52.418 18.3718 50.3931 16.3262 50.3931 13.8028C50.3931 11.2794 52.418 9.23374 54.9159 9.23374C57.4139 9.23374 59.4388 11.2794 59.4388 13.8028Z" fill="#020164"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M82.9905 0.537201H78.9798V6.66891C77.7627 5.9447 76.344 5.52929 74.8292 5.52929C70.306 5.52929 66.6392 9.23353 66.6392 13.8029C66.6392 18.3723 70.306 22.0766 74.8292 22.0766C79.3525 22.0766 83.0193 18.3723 83.0193 13.8029C83.0193 13.5676 83.0096 13.3345 82.9905 13.1041V0.537201ZM79.3519 13.8028C79.3519 16.3262 77.3269 18.3718 74.829 18.3718C72.3311 18.3718 70.3062 16.3262 70.3062 13.8028C70.3062 11.2794 72.3311 9.23374 74.829 9.23374C77.3269 9.23374 79.3519 11.2794 79.3519 13.8028Z" fill="#020164"/>
</svg>
)
export default LogoIcon
