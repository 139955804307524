
const TickIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M5.33594 12.9513L9.22157 16.8368L17.8967 8.16162" stroke="white" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default TickIcon;