
const Loans = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g>
        <path d="M2.99904 19.0001H20.999M11.999 12.0001V19.0001M17.999 12.0001V19.0001M5.99904 12.0001V19.0001M12.4462 3.22374L20.589 7.29515C21.4385 7.71987 21.1362 9.00013 20.1865 9.00013H3.8115C2.8618 9.00013 2.55957 7.71987 3.40901 7.29515L11.5518 3.22374C11.8333 3.08297 12.1647 3.08297 12.4462 3.22374Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        </svg>
    )
}

export default Loans;


