import { gql } from '@apollo/client';


export const EMICACULATORQUERY = gql`mutation EmiCalculator($input: EmiCalculator) {
    emiCalculator(input: $input) {
      emiCalculatorErrors {
        message
      }
      status
      message
      emiCalculations {
        totalAmountPayable
        monthlyEmi
        principalAmount
        totalInterestPayable
      }
    }
  }`