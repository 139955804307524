import * as React from "react"
const NotificationIcon = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeWidth={1.5}
      d="m18.698 14.877.001.003c.27.444.317.963.14 1.435a1.649 1.649 0 0 1-1.033 1.002l-.004.002a18.22 18.22 0 0 1-5.782.931c-1.962 0-3.92-.308-5.778-.94H6.24c-.556-.188-.932-.56-1.084-.983l-.002-.006c-.163-.443-.118-.947.179-1.445v-.002l1.009-1.666.006-.01c.15-.259.272-.593.354-.898.083-.303.148-.652.148-.95V8.82c0-2.021 1.176-3.779 2.884-4.628l.207-.104.114-.202A2.21 2.21 0 0 1 11.99 2.75c.83 0 1.54.426 1.927 1.109l.116.206.214.102A5.16 5.16 0 0 1 17.19 8.82v2.53c0 .298.065.647.147.95.083.307.203.64.35.9l.005.009.006.008 1 1.66Z"
    />
    <path
      fill="#000"
      d="M14.83 20.01A3.014 3.014 0 0 1 12 22c-.79 0-1.57-.32-2.12-.89-.32-.3-.56-.7-.7-1.11.13.02.26.03.4.05.23.03.47.06.71.08.57.05 1.15.08 1.73.08.57 0 1.14-.03 1.7-.08.21-.02.42-.03.62-.06l.49-.06Z"
    />
   {props.newNotification && <circle cx={17} cy={6} r={4} fill="#F33" stroke="#F6F6FF" />} 
  </svg>
)
export default NotificationIcon
