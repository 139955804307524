import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#020164',
        },
        secondary: {
            main: '#F5F4FF',
        },
    },
    typography: {
        fontFamily: 'Poppins',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
              body: {
                scrollbarColor: "#F5F4FF #020164",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "#020164",
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 8,
                  backgroundColor: "#F5F4FF",
                  minHeight: 24,
                  border: "3px solid #020164",
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                  backgroundColor: "#F5F4FF",
                },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                  backgroundColor: "#F5F4FF",
                },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#F5F4FF",
                },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "#F5F4FF",
                },
              },
            },
          },
    },
});

export default theme;
