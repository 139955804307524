import { Accordion, AccordionDetails, AccordionSummary, List, ListItem } from "@mui/material";
import Logo from "../../components/Logo";
import "./index.scss";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const AccordionContainer = styled(Accordion)({
    boxShadow: "none",
    color: 'color:rgb(0, 30, 100)'
})

const Summary = styled(AccordionSummary)({
    fontStyle: "italic",
    fontSize: '18px',
})

interface Blogprops {
    id: string 
}


const WeddingLoanBlog = () => {

    return (
        <section className="blog-container-width">
            <header className="p-3">
                <Logo />
            </header>
            <div className="mt-4">
                <AccordionContainer defaultExpanded={false} id="fundamentals">
                    <Summary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        THEME 1: Wedding Loan Fundamentals
                    </Summary>
                    <AccordionDetails sx={{ marginLeft: "35px" }}>
                        <ul style={{ color: "rgb(0, 30, 100)" }}>
                            <li><a
                                href="/blog/budget-requirements"
                                target="_blank" rel="noopener noreferrer"
                            >Key Budgeting Requirements in a Wedding</a></li>
                            <li><a
                                target="_blank" rel="noopener noreferrer"
                                href="/blog/wedding-loan">What is a Wedding Loan?</a></li>
                            <li><a
                                href="/blog/not-a-wedding-loan"
                                target="_blank" rel="noopener noreferrer">What is Not a Wedding Loan?</a></li>
                        </ul>


                    </AccordionDetails>
                </AccordionContainer>
                <AccordionContainer>
                    <Summary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{}}
                    >
                        THEME 2: Pre Wedding Loan Checklist
                    </Summary>
                    <AccordionDetails sx={{ marginLeft: "35px", padding: '8px !important' }}>
                        <ul style={{ color: "rgb(0, 30, 100)" }}>
                            <li><a
                                href="/blog/decide-ur-expense"
                                target="_blank" rel="noopener noreferrer"
                            >How Do You Decide Your Expenses?</a></li>
                            <li><a
                                target="_blank" rel="noopener noreferrer"
                                href="/blog/decide-vendor">How Do You Decide Your Vendors?</a></li>
                            <li><a
                                target="_blank" rel="noopener noreferrer" href="/blog/not-exceed-budget">'
                                How to Ensure You're Not Exceeding Your Budget?
                            </a></li>
                            <li><a
                                target="_blank" rel="noopener noreferrer" href="/blog/key-benefits">'
                                Key Benefits You Should Look Out For?
                            </a></li>
                        </ul>


                    </AccordionDetails>
                </AccordionContainer>

                <AccordionContainer>
                    <Summary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{}}
                    >
                        THEME 3: Wedding Loan Process
                    </Summary>
                    <AccordionDetails sx={{ marginLeft: "35px", padding: '8px' }}>
                        <ul style={{ color: "rgb(0, 30, 100)" }}>
                            <li><a
                            href="/blog/ensure-privacy"
                                target="_blank" rel="noopener noreferrer"
                            >How to Ensure Privacy?</a></li>
                            <li><a
                                target="_blank" rel="noopener noreferrer"
                                href="/blog/manage-stakeholders">
                                How to Manage Key Stakeholders?
                            </a></li>
                            <li><a
                                target="_blank" rel="noopener noreferrer" 
                                href="/blog/docs-requried">
                                Key Documents Required for a Wedding Loan
                            </a></li>
                        </ul>


                    </AccordionDetails>
                </AccordionContainer>

                <AccordionContainer>
                    <Summary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{}}
                    >
                        THEME 4: Key Technical Terms Related to Wedding Loan
                    </Summary>
                    <AccordionDetails sx={{ marginLeft: "35px", padding: '8px' }}>
                        <ul style={{ color: "rgb(0, 30, 100)" }}>
                            <li><a
                                href="/blog/calc-emi"
                                target="_blank" rel="noopener noreferrer"
                            >How to Calculate EMI?</a></li>
                            <li><a
                                target="_blank" rel="noopener noreferrer"
                                href="/blog/key-terms">
                                Key Terms in a Wedding Loan
                            </a></li>
                            <li><a
                                href="blog/key-fact"
                                target="_blank" rel="noopener noreferrer">
                                What is a Key Fact Sheet?
                            </a></li>
                        </ul>
                    </AccordionDetails>
                </AccordionContainer>

                <AccordionContainer>
                    <Summary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{}}
                    >
                        THEME 5: Types of Wedding Loans
                    </Summary>
                    <AccordionDetails sx={{ marginLeft: "35px", padding: '8px' }}>
                        <ul style={{ color: "rgb(0, 30, 100)" }}>
                            <li><a
                                href="blog/overdraft-facility"
                                target="_blank" rel="noopener noreferrer"
                            >Overdraft Facility</a></li>
                            <li><a
                                target="_blank" rel="noopener noreferrer"
                                href="https://weddingloancom.blogspot.com/search/label/What%20is%20a%20Wedding%20Loan%3F">
                               Personal Loans
                            </a></li>
                            <li><a
                                target="_blank" rel="noopener noreferrer" href="https://weddingloancom.blogspot.com/search/label/What%20is%20Not%20a%20Wedding%20Loan%3F">
                                Revolving Credit Line Facilit
                            </a></li>
                        </ul>
                    </AccordionDetails>
                </AccordionContainer>

            </div>
        </section>
    )
}

export default WeddingLoanBlog;