import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Menu,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    Button,
    CircularProgress,
    Paper,
    styled,
    useTheme,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotificationIcon from './NotificationIcon';
import { formatRelativeTime } from '../utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import { USER_NOTIFICATIONS, MARK_AS_READ } from '../query/Notification';
import { Socket } from 'socket.io-client';

interface Notification {
    id: string;
    title: string;
    message: string;
    createdAt: string;
    isRead: boolean;
}

const StyledInfiniteScroll = styled(InfiniteScroll)(({ theme }) => ({
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: theme.palette.primary.main,
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.main,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.secondary.main}`,
}));

const NotificationContainer = styled(Box)(() => ({
    display: 'flex', alignItems: 'center'
}));

function NotificationDropdown({ newNotification, socket }: { newNotification: boolean, socket: Socket }) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [items, setItems] = useState<Notification[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [offset, setOffset] = useState(0);
    const limit = 10;

    const [markAsRead] = useMutation(MARK_AS_READ, {})

    const [fetchNotifications] = useLazyQuery(USER_NOTIFICATIONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response: any) => {
            if (response.notifications.data.length < response.notifications.count) {
                setHasMore(true);
                setItems((prevItems) => [...prevItems, ...response.notifications.data]);
            } else {
                setItems([...response.notifications.data]);
            }
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        fetchNotifications({ variables: { limit, offset } });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchMoreData = () => {
        if (hasMore) {
            fetchNotifications({ variables: { limit, offset } });
        }
    };

    const markAllAsRead = () => {

      
        if (items.some(item => item.isRead === false)) {
            markAsRead({
                variables: {
                    input: {
                        "id": null
                    }
                }
            })
            fetchNotifications({ variables: { limit, offset } });
        }
    };

    const handleNotificationClick = (notification: Notification) => {
        if (!notification.isRead) {

            markAsRead({
                variables: {
                    input: {
                        "id": Number(notification.id)
                    }
                }
            })
            fetchNotifications({ variables: { limit, offset } });
        }
    };

    return (
        <NotificationContainer>
            <IconButton
                size="large"
                color="primary"
                onClick={handleOpen}
            >
                <NotificationIcon newNotification={newNotification} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            width: '300px',
                            maxHeight: '100vh',
                            borderRadius:'13px'
                        }
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, pt: 1 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        Notifications
                    </Typography>
                    <Button size="small" onClick={markAllAsRead}>
                        Read All
                    </Button>
                </Box>
                <Divider />
                <StyledInfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'center', mt: 1 }}><CircularProgress size={50} /></Box>}
                    // height={650}
                    endMessage={
                        <Typography sx={{ textAlign: 'center', mt: 2 }}>
                            No more notifications
                        </Typography>
                    }
                >
                    <List>
                        {items.map((notification: Notification) => (
                            <Paper
                                key={notification.id}
                                elevation={notification.isRead ? 1 : 3}
                                sx={{
                                    px: 2,
                                    margin: 'px',
                                    backgroundColor: notification.isRead ? 'transparent' : theme.palette.secondary.main,
                                    borderRadius: '8px',
                                    boxShadow: notification.isRead ? 'none' : '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#F1F1F1',
                                    },
                                }}
                                onClick={() => handleNotificationClick(notification)}
                            >
                                <ListItem
                                    sx={{
                                        flexDirection:'column',
                                        alignItems:'flex-start',
                                        padding: 0,
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography sx={{ fontWeight: notification.isRead ? 'normal' : 'bold' }}>
                                                {notification.title}
                                            </Typography>
                                        }
                                        secondary={notification.message}
                                    />
                                    <Typography variant="body2" color="textSecondary">
                                        {formatRelativeTime(notification.createdAt)}
                                    </Typography>
                                </ListItem>
                                <Box mt={1}>
                                    <Divider sx={{borderWidth:'1px'}}/>
                                </Box>
                            </Paper>
                        ))}
                    </List>
                </StyledInfiniteScroll>
            </Menu>
        </NotificationContainer>
    );
}

export default NotificationDropdown;
