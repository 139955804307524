import { gql } from '@apollo/client';

export const USER_NOTIFICATIONS = gql`
	query Notifications($offset: Int, $limit: Int) {
		notifications(offset: $offset, limit: $limit) {
			data {
				id
				userId
				title
				message
				isRead
				createdAt
			}
			count
		}
	}
`;

export const MARK_AS_READ = gql`
	mutation MarkAsRead($input: MarkAsReadInput) {
		markAsRead(input: $input) {
			MarkAsReadErrors {
				message
			}
			message
			status
		}
	}
`;
