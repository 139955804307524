import BankListing from "../BankListingComponent/BankListing";
import Loans from "../Loans/Loans";
import Navbar from "../../components/Navbar/Navbar";
import { APPLIEDLOANSTATUS } from "../../query/BankListingDetails/AppliedLoans";
import { useQuery } from "@apollo/client";
import LoadingModalView from "../../components/LoadingModal";

const LoanComponent = () => {
    const { data: appliedLoanResponse, loading, refetch } = useQuery(APPLIEDLOANSTATUS, { fetchPolicy: "network-only", errorPolicy: "all" });
    return (
        <>
            <Navbar menuId={2} />
            {loading ?
                <LoadingModalView isOpen={loading} /> :
                <>
                    {appliedLoanResponse?.appliedLoans?.length > 0 ?
                        <Loans appliedLoan={appliedLoanResponse?.appliedLoans} />
                        :
                        <BankListing refetchLoan={refetch} />
                    }
                </>
            }
        </>
    )
}

export default LoanComponent;