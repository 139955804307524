import { gql } from '@apollo/client';


export const CASHELOAN = gql`mutation ApplyLoan($input: ApplyCasheLoanInput) {
  applyCasheLoan(input: $input) {
    applyCasheLoanErrors {
      message
    }
    status
    message
    data {
      URL
      lenderName
    }
  }
}
`