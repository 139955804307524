
import { TextField, FormControl, InputLabel, Select, MenuItem,Radio, Box, Fab, Typography, styled } from '@mui/material';
import locationGif from "../../assets/locationGif.gif";
import Logo from "../../assets/logo.svg";
import "./userDetails.scss";
import React, { useEffect, useState } from "react";
import AppButton from "../../components/AppButton";
import ProgressBarComponent from "../../components/ProgressBarComponent";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CITIESLIST, RESIDENTTYPELIST, STATESLIST } from "../../query/queries";
import { validateAddress, validatePinCode } from "../../utils";
import AppModal from "../../components/AppModal/AppModal";
import { SAVELOCATIONDETAILS } from "../../query/UserDetails/userDetailsQuery";
import { useNavigate } from "react-router-dom";
import { ValidationErrorMessage } from '../../ValidationError';
import LogoIcon from '../../components/Logo';
import { jwtDecode, JwtPayload } from "jwt-decode";
import ChatIcon from '@mui/icons-material/Chat';
import env from "../../config/config";

let locationDetailsInfo = {
    residenceType: "",
    address: "",
    addressType: 1,
    city: "",
    pincode: "",
    state: "",
}

let currentAddressInput = [
    {
        id: 1,
        value: "Yes"
    },
    {
        id: 2,
        value: "No"
    }
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const LocationDetails = () => {
    const [locationDetails, setLocationDetails] = useState(locationDetailsInfo);
    const { data: residentTypeList } = useQuery(RESIDENTTYPELIST,{fetchPolicy: "network-only"});
    const { data: stateList } = useQuery(STATESLIST,{fetchPolicy: "network-only"});
    const [openModal, setOpenModal] = useState(false);
    const [saveLocationDetails, { data: locationDetailsResponse, loading }] = useMutation(SAVELOCATIONDETAILS);
    const [permanentAddressId, setPermanentAddressId] = useState("");
    const [permanentAddress,setPermanentAddress] = useState(locationDetailsInfo);
    const navigate = useNavigate();
    const [formCompletedStatus, setFormCompletedStatus] = useState(50);
    const [isPermanentAdressSame, setIsPermanentAdressSame] = useState(true);

    const [citiesList,setCitiesList] = useState([])
    
    const [token, setToken] = useState<JwtPayload & { id: string, ecc: string } | null>(null);

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth' 
        });
        const storedToken = sessionStorage.getItem('AuthToken');
        if (storedToken) {
            try {
                const decodedToken: JwtPayload & { id: string, ecc: string } = jwtDecode(storedToken);
                setToken(decodedToken);
                // console.log("Token set:", decodedToken);
            } catch (error) {
                console.error("Error decoding token:", error);
            }
        } else {
            console.log("No token found in sessionStorage");
        }
    }, []);


    const [fetchCities] = useLazyQuery(CITIESLIST, {
        fetchPolicy: 'network-only',
        onCompleted: (response: any) => {
            setLocationDetails({
                ...locationDetails,
                city: "",
            });
           if(response?.city){
            setCitiesList(response?.city)
           }
        }
    });

    useEffect(() => {
        if ((locationDetailsResponse?.userLocationDetail.status == 200) || (locationDetailsResponse?.userLocationDetail.status == 201)) {
            if (locationDetails?.addressType === 1) {
                window.scroll({
                    top: 0,
                    behavior: 'smooth' 
                });
                setOpenModal(true);
            } else {
                navigate("/employment-details");
                // setFormCompletedStatus(99);
            }
        }

    }, [locationDetailsResponse])



    const submitFn = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        
        saveLocationDetail()

        // if(permanentAddress.addressType === 2){
            // saveLocationDetail();
        // }
        // if (permanentAddressId === "") {
        //     setOpenModal(true);
        // }
    };

    const handleInfoChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        // const { name, value } = e.target;
        // if(permanentAddressId === ""){
        //  setLocationDetails({ ...locationDetails, [name]: value });
        // }else{
        //     setPermanentAddress({...permanentAddress,[name]: value})
        // }
        const { name, value } = e.target;


        if (name === "address"){
            setLocationDetails({ ...locationDetails, [name]: value?.trimStart() });

        }else {
            setLocationDetails({ ...locationDetails, [name]: value });
            
        }



        if (name === "state"){

          
            fetchCities({
                variables:{
                    stateId:+value
                }
            })
           

        }

    

    }

    const saveLocationDetail = () => {
        saveLocationDetails({
            variables: {
                input: {...locationDetails,isSameAsPermanent: isPermanentAdressSame}
            }
        })
    }


    // const saveLocationDetail = () => {
    //     saveLocationDetails({
    //         variables: {
    //             input: {
    //                 currentAddress:locationDetails,
    //                 isCurrentSameAsPermanent: permanentAddressId === "1" ? true : false,
    //                 permanentAddress:permanentAddress
    //             }
    //         }
    //     })
    // }

    const handleIsPermanentAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        // if (value === "2") {  // if permanent address is not same as current address
        //     setPermanentAddress({...permanentAddress, addressType : 2});
        // }else{
        //     let permanentAddressObj = {...locationDetails, addressType : 2}; 
        //     setPermanentAddress(permanentAddressObj);
        // }
        // setPermanentAddressId(value)
        // setOpenModal(!openModal);
        // setFormCompletedStatus(75);


        setIsPermanentAdressSame(value === "1" ? true :false)
        // const { value } = e.target;
        if (value === "2") {  // if permanent address is not same as current address
            locationDetailsInfo.addressType = 2;
            setLocationDetails(locationDetailsInfo);
        } else {
            setLocationDetails({ ...locationDetails, addressType: 2 });
        }
        setPermanentAddressId(value)
        setOpenModal(!openModal);
        setFormCompletedStatus(50);
    

    }

    const permanentAddressModal = () => {
        return (
            <Box sx={{mt:2}}>
                <div className="fs-24 fw-bold">Current address same as your permanent address?</div>
                {(currentAddressInput || []).map((item: any, index: number) => (
                    // <RadioGroup 
                    // value={item.id}
                    // name="isPermanentAddress"
                    // id={item.id}
                    // className={item === item.id ? 'employment-type-input selected-input' : 
                    // 'employment-type-input'} key={item.id}>
                        <div className={item === item.id ? 'employment-type-input selected-input' : 'employment-type-input'} key={item.id}>

                        <span>{item?.value}</span>
                        <Radio
                            disableRipple
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleIsPermanentAddress(e)}
                            value={item.id}
                            name="isPermanentAddress"
                            id={item.id}
                            checked={item.id === +permanentAddressId}
                        />
                        </div>
                    
                ))}
            </Box>
        )
    }

    const isDisabledButton = () => {
        if (loading === false && locationDetails.residenceType !== "" && locationDetails.address !== "" && locationDetails.city !== "" && locationDetails.pincode !== "") {
            return false;
        }
        return true;
    }

    const formView = () => {
        return (
            <>
                <img src={locationGif} className="icon-gif" alt='location-gif'/>
                <div className="fs-24 mt-4 heading">{locationDetails?.addressType === 1 ? "Enter your current address" : "Enter your permanent address"}</div>
                <form noValidate onSubmit={submitFn}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="demo-simple-select-helper-label">Residence type</InputLabel>
                        <Select
                            required
                            name='residenceType'
                            label="Residence type"
                            id="demo-simple-select-helper"
                            onChange={(e: any) => handleInfoChange(e)}
                            value={locationDetails.residenceType}

                            // value={permanentAddressId === "" ? locationDetails.residenceType : permanentAddress.residenceType}                            //  error={ValidationErrorMessage.residentTypeValidation}
                            className={permanentAddressId === "1" ? "pointer-none details-input" : "details-input"}>
                            {/* <MenuItem value="">Select your residence type</MenuItem> */}
                            {(residentTypeList?.residenceType || []).map((residenceType: any) => (
                                <MenuItem key={residenceType.id} value={residenceType.id}>{residenceType.name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>


                    <FormControl fullWidth margin="normal">
                        <TextField
                            required
                            inputProps={{ readOnly: permanentAddressId === "1" ? true : false }}
                            name="address"
                            label="Address"
                            placeholder="Enter your address"
                            multiline
                            rows={3}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInfoChange(e)}
                            value={locationDetails.address}
                            // value={permanentAddressId === ""  ? locationDetails.address : permanentAddress.address}
                            error={locationDetails.address !== "" && validateAddress(locationDetails.address) === false}
                            autoComplete="off"
                        />
                    </FormControl>
                    {/* <Form.Control.Feedback type="invalid">
                                {ValidationErrorMessage.addressValidation}
                            </Form.Control.Feedback> */}

                  
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="stateId">State</InputLabel>
                        <Select required name="state"
                            label="State"
                            id="stateId"
                            inputProps={{}}
                            onChange={(e: any) => handleInfoChange(e)}
                            value={locationDetails.state}
                            MenuProps={MenuProps}
                            // value={permanentAddressId === ""  ? locationDetails.state : permanentAddress.state} 
                            className={permanentAddressId === "1" ? "pointer-none details-input" : "details-input"}>
                            {(stateList?.state || []).map((state: any) => (
                                <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
                            ))}

                        </Select>
                        {/* <Form.Control.Feedback type="invalid">
                                {ValidationErrorMessage.stateValidation}
                            </Form.Control.Feedback>  */}
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel id="cityId">City / Town</InputLabel>
                        <Select required name="city"
                            label="City Town"
                            id="cityId"
                            onChange={(e: any) => handleInfoChange(e)}
                            value={locationDetails.city}
                            disabled={!Boolean(locationDetails.state)}
                            MenuProps={MenuProps}
                            // value={permanentAddressId === ""  ? locationDetails.city : permanentAddress.city}
                            className={permanentAddressId === "1" ? "pointer-none details-input" : "details-input"}
                            error={Boolean(locationDetails.city)}
                            // helperText={Boolean(locationDetails.city) ? "" : "Select City"}
                            
                            >
                            {(citiesList || []).map((city: any) => (
                                <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                            ))}
                            

                        </Select>
                        {/* <Form.Control.Feedback type="invalid">
                                {ValidationErrorMessage.cityValidation}
                            </Form.Control.Feedback> */}

                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField type="number" required placeholder="Enter your pincode" name="pincode"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInfoChange(e)}
                            autoComplete="off"
                            label="Pincode"
                            inputProps={{ readOnly: permanentAddressId === "1" ? true : false }}
                            value={locationDetails.pincode}
                            // value={permanentAddressId === ""  ? locationDetails.pincode : permanentAddress.pincode}
                            className="details-input"
                            helperText={(locationDetails.pincode !== "" && validatePinCode(locationDetails.pincode) === false) ? ValidationErrorMessage.pincodeValidation : ""}
                            error={locationDetails.pincode !== "" && validatePinCode(locationDetails.pincode) === false} />
                        {/* <Form.Control.Feedback type="invalid">
                                {ValidationErrorMessage.pincodeValidation}
                            </Form.Control.Feedback> */}

                    </FormControl>
                    <div className="mt-35">
                        <AppButton text="Next"
                            className="w-100 app-btn"
                            isDisabled={isDisabledButton()}
                            loading={loading}
                            type="submit"
                        />
                    </div>
                    {/* <Box sx={{mt:2}} className="text-center mt-3">
                        <a href="#" className="app-text-color fs-14"
                        // onClick={(e: React.MouseEvent<HTMLElement>) => handleneedHelp(e)}
                        >Need help?</a>
                    </Box> */}
                </form>
                <AppModal openModal={openModal}>
                    {permanentAddressModal()}
                </AppModal>
            </>
        )
    }

    return (
        <>
            <div className="basic-details-view">
                <div className="app-logo w-100">
                    <LogoIcon/>
                    {/* <img src={Logo} alt="logo" />Logo */}
                </div>
                <div>
                    <div className="app-progress-bar w-100">
                        <ProgressBarComponent completedStatus={formCompletedStatus} />
                    </div>
                    <div className="details-form-view">
                        {formView()}
                    </div>
                </div>
            </div>
            <Fab
                color="primary"
                variant="extended"
                style={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    display: 'flex',
                    zIndex: 1000,
                }}
                onClick={() => {
                    const customerCode = token?.ecc.replaceAll("+","%2B")  || 'cus_code';
                    const iv = env.KAPTURE_IV || 'cus_iv';
                
                    const url = `https://selfserveapp.kapturecrm.com/web-view/webview_chat.html?&data-supportkey=${env.KAPTURE_PARTNER_KEY}&chat-for=TICKET&data-server=Indian&script_type=RNF&customer_code=${customerCode}&iv=${iv}`;
                    window.open(url, '_blank', 'noopener,noreferrer');
                }}
            >
            <Box sx={{display:'flex',gap:'0.5rem',alignItems:'center',justifyContent:'center'}}>
                <ChatIcon /> 
                <Typography textTransform={'capitalize'}>
                    Chat
                </Typography>
            </Box>
      </Fab>
        </>
    )
}

export default LocationDetails;