import React from "react";
import { useQuery } from "@apollo/client";
import { Paper, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableBody, Table, Typography, Box, Button } from "@mui/material";
import { LOANHISTORY } from "../../query/BankListingDetails/LoanHistoryQuery";
import Navbar from "../../components/Navbar/Navbar";
import { localTimeZoneFormat } from "../../utils";
import { useNavigate } from "react-router-dom";

const tableStyle = {
    boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.12)",
    marginTop: "30px",
    borderRadius: "12px",
};

const LoanHistory = () => {
    const { data: loanHistoryResponse, loading } = useQuery(LOANHISTORY, { fetchPolicy: "network-only", errorPolicy: "all" });
    const navigate = useNavigate();

    return (
        <>
            <Navbar menuId={2} />
            <section className="container-width">
                {loanHistoryResponse?.loanHistory.length > 0 ? (
                    <TableContainer component={Paper} sx={tableStyle}>
                        <Table
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                    fontFamily: "Poppins"
                                }
                            }}
                        >
                            <TableHead sx={{ background: "#FAFAFA" }}>
                                <TableRow>
                                    <TableCell align="center">Loan ID</TableCell>
                                    <TableCell align="center">Lender Name</TableCell>
                                    <TableCell align="center">Loan Applied Status</TableCell>
                                    <TableCell align="center">Updated At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(loading === false && loanHistoryResponse?.loanHistory || []).map((row: any, index: any) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{row?.requestuid}</TableCell>
                                        <TableCell align="center">{row.lenderName}</TableCell>
                                        <TableCell align="center">{row?.loanappliedstatus?.title}</TableCell>
                                        <TableCell align="center">{localTimeZoneFormat(+row?.createdAt)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box 
                        sx={{ 
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "center", 
                            alignItems: "center",
                            minHeight: 'calc(100vh - 100px)', 
                            textAlign: "center",
                        }}
                    >
                        <Typography 
                            sx={{
                                fontSize: { xs: "1.5rem", sm: "2rem" },
                                fontWeight: 900,
                                marginBottom: "20px"
                            }}
                        >
                            No Loan History Found
                        </Typography>
                        <Button 
                            variant="contained" 
                            onClick={() => navigate("/loans")}
                            sx={{ 
                                fontWeight: 600,
                                padding: '10px 20px',
                                borderRadius:"8px"

                            }}
                        >
                            Go Back
                        </Button>
                    </Box>
                )}
            </section>
        </>
    );
};

export default LoanHistory;