export const DecideVendor = () => {
  return (
    <>
      <section className="blog-container-width">
        <p
          style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}
        >
          <span style={{ color: "#000000" }}>
            <span>Pre-Wedding Loan Checklist:</span>
            <span>&nbsp;</span>
          </span>
          <span>How Do You Decide Your </span>
          <br />
          <span>Vendors?</span>
        </p>
        <div className="mt-4">
          <span>
            Selecting vendors for your wedding is a crucial step in the planning
            process. It shapes everything from the ambience to the overall
            experience of your big day. Whether you're looking for the perfect
            venue, a talented caterer, or a skilled photographer, each decision
            impacts your wedding budget. Plus, if you're considering a wedding
            loan to help manage these costs, it's essential to make informed
            choices
          </span>
          <br />
          <span>&nbsp;</span>
        </div>
        <span>
          So, let’s take you through a checklist for selecting vendors wisely,
          helping you navigate the process with confidence and financial clarity
          for your big wedding day!
        </span>
        <div>&nbsp;</div>
        <div className="heading-style text-center">
          A Checklist For Selecting Vendors
        </div>
        <div>
          <span className="subheading">1. Establish Your Budget</span>
          <br />
          <div>&nbsp;</div>
          <span>
            Before reaching out to vendors, set a clear wedding budget that
            includes savings, family contributions and any loans. This helps
            narrow your vendor choices and prevents overspending. Prioritise key
            areas like the venue, catering, or photography and reserve a portion
            for unexpected expenses.
          </span>
          <div>&nbsp;</div>
          <span className="subheading">2. Identify Key Vendors</span>
          <br />
          <div>&nbsp;</div>
          <span className="mt-10">
            Weddings require various vendors, but some demand more attention and
            budget than others. Key vendors include:
          </span>
          <ul className="expenses-list">
            <li>
              <strong>Venue:</strong>
              <span className="ml-10">
                The location sets the tone and often takes the biggest chunk of
                your budget
              </span>
            </li>
            <li>
              <strong>Catering:</strong>
              <span className="ml-10">
                Food is a major part of the experience, especially in Indian
                weddings where an elaborate feast is expected.
              </span>
            </li>
            <li>
              <strong>Photography/Videography:</strong>
              <span className="ml-10">
                Capturing your big day is vital, making this a high-priority
                choice.
              </span>
            </li>
            <li>
              <strong>Entertainment:</strong>
              <span className="ml-10">
                A good DJ or band ensures guests enjoy themselves.
              </span>
            </li>
            <li>
              <strong>Decorator:</strong>
              <span className="ml-10">
                Decorations enhance the ambience, making the venue visually
                appealing.
              </span>
            </li>
          </ul>
          <div>&nbsp;</div>
          <span>
            Other vendors like florists, makeup artists, accommodation and
            transportation are important but may require less investment
            depending on your preferences.
          </span>
          <div>&nbsp;</div>
          <span className="subheading">3. Research Vendor Costs</span>
          <br />
          <div>&nbsp;</div>
          <span>
            Before deciding on vendors, research average costs in your area, as
            prices vary by location, experience and season. For example, venues
            in cities like Mumbai or Delhi are often pricier than in smaller
            towns and experienced photographers may charge more. Gather quotes
            from multiple vendors and seek recommendations from recently married
            friends or family to understand pricing within your budget.
          </span>
          <div>&nbsp;</div>
          <span className="subheading">4. Check Vendor Availability</span>
          <br />
          <div>&nbsp;</div>
          <span>
            One major challenge in wedding planning is securing your preferred
            vendors for your date. Popular vendors book up months in advance,
            especially during peak seasons. Once you have a shortlist, check
            their availability early. If they're booked, ask for referrals to
            similar vendors or consider adjusting your wedding date to secure
            your ideal vendor at a better price.penses but also allows for a
            more intimate and personal celebration.
          </span>
          <br />
          <div>&nbsp;</div>
          <span className="subheading">5. Review Vendor Portfolios</span>
          <br />
          <div>&nbsp;</div>
          <span className="mt-10">
          Each vendor has a distinct style, so reviewing their portfolio is important. Choose those whose work aligns with your wedding vision—for instance, a photographer specializing in candid shots if that's what you prefer. For decorators and florists, ask to see examples of past weddings. Many venues also recommend trusted vendors, which can simplify your decision-making process.
          </span>
          <div>&nbsp;</div>
          <span className="subheading">6. Read Reviews & Get References</span>
          <br />
          <div>&nbsp;</div>
          <span>
              Checking reviews and asking for references is key when choosing vendors. Websites like WeddingBazaar, WeddingWire, WedMeGood and even Google offer helpful feedback from past clients. Furthermore, ask vendors for references directly—reliable vendors will gladly share contact info. Speaking with previous clients gives valuable insight into their service and professionalism.
        </span>
          <div>&nbsp;</div>
          <span className="subheading">
          7. Consider Payment Schedules
          </span>
          <br />
          <div>&nbsp;</div>
          <span>
              When working with wedding vendors, it’s important to understand their payment schedules and terms. Most require a deposit to secure your date, with the balance due near the wedding. Ensure you have enough funds, especially if using a wedding loan. Some vendors offer payment plans but avoid paying in full upfront for day-of services like catering or entertainment.
          </span>
          <br />
          <div>&nbsp;</div>
          <span className="subheading">
          8. Negotiate with Vendors
          </span>
          <br />
          <div>&nbsp;</div>
          <span>
              Once you’ve narrowed down your options, don’t be afraid to negotiate. Many vendors are willing to adjust their pricing or offer discounts, especially if you’re booking multiple services from the same vendor (e.g., a photographer and videographer package). Some vendors may also offer discounts for weekday weddings or off-season bookings. Just be sure to negotiate professionally and respectfully, as vendors have set prices based on their experience, materials and time.
          </span>
          <br />
          <div>&nbsp;</div>
          <span className="subheading">
              9. Sign Contracts & Review Terms
          </span>
          <br />
          <div>&nbsp;</div>
          <span>
          Once you've selected your vendors, ensure you have a detailed contract that covers services, costs, payment terms, cancellation policies and extra fees. Review it carefully and ask for clarification if needed. A clear contract protects both you and the vendor, ensuring mutual understanding of expectations.          </span>
          <br />
          <div>&nbsp;</div>
          <span className="subheading">
          10. Stay Organized & Track Payments
          </span>
          <br />
          <div>&nbsp;</div>
          <span>
          As you move through the wedding planning process, keeping track of your vendor payments is essential. Use a spreadsheet or a wedding planning app to record deposits, balances and due dates for each vendor. This will help you stay on top of your budget and avoid late payments, which could result in penalties or losing your vendor altogether.          </span>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>

          
            In conclusion, choosing the right wedding vendors is one of the most important aspects of planning your big day. By setting standards you can select vendors that align with your vision and budget, ensuring your wedding day is a seamless and beautiful celebration!

        </div>
      </section>
    </>
  );
};
