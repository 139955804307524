export const TypesOfLoan = () => {
  return (
    <section className="blog-container-width">
      <div className="text-center">
        <span
          style={{
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          <span>
            What Are the Different Kinds of Personal Loans Available for
          </span>
          <br />
          <span>Financing Your Wedding?</span>
        </span>
      </div>
      <div className="mt-3">
        <span>
          Indian weddings are known for their grandeur, spanning from elaborate
          ceremonies to grand receptions. However, with great celebrations come
          significant expenses. A report by The Economist highlights that the
          Indian wedding industry sees a staggering $130 billion in annual
          spending, ranking as the fourth-largest in the world. For many,
          managing such expenses requires a thoughtful financial plan—and often,
          personal loans are a popular route.
        </span>
        <br />
        <div className="mt-10">
          Here’s a closer look at the various personal loans available to help
          you fund the wedding of your dreams:
        </div>
      </div>
      <div className="mt-10">
        <strong>1. Personal Loans for Marriage</strong>
        <div className="mt-10">
          One of the most common options for wedding financing, a personal loan
          is a versatile and easily accessible choice. These loans are
          unsecured, meaning they don’t require collateral, and they can be used
          for a variety of wedding-related expenses—from venue bookings to
          catering and photography.
          <div className="mt-10">
            - Interest Rates: Typically range between 10% and 30% per annum,
            depending on the lender and your credit score.
          </div>
          <div className="mt-10">
            - Loan Amount: Varies based on your creditworthiness, but most
            lenders offer up to ₹25 lakhs for personal loans.
          </div>
          <div className="mt-10">
            - Repayment Tenure: Generally, spans 1 to 5 years.{" "}
          </div>
          <div className="mt-10">
            The flexibility and speed of personal loans make them a go-to option
            for many couples, but the higher interest rates compared to secured
            loans might be a drawback.
          </div>
        </div>
      </div>
      <div>&nbsp;</div>
      <strong> 2. Loan Against Mutual Funds</strong>
      <div className="mt-10">
        If you have investments in mutual funds, you can leverage them for a
        loan against mutual funds. This is a secured loan where you use your
        mutual fund units as collateral while continuing to earn returns on your
        investment.
        <div className="mt-10">
          - Interest Rates: These loans usually come with interest rates as low
          as 10.5% per annum.
        </div>
        <div className="mt-10">
          - Loan Amount: The loan amount depends on the value of your mutual
          fund portfolio, and you can typically borrow up to 50% of the fund's
          value.
        </div>
        <div className="mt-10">
          - Quick Disbursal: With some companies, the process can be
          fast-tracked, with loan approval in 7 minutes and the amount disbursed
          within 4 business hours.{" "}
        </div>
        <div className="mt-10">
          This option is perfect for individuals looking to maintain their
          long-term investment plans while covering short-term wedding expenses.
        </div>
      </div>
      <div>&nbsp;</div>
      <strong>3. Gold Loans for Marriage</strong>
      <div className="mt-10">
        Indian households often have considerable gold assets, which makes gold
        loans a favourable option for financing weddings. Here, gold jewellery
        is used as collateral, allowing you to access funds swiftly.
        <div className="mt-10">
          - Interest Rates: Typically range between 9% to 17% depending on the
          lender.
        </div>
        <div className="mt-10">
          - Flexible Repayment Options: Lenders often provide bullet payments,
          where you only repay the principal at the end of the loan term while
          servicing interest regularly.
        </div>
        <div className="mt-10">
          - Quick Processing: Minimal documentation is required, making this a
          fast and efficient option.
        </div>
        <div className="mt-10">
          Gold loans are especially useful for families who already possess gold
          and want a low-risk, secure loan with quick disbursal.
        </div>
      </div>
      <div>&nbsp;</div>
      <strong>4. Loan Against Fixed Deposits (FD)</strong>
      <div className="mt-10">
        If you have a fixed deposit, you can avoid prematurely breaking it by
        opting for a loan against your FD. This ensures you continue earning
        interest on the FD while accessing funds for your wedding.
        <div className="mt-10">
          - Interest Rates: Typically 1-2% higher than the FD interest rate.
        </div>
        <div className="mt-10">
          - Quick Processing: As the FD serves as collateral, the process is
          usually seamless and fast.
        </div>
        <div className="mt-10">
          - No Penalty: You don’t lose out on the interest from the FD, making
          this an attractive low-cost loan option.
        </div>
        <div className="mt-10">
          For those with substantial savings, borrowing against an FD can be a
          cost-effective way to manage wedding expenses without withdrawing from
          long-term investments.
        </div>
      </div>
      <div>&nbsp;</div>
      <strong> 5. Loan Against Property (LAP) for Marriage</strong>
      <div className="mt-10">
        For homeowners, a loan against property offers the opportunity to borrow
        large sums at relatively lower interest rates. This loan is secured by
        your real estate.
        <div className="mt-10">
          - Interest Rates: Generally range between 8% to 9% per annum.{" "}
        </div>
        <div className="mt-10">
          - Loan Tenure: Longer repayment tenures, sometimes extending up to 15
          to 20 years.{" "}
        </div>
        <div className="mt-10">
          - Large Loan Amounts: The loan amount can go as high as 60% to 70% of
          the property’s market value.
        </div>
        <div className="mt-10">
          While the interest rates are attractive, failure to repay the loan
          could result in the lender taking ownership of the property, making it
          a more significant risk than unsecured loans.{" "}
        </div>
      </div>
      <div>&nbsp;</div>
      <strong> 6. Top-Up Loan on Existing Home Loan</strong>
      <div className="mt-10">
        If you’re already repaying a home loan, you can opt for a top-up loan.
        This is an additional loan on top of your existing home loan, with no
        restrictions on how the funds are used.
        <div className="mt-10">
          - Interest Rates: Usually fall between 8.70% to 9.55% per annum,
          making it an affordable option.
        </div>
        <div className="mt-10">
          - Minimal Documentation: Since you already have a home loan with the
          lender, this top-up loan is processed quickly with little paperwork.
        </div>
        <div className="mt-10">
          This loan is ideal for homeowners who have ongoing home loan payments
          but need extra funds for their wedding.
        </div>
      </div>
      <div>&nbsp;</div>
      <strong> 7. Flexi Loans</strong>
      <div className="mt-10">
        A Flexi loan is linked to your bank account, offering you flexibility in
        drawing funds as and when required. It also offers lower interest rates
        on the balance you save in your account.
        <div className="mt-10">
          - Interest Rates: The interest rates vary but are generally
          affordable.
        </div>
        <div className="mt-10">
          - Flexible Withdrawals: You can withdraw money as needed, giving you
          greater control over your loan utilization.
        </div>
        <div className="mt-10">
          Flexi loans are best for individuals who want ongoing flexibility in
          accessing funds while planning a wedding.
        </div>
      </div>
      <div>&nbsp;</div>
      <strong>8.Personal Loan Against Overdraft for Weddings</strong>
      <div className="mt-10">
        A personal loan against an overdraft is a flexible option tied to your
        bank account, offering access to additional funds for wedding expenses
        without needing a separate loan. It allows you to withdraw funds as
        needed, up to a predetermined limit.
        <div className="mt-10">
          Interest Rates: You only pay interest on the amount withdrawn, with
          rates typically ranging from 9% to 15%.
        </div>
        <div className="mt-10">
          Flexible Withdrawals: Funds can be accessed instantly for any
          wedding-related expenses.
        </div>
        <div className="mt-10">
          This option is ideal for couples seeking quick access to funds with
          the flexibility to borrow only what they need.
        </div>
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>

      <strong> Choosing the Right Loan for Your Wedding</strong>
      <div className="mt-10">
        When choosing a loan for your wedding, it's essential to evaluate your
        financial situation, repayment capacity, and the loan’s terms. If you
        have assets like mutual funds, fixed deposits, or gold, a secured loan
        might be the better option. If you're looking for a quicker, unsecured
        option, personal loans or top-up loans are ideal.
        <div className="mt-10">
        Each loan type offers unique benefits, but they all serve one purpose—helping you create the wedding of your dreams while managing your finances smartly.
        </div>
        <div className="mt-10">
        Thus, weddings may be one of the most significant expenses in your life, but the right loan can ensure your celebration is everything you’ve imagined. Whether you opt for a personal loan or choose to leverage your assets, understanding your options will help you plan better and reduce stress as your big day approaches.
        </div>
      </div>
    </section>
  );
};
