import * as React from "react"
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width={150}
    height={28}
    x={0}
    y={0}
    {...props}
  >
    <style>{".st0{fill:#9e1c33}"}</style>
    <path
      d="M145.6 3.921c0-.39.098-.756.294-1.098s.464-.611.805-.809.71-.296 1.105-.296a2.187 2.187 0 0 1 1.903 1.097c.197.345.297.714.297 1.106 0 .39-.097.753-.29 1.093s-.457.61-.797.809-.71.299-1.113.299c-.404 0-.777-.101-1.12-.304s-.608-.472-.797-.81a2.168 2.168 0 0 1-.286-1.087zm.361 0a1.832 1.832 0 0 0 .914 1.59c.285.17.595.253.929.253.338 0 .65-.085.936-.255a1.832 1.832 0 0 0 .903-1.588c0-.322-.08-.625-.24-.91a1.81 1.81 0 0 0-.667-.679 1.789 1.789 0 0 0-.932-.253c-.328 0-.636.082-.921.247-.287.165-.512.39-.676.677s-.246.592-.246.918zm2.813-.515a.705.705 0 0 1-.133.425.898.898 0 0 1-.383.287l.618 1.092h-.586l-.504-.952h-.266v.952h-.522V2.638h.788c.658 0 .988.256.988.768zm-1.254.43h.21c.165 0 .29-.035.379-.105a.351.351 0 0 0 .131-.29c0-.143-.045-.241-.132-.296a.724.724 0 0 0-.377-.082h-.211v.773zM19.803 12.1l.9-4.2h-9.1l.8-3.6h10.4l.9-4.3h-18.9l-4.8 21.2h19.5l.9-4.5h-10.7l1-4.6zM45.102 12.1c.4-2 .7-3.9.7-3.9 1.2-6.6-5.4-6.6-9.6-6.7-6.1-.2-11.3-.2-13.2 7.3 0 0-1 3.9-1.2 6.9-.3 6.1 6.2 5.9 9.7 6.1 3.5.1 5.7-.6 5.7-.6l5.8 6.8h8.8l-13.3-15.9h6.6zm-6.8-3.6s-.5 3.6-1.8 7.2c-.6 1.7-2.6 2.6-4.1 2.6-1.7 0-3.6-.9-3.1-3.4 0 0 .5-3.4 1.8-7.2 0 0 1.1-2.8 4.1-2.6 0 0 3.8-.6 3.1 3.4z"
      className="st0"
    />
    <path
      d="m63.3 2.4-3.1 13.7c-.6 2.5-3.699 2.1-3.699 2.1-2.4 0-2.4-2.2-2.4-2.2.5-2.7 3-13.5 3-13.5h-7.7c-.6 2.2-2.5 10.9-2.5 10.9l7.1 8.3c2 .3 3.8.3 4.8.3 5.9-.3 8.5-2.6 9-4.3.5-1.7 3.5-15.2 3.5-15.2h-8v-.1zM74 2.4l-4.1 18.8h7.7l4.3-18.8zM98.799 9.5h-7.7L92 6h8.9l.9-3.6h-17l-4.1 18.8h7.8l1.9-8.1H98zM136.297 2.4l-4.9 4.8-3-4.8h-10l6.2 9.4-6.3 5.8-1.9-15.2h-9.399L93.7 21.2h5.5l2.8-4.2h8.2l.4 4.1h11.699l5.4-5.6 3.3 5.6h9.599l-6.6-10 9.9-8.7h-7.6zM104.599 13l4.3-6.2.6 6.2h-4.9z"
      className="st0"
    />
  </svg>
)
export default SvgComponent