import "./loans.scss";
import {
    Step,
    LinearProgress, 
    useTheme,
    StepLabel, Stepper, StepConnector, stepConnectorClasses, StepIconProps, Avatar, Typography, Box, Card, Button, CardContent, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TickIcon from "../../components/TickIcon";
import ClockIcon from "../../components/ClockIcon";



import { StatusIds } from "../../constants";
import { AccessTime, CalendarToday } from '@mui/icons-material';

import IncompleteApplicationIcon from '../../assets/IncompleteApplicationIcon.svg'
import LogoMapper from "../../assets/Vendors";





const steps = [
    {
        label: "Created",
    },
    {
        label: "Under Process",
    },
    {
        label: "Approved",
    },
    {
        label: "Document Verification",
    },
    {
        label: "Disbursement",
    }
];


interface LoanCoolingPeriodCardProps {
    applyDate: Date;
}

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean, error?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#EC9A3B',
    zIndex: 1,
    color: '#fff',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    width: 26,
    height: 26,
    ...(ownerState.active && {
        backgroundColor: "#4bb543"
    }),
    ...(ownerState.completed && {
        backgroundColor: "#4bb543"
    }),
    ...(ownerState.error && {
        fontSize: 12,
        backgroundColor: "#d32f2f"
    })
}));


const StyledCard = styled(Card)(({ theme }) => ({
    // width: "500px",
    // height: "300px",
    padding: "20px",
    flexShrink: 0,
    borderRadius: "12px",
    background: "#FFF",
    boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.12)",
}));

const IconContainer = styled(Box)({
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
});

const StyledButton = styled('a')(({ theme }) => ({
    display: "flex",
    width: "600px",
    height: "48px",
    padding: "8px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#020164",
    color:"#FFFF",
    textDecoration:'none'
}));


function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, error, className } = props;
    const icons: { [index: string]: React.ReactElement } = {
        1: <TickIcon />,
        2: <ClockIcon />,
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active, error }}
            className={className}
        >
            {(props.active || props.completed) ? icons[String(1)] : (props.error) ? "X" : icons[String(2)]}
        </ColorlibStepIconRoot>
    );
}


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        //  top: 12,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#4bb543"
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#4bb543"

        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderWidth: "2px",
        borderRadius: 1,
        //  marginLeft: "-10px",
        //  marginRight: "-20px"
    }

}));

const Loans = ({ appliedLoan }: any) => {
    const matches = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    // console.log("matc", matches);

    const getActiveSteps = () => {
        if (+appliedLoan[0]?.loanappliedstatusId === StatusIds.loanStatus.created) {
            return 0
        } else if (+appliedLoan[0]?.loanappliedstatusId === StatusIds.loanStatus.underprocess) {
            return 1
        } else if ((+appliedLoan[0]?.loanappliedstatusId === StatusIds.loanStatus.rejected || +appliedLoan[0]?.loanappliedstatusId === StatusIds.loanStatus.approved)) {
            return 2
        } else if (+appliedLoan[0]?.loanappliedstatusId === StatusIds.loanStatus.documentVerification) {
            return 3
        } else {
            return 4;
        }
    }

    const IncompleteApplicationCard = ({ portalLink }: any) => (

        <Box style={{ marginTop: '40px' }} >
            <div className="fw-bold fw-20" style={{ marginBottom: "20px" }}>{"Incomplete application"}</div>
            <StyledCard>
                <CardContent sx={{ mt: 3 }}>
                    <IconContainer>
                        <img src={IncompleteApplicationIcon} alt="Incomplete_application_Icon" />
                    </IconContainer>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 600 }} gutterBottom>
                        Your application is incomplete
                    </Typography>
                    <Typography variant="body2" >
                        Complete now to process your loan amount
                    </Typography>
                    <Box mt={3} display="flex" justifyContent="center">
                        <StyledButton target="_blank" href={portalLink}>Complete now</StyledButton>
                    </Box>
                </CardContent>
            </StyledCard>
        </Box>
    );

    const stepperView = () => {
        let label = +appliedLoan[0]?.loanappliedstatusId === StatusIds.loanStatus.rejected ? steps.slice(0, 3) : steps;
        return (
            <div className="mt-4">
                <div className="fw-bold fw-20">{"Loan details"}</div>
                <div className="loan-details-status-card-view" style={{ height: "100%" }}>
                    <>
                        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                            <Avatar sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "8px",
                                border: "1px solid #D8D8D8",
                            }} src={LogoMapper[appliedLoan[0]?.lenderName]} alt={appliedLoan[0]?.lenderName} />

                            <Typography sx={{
                                color: "#000",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal"
                            }} >{appliedLoan[0]?.lenderName  === "CASHE" ? "Bhanix Finance and Investment Limited" : appliedLoan[0]?.lenderName  }</Typography>
                        </Box>
                        {/* {(appliedLoan[0]?.loanappliedstatusId === 3 || appliedLoan[0]?.loanappliedstatusId === 4) ?
                                    <>
                                        <div className="divider"></div>
                                        <div className="d-flex">
                                            <div className="loan-approved-view">
                                                <TickIcon/>
                                            </div>
                                            <div>
                                                <div>₹5,50,000</div>
                                                <div>Approved on 12th May, 2024</div>
                                            </div>
                                        </div>
                                    </>
                                    : */}
                        <Stepper
                            sx={{ marginTop: "40px" }}
                            alternativeLabel={matches ? false : true}
                            orientation={matches ? "vertical" : "horizontal"}
                            activeStep={getActiveSteps()}
                            connector={<ColorlibConnector />}
                        >
                            {(label).map((item: any, index: any) => (
                                <Step key={item.label} >
                                    <StepLabel StepIconComponent={ColorlibStepIcon}
                                        error={(+appliedLoan[0]?.loanappliedstatusId === StatusIds.loanStatus.rejected && index === 2)}>{(+appliedLoan[0]?.loanappliedstatusId === StatusIds.loanStatus.rejected && index === 2) ? "Rejected" : item.label}</StepLabel>
                                    {/* <div className={index + 1 === 1 ? "verfied-text text-center" : "pending-text text-center"}>{item.desc}</div> */}
                                    {/* <StepContent>
                                                <Typography>{item.desc}</Typography>
                                            </StepContent> */}
                                </Step>
                            ))}
                        </Stepper>
                    </>


                </div>
                {/*  Incomplete application  */}
                {appliedLoan[0]?.portalLink && <IncompleteApplicationCard portalLink={appliedLoan[0]?.portalLink} />}
            </div>
        )
    }




    const LoanCoolingPeriodCard: React.FC<LoanCoolingPeriodCardProps> = ({ applyDate }) => {
        const theme = useTheme();

        const formatDate = (date: Date): string => {
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        };

        // console.log("Date",applyDate)

        const calculateCoolingPeriodEndDate = (applicationDate: Date): Date => {
            const endDate = new Date(applicationDate);
            endDate.setDate(endDate.getDate() + 30);
            return endDate;
        };

        const calculateProgress = (): number => {
            const now = new Date();
            const total = 30 * 24 * 60 * 60 * 1000; 
            const elapsed = now.getTime() - applyDate.getTime();
            return Math.min(100, (elapsed / total) * 100);
        };

        const progress = calculateProgress();
        const endDate = calculateCoolingPeriodEndDate(applyDate);

        return (
            <Card sx={{
                maxWidth: 500,
                margin: 'auto',
                mt: 25,
                borderRadius: "12px",
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
                overflow: 'hidden',
            }}>
                <Box sx={{
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    height: '8px',
                }} />
                <CardContent sx={{ padding: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                            You Are in Cooling Period
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                            <CalendarToday sx={{ mr: 1, color: theme.palette.text.secondary }} />
                            <Typography variant="body1" color="text.secondary">
                                Application Date: {formatDate(applyDate)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <AccessTime sx={{ mr: 1, color: theme.palette.success.main }} />
                            <Typography variant="body1" sx={{ color: theme.palette.success.main, fontWeight: 'bold' }}>
                                You Can Apply After: {formatDate(endDate)}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    backgroundColor: theme.palette.grey[200],
                                    '& .MuiLinearProgress-bar': {
                                        borderRadius: 5,
                                        backgroundColor: theme.palette.success.main,
                                    },
                                }}
                            />
                            <Typography variant="body2" color="text.secondary" align="right" sx={{ mt: 1 }}>
                                {Math.round(progress)}% Complete
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    return (
        <>
            {/* <div className="need-help-section">
                <span className="fw-bold">Need help?{" "}</span>
                <span>Contact your Relationship Manager{" "}</span>
                <span className="app-text-color fw-500">+91 9876543210</span>
            </div> */}

            {appliedLoan[0]?.withInCoolingPeriod ?
                <Box >
                    <LoanCoolingPeriodCard applyDate={new Date(appliedLoan[0]?.createdAt)} />
                </Box>
                :
                <section className="bank-details-view">
                    {/* <div className="banner-view d-flex justify-content-center align-items-center">
                <div className="">Banner</div>
               </div> */}
                    {stepperView()}
                    {/* <div className="mt-4">
                    <div className="fw-bold fw-20">Brand Vouchers</div>
                </div> */}
                </section>
            }
        </>
    )

}

export default Loans;