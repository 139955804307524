import React from 'react';
import { Box, Typography } from '@mui/material';

function ProcessingFee({ discountPercentage = '', actualPercentage = '' }) {
  return (
    <Box display="flex" alignItems="center" justifyContent={"center"}>

      {discountPercentage ? (<>
        <Box
          component="span"
          sx={{
            position: 'relative',
            marginRight: 1.5
          }}
        >
          <Typography variant="body1" sx={{ color: 'black' }}>
            {actualPercentage}
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              top: '35%',
              width: '100%',
              height: '2.6px',
              backgroundColor: 'red',
              transform: 'rotate(-30deg)'
            }}
          />
        </Box>
        <Typography variant="body1" sx={{ color: 'black' }}>
          {discountPercentage}
        </Typography>
      </>) : (<Typography variant="body1" sx={{ color: 'black' }}>
        {actualPercentage}
      </Typography>)}
    </Box>
  );
}

export default ProcessingFee;
