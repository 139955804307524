
export const StatusIds = {
    othersOraganisationId: 5,
    userStatus:{
        newUser: 1,
        basicDetailsStatus:2,
        currentAddressStatus:3,
        permanentAddressStatus:4,
        employmentDetails:5
    },
    loanStatus: {
        created:1,
        underprocess:2,
        rejected:3,
        approved:4,
        documentVerification:5,
        disburesement:6
    }
}