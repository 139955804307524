import * as React from "react"
import { SVGProps } from "react"
const CreditScoreForm = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={156}
        height={162}
        fill="none"
        {...props}
    >
        <g filter="url(#a)">
            <rect
                width={73.267}
                height={101.698}
                x={24.541}
                y={26.481}
                fill="#fff"
                rx={4.902}
            />
        </g>
        <g clipPath="url(#b)" opacity={0.2}>
            <path
                fill="#7084AC"
                d="m75.169 83.267-28.52-.516-.03 1.622 28.52.517.03-1.623ZM63.323 88.07l-16.766-.304-.03 1.623 16.766.303.03-1.622Z"
            />
            <path
                fill="#4FD69C"
                d="M37.996 89.911a1.057 1.057 0 0 1-.722-.3l-1.891-1.831a1.057 1.057 0 0 1-.023-1.5 1.066 1.066 0 0 1 1.504-.023l.972.938 3.006-4.507c.325-.49.987-.621 1.475-.298.49.324.623.985.298 1.472l-3.713 5.57a1.067 1.067 0 0 1-.772.467c-.044.006-.09.006-.134.006v.006Z"
            />
            <path
                fill="#7084AC"
                d="m74.89 98.353-28.52-.516-.03 1.623 28.52.516.03-1.623ZM63.044 103.156l-16.767-.304-.03 1.623 16.767.304.03-1.623Z"
            />
            <path
                fill="#4FD69C"
                d="M37.717 104.997a1.05 1.05 0 0 1-.722-.301l-1.89-1.83a1.056 1.056 0 0 1-.023-1.5 1.065 1.065 0 0 1 1.504-.023l.971.938 3.006-4.507c.325-.49.988-.621 1.476-.297.49.323.622.985.298 1.471l-3.714 5.571a1.068 1.068 0 0 1-.771.466c-.044.007-.09.006-.134.006v.006Z"
            />
        </g>
        <g filter="url(#c)">
            <rect
                width={73.259}
                height={101.71}
                fill="#fff"
                rx={4.902}
                transform="rotate(-11.966 187.463 -169.258) skewX(.069)"
            />
        </g>
        <g clipPath="url(#d)">
            <path
                fill="url(#e)"
                d="m79.176 44.947 1.92 9.88a15.718 15.718 0 0 1 9.406 2.05l5.827-8.212c-4.961-3.106-10.981-4.551-17.153-3.718Z"
            />
            <path
                fill="url(#f)"
                d="M70.232 60.76a15.722 15.722 0 0 1 7.933-5.367l-1.917-9.881a25.747 25.747 0 0 0-14.495 9.798l8.48 5.45Z"
            />
            <path
                fill="url(#g)"
                d="m98.76 50.388-5.829 8.211a15.63 15.63 0 0 1 5.165 8.891l9.882-1.91c-1.211-6.236-4.598-11.507-9.217-15.195v.003Z"
            />
            <path
                fill="url(#h)"
                d="M60.137 57.813c-2.923 5.148-4.103 11.317-2.888 17.569l9.883-1.91a15.63 15.63 0 0 1 1.484-10.213l-8.48-5.446Z"
            />
            <path
                fill="#020164"
                d="M80.773 68.097c-.356.591 1.192 2.878 1.981 2.593 1.302-.47 8-7.18 7.658-7.823-.343-.642-9.05 4.253-9.639 5.23Z"
                opacity={0.87}
            />
        </g>
        <g clipPath="url(#i)">
            <path
                fill="#020164"
                d="m106.084 79.297-28.005 5.409.31 1.593 28.004-5.409-.309-1.593ZM95.5 86.45l-16.464 3.18.31 1.594 16.463-3.18-.31-1.593Z"
                opacity={0.2}
            />
            <path
                fill="#4FD69C"
                d="M71.111 93.504c-.262.052-.538 0-.769-.145l-2.23-1.398c-.5-.313-.65-.968-.336-1.463.314-.495.97-.648 1.467-.334l1.146.716 2-5.032a1.065 1.065 0 0 1 1.382-.597c.547.215.814.835.598 1.378l-2.471 6.219c-.117.292-.359.52-.658.616-.042.016-.087.025-.13.034l.001.006Z"
            />
            <path
                fill="#020164"
                d="m108.959 94.11-28.005 5.41.31 1.593 28.004-5.41-.309-1.592ZM98.373 101.265l-16.463 3.179.31 1.594 16.463-3.18-.31-1.593Z"
                opacity={0.2}
            />
            <path
                fill="#4FD69C"
                d="M73.985 108.318a1.06 1.06 0 0 1-.769-.145l-2.23-1.398c-.5-.313-.65-.968-.336-1.463.314-.495.97-.648 1.467-.335l1.146.717 2-5.032a1.065 1.065 0 0 1 1.381-.597c.548.215.815.834.599 1.378l-2.471 6.218c-.118.292-.359.52-.658.617-.042.015-.087.024-.13.034l.001.006Z"
            />
        </g>
        <defs>
            <linearGradient
                id="e"
                x1={55.248}
                x2={105.306}
                y1={58.175}
                y2={45.822}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF2D6E" />
                <stop offset={0.32} stopColor="#FFAC32" />
                <stop offset={0.67} stopColor="#FFF755" />
                <stop offset={1} stopColor="#1BCA7F" />
            </linearGradient>
            <linearGradient
                id="f"
                x1={55.103}
                x2={105.161}
                y1={57.587}
                y2={45.234}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF2D6E" />
                <stop offset={0.32} stopColor="#FFAC32" />
                <stop offset={0.67} stopColor="#FFF755" />
                <stop offset={1} stopColor="#1BCA7F" />
            </linearGradient>
            <linearGradient
                id="g"
                x1={57.954}
                x2={108.012}
                y1={69.136}
                y2={56.781}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF2D6E" />
                <stop offset={0.32} stopColor="#FFAC32" />
                <stop offset={0.67} stopColor="#FFF755" />
                <stop offset={1} stopColor="#1BCA7F" />
            </linearGradient>
            <linearGradient
                id="h"
                x1={57.462}
                x2={107.523}
                y1={67.156}
                y2={54.803}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FF2D6E" />
                <stop offset={0.32} stopColor="#FFAC32" />
                <stop offset={0.67} stopColor="#FFF755" />
                <stop offset={1} stopColor="#1BCA7F" />
            </linearGradient>
            <clipPath id="b">
                <path
                    fill="#fff"
                    d="m36.345 77.826 40.835 5.725-3.728 26.461-40.836-5.724z"
                />
            </clipPath>
            <clipPath id="d">
                <path
                    fill="#fff"
                    d="m55.04 46.576 51.356-3.582 2.02 28.819-51.356 3.582z"
                />
            </clipPath>
            <clipPath id="i">
                <path
                    fill="#fff"
                    d="m66.976 82.026 41.137-2.87 1.869 26.657-41.138 2.87z"
                />
            </clipPath>
            <filter
                id="a"
                width={122.291}
                height={150.722}
                x={0.029}
                y={5.645}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy={3.677} />
                <feGaussianBlur stdDeviation={12.256} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0.847059 0 0 0 0 0.772549 0 0 0 0 1 0 0 0 1 0" />
                <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_679_2751"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_679_2751"
                    result="shape"
                />
            </filter>
            <filter
                id="c"
                width={140.071}
                height={161.869}
                x={15.566}
                y={0.074}
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy={3.677} />
                <feGaussianBlur stdDeviation={12.256} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0.845111 0 0 0 0 0.772222 0 0 0 0 1 0 0 0 1 0" />
                <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_679_2751"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_679_2751"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)
export default CreditScoreForm
