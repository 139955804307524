import { gql } from '@apollo/client';

export const VERIFYOTP = gql`mutation VerifyOtp($input: UserVerifyOtpInput) {
    verifyOtp(input: $input) {
     verifyOtpErrors {
        message
      }
      message
      status
      token
       user {
        id
        mobileNumber
        userstatusId
      }
    }
  }`
 


