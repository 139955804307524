
const ClockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path d="M19.9993 33.3334C27.3631 33.3334 33.3327 27.3639 33.3327 20.0001C33.3327 12.6363 27.3631 6.66675 19.9993 6.66675C12.6356 6.66675 6.66602 12.6363 6.66602 20.0001C6.66602 27.3639 12.6356 33.3334 19.9993 33.3334Z" fill="#EC9A3B" />
            <path d="M19.9993 34.3334C27.9154 34.3334 34.3327 27.9162 34.3327 20.0001C34.3327 12.084 27.9154 5.66675 19.9993 5.66675C12.0833 5.66675 5.66602 12.084 5.66602 20.0001C5.66602 27.9162 12.0833 34.3334 19.9993 34.3334Z" stroke="#EC9A3B" stroke-opacity="0.2" strokeWidth="2" />
            <path d="M20 13.3335V20.0002L25 25.0002" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    )
}

export default ClockIcon;