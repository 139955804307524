export const NotExceedBug = () => {
  return (
    <section className="blog-container-width">
      <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>
        How to Ensure You're Not Exceeding Your Budget?
      </p>
      <div className="mt-4">
        <span>
          Weddings are a time of joy and celebration, but they’re also heavy on
          the wallet. When planning a wedding, costs can quickly escalate,
          leaving many couples concerned about exceeding their budget. This is
          where careful planning, mindful spending and a solid wedding budget
          checklist can make a difference. Managing your wedding expenses
          effectively is important so you don’t overspend or start your married
          life in debt.
        </span>
        <br />
        <span>&nbsp;</span>
      </div>
      <span>
        So, here are some tips on how to stay within your budget while planning
        your wedding!
      </span>
      <br />
      <span>&nbsp;</span>
      <div>
        <strong>
          1.<u> Set a Realistic Budget from the Start</u>
        </strong>
        <br />
        <div>
          <span>&nbsp;</span>
        </div>
        <span>
          The first step in ensuring you don’t exceed your budget is setting one
          that's realistic for your financial situation. Start by evaluating how
          much money you have at your disposal, including savings, contributions
          from family and the amount you're willing to take out as a wedding
          loan. Be sure to leave some room for unexpected costs, as there are
          often additional expenses that come up during the planning process.
        </span>
        <br />
        <span>&nbsp;</span>
        <div style={{ marginLeft: "40px" }}>
          <div>
            <span style={{ fontSize: "150%", color: "#000" }}>&#9733;</span>
            <strong className="ml-10">Divide the Budget by Category</strong>
            <p style={{ marginLeft: "30px" }}>
              Allocate your total budget into categories like venue, catering,
              attire, decor, photography, entertainment and miscellaneous
              expenses. Assign amounts to each category and monitor your
              spending to avoid overspending in one area, which can strain your
              budget for others.
            </p>
          </div>
          <span style={{ fontSize: "150%", color: "#000" }}>&#9733;</span>
          <strong className="ml-10">Keep An Eye Out For Hidden Costsy</strong>
          <p style={{ marginLeft: "30px" }}>
            Hidden costs like taxes, service charges and vendor transportation
            can surprise couples. Request a detailed breakdown of all potential
            fees from your vendors to avoid unexpected expenses and stay within
            your budget.
          </p>
        </div>

        <br />
        <span></span>
      </div>
      <div>
        <strong>
          2. <u> Track All Your Expenses</u>
        </strong>
        <br />
        <div>
          <span>&nbsp;</span>
        </div>
        <span>
          Tracking your expenses is crucial for staying on budget. Use a
          spreadsheet, budgeting app, or notebook to record every expense. From
          major costs like the venue and catering to smaller ones like
          invitations and transportation, everything should be written down.
          This helps you see all your spending in one place and identify any
          budget overruns.
        </span>
        <br />
        <span>&nbsp;</span>
        <div style={{ marginLeft: "40px" }}>
          <div>
            <span style={{ fontSize: "150%", color: "#000" }}>&#9733;</span>
            <strong className="ml-10">Keep Receipts and Contracts</strong>
            <p style={{ marginLeft: "30px" }}>
              Keep receipts and copies of all vendor contracts to track what
              you’re paying for and ensure accountability. Organizing these
              documents helps you calculate total expenses and compare them to
              your budget.
            </p>
          </div>
          <span style={{ fontSize: "150%", color: "#000" }}>&#9733;</span>
          <strong className="ml-10">Track Your Loan Payments</strong>
          <p style={{ marginLeft: "30px" }}>
            Track your wedding loan payments and interest carefully. Set
            reminders for due dates to avoid late fees and keep your loan
            manageable within your financial plan.
          </p>
        </div>

        <br />
        <span></span>
      </div>
      <div>
        <strong>
          3. <u> Prioritize Essential Expenses</u>
        </strong>
        <br />
        <div>
          <span>&nbsp;</span>
        </div>
        <span>
          To avoid exceeding your budget, prioritise spending on key aspects of
          your wedding. Identify what’s most important to you and allocate your
          budget accordingly, such as focusing more on the venue or catering if
          those are top priorities.
        </span>
        <br />
        <span>&nbsp;</span>
        <div style={{ marginLeft: "40px" }}>
          <div>
            <span style={{ fontSize: "150%", color: "#000" }}>&#9733;</span>
            <strong className="ml-10">Determine Your Essentials</strong>
            <p style={{ marginLeft: "30px" }}>
              Create a list of essentials and optional items. For instance,
              prioritise the venue and food over luxuries like elaborate
              invitations or high-end transportation. This helps you allocate
              your budget wisely.
            </p>
          </div>
          <span style={{ fontSize: "150%", color: "#000" }}>&#9733;</span>
          <strong className="ml-10">Cut Back on Extras</strong>
          <p style={{ marginLeft: "30px" }}>
            Consider where you can trim expenses without sacrificing the overall
            quality of your wedding. For example, you could opt for digital
            invitations instead of expensive printed ones, or choose a smaller,
            more intimate guest list to cut down on catering and venue costs.
          </p>
        </div>

        <br />
        <span></span>
      </div>
      <div>
        <strong>
          4. <u> Negotiate with Vendors</u>
        </strong>
        <br />
        <div>
          <span>&nbsp;</span>
        </div>
        <span>
          Vendors often offer some flexibility when it comes to pricing,
          especially if you’re booking multiple services with them. Negotiate
          with vendors to get the best deal possible while being respectful and
          professional.
        </span>
        <br />
        <span>&nbsp;</span>
        <div style={{ marginLeft: "40px" }}>
          <div>
            <span style={{ fontSize: "150%", color: "#000" }}>&#9733;</span>
            <strong className="ml-10">Shop Around for the Best Deals</strong>
            <p style={{ marginLeft: "30px" }}>
              Before committing, compare prices and get multiple quotes from
              vendors. This helps you find the best fit for your budget without
              sacrificing quality.
            </p>
          </div>
          <span style={{ fontSize: "150%", color: "#000" }}>&#9733;</span>
          <strong className="ml-10">Consider Off-Peak Dates</strong>
          <p style={{ marginLeft: "30px" }}>
            Booking your wedding on a weekday or off-peak season can save money.
            Venues and vendors often offer discounts for non-peak dates, helping
            you stay within budget.
          </p>
        </div>
        <br />
        <div>
          <strong>
            4. <u> 5. Be Ready for Adjustments</u>
          </strong>
          <br />
          <div>
            <span>&nbsp;</span>
          </div>
          <span>
            Even with planning, things may not always go as expected. Stay
            flexible and adjust as needed to avoid financial stress. If costs
            exceed expectations, revisit your budget and cut back on other areas
            to balance out. Create a contingency fund of 10-15% of your budget
            which will provide flexibility for unexpected expenses, such as
            last-minute changes or extra vendor charges.
          </span>
          <span>&nbsp;</span>
        </div>
        <span>&nbsp;</span>
        <span>&nbsp;</span>

        <div>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </div>
        
        In conclusion, staying within budget is possible with careful planning
        and tracking. Set a realistic budget, monitor expenses, prioritize key
        vendors and negotiate costs. Thoughtful planning ensures a beautiful,
        manageable wedding without financial strain!
      </div>
    </section>
  );
};
