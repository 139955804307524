import AppModal from "./AppModal/AppModal";
import loadingGif from "../assets/loadingGif.gif";

interface modalProps{
    isOpen: boolean,
    lenderName?:string
}


const LoadingModalView = ({isOpen,lenderName}:modalProps) => {
    return (
        <AppModal openModal={isOpen}>
            <div className="text-center">
                <img src={loadingGif} alt="loader" className="loading-icon-gif" />
                <div className="heading fs-24">Just a minute..</div>
                {lenderName === "CASHE" && <div className="mt-1 fs-14">We are redirecting you to our banking partner's app</div>}
            </div>
        </AppModal>
    )
}

export default LoadingModalView;