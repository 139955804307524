import Navbar from "../../components/Navbar/Navbar";
import rightArrowIcon from "../../assets/rightArrow.svg";
// import ticketIcon from "../../assets/ticketIcon.svg";
// import supportContact from "../../assets/supportContact.svg";
import supportEmail from "../../assets/emailIcon.svg";
import { useNavigate } from "react-router-dom";
import leftArrow from "../../assets/leftArrow.svg";



const supportEmails:string[]= ["care@weddingloan.com","help@weddingloan.com"]


const SupportPage = () => {
    const navigate = useNavigate();
    const spanStyles = {
        display:"flex",gap:"0.5em"
    }

    const supportView = () => {
        return (
            <>
           
           {
             supportEmails.map(email=> (

                <div className="profile-menu-card-view" >
                <div className="mt-2">
                        <div className="d-flex jc-sb py-15" >
                            <div style={spanStyles} >
                                <img src={supportEmail} alt="supportEmailIcon" />
                                <a className="ml-15" href={`mailto:${email}`}>{email}</a>
                            </div>
                            <div>
                                {/* <img src={rightArrowIcon} alt="rightArrowIcon" /> */}
                            </div>
                        </div>
                        {/* <div className="d-flex jc-sb py-15">
                            <div style={spanStyles}>
                                <img src={supportContact} alt="supportContactIcon" /> 
                                <span className="ml-15">Contact</span>
                            </div>
                            <div>
                                <img src={rightArrowIcon} alt="rightArrowIcon" />
                            </div>
                        </div> */}
                        {/* <div className="d-flex jc-sb py-15">
                            <div style={spanStyles}>
                                <img src={ticketIcon} alt="ticketIcon" /> 
                                <span className="ml-15">Raise </span>
                            </div>
                            <div>
                                <img src={rightArrowIcon} alt="rightArrowIcon" />
                            </div>
                        </div> */}
                    </div>
                </div>

             ))
           }
               
            </>
        )
    }
    const goToPrev = () => {
        navigate(-1);
    };

    return (
        <>
            <Navbar />
            <div className="d-flex jc-center align-center flex-col mt-4">
                <div>
                    <div className="d-flex">
                    <img src={leftArrow} alt="leftArrow" className="cursor-pointer" onClick={() => goToPrev()} />
                    <span className="ml-2">Support</span>
                    </div>
                    {supportView()}
                </div>
            </div>
        </>
    )
}

export default SupportPage;