import { gql } from '@apollo/client';

export const LOANHISTORY = gql`query LoanHistory {
  loanHistory {
    id
    lenderName
    requestuid
    leadId
    isActive
    userId
    lendereligibilitycriteriaId
    loanappliedstatusId
    updatedAt
    createdAt
    lendereligibilitycriteria {
      id
      lenderName
    }
    loanappliedstatus {
      id
      title
    }
  }
}
`

