export const Stakeholders = () => {
  return (
    <section className="blog-container-width">
      <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>
        <span style={{ color: "#000000" }}>
          <span>Wedding Loan Process:</span>
          <span>&nbsp;</span>
        </span>
        <span>How to Manage Key </span>
        <br />
        <span>Stakeholders?</span>
      </p>
      <div className="mt-4">
        <span>
          When planning to secure a wedding loan, several key stakeholders are
          impacted throughout the process. Properly managing these individuals
          is essential for smooth coordination and maintaining financial
          stability.{" "}
        </span>
        <br />
        <span>&nbsp;</span>
        <div>
          Whether it's the couple, families, vendors, lenders or even guests,
          weddings—especially those funded by a loan—involve many people whose
          expectations need to be aligned. Effective stakeholder management
          helps prevent misunderstandings, ensures expectations are met and
          streamlines decision-making.
        </div>
      </div>
      <span>
        <br />
        To navigate this process efficiently, here's how to manage these key
        stakeholders so that everyone stays on the same page.
      </span>
      <br />
      <div>&nbsp;</div>
      <span className="subheading">1. The Couple</span>
      <div className="mt-2">
        The couple getting married is at the centre of all decision-making. It’s
        essential that they communicate openly and agree on financial
        priorities. Effective management between the couple ensures that
        financial decisions, including loan approvals and repayments, are
        aligned with long-term financial goals. Before applying for a wedding
        loan, the couple should decide on these:
      </div>
      <div className="mt-3">
        <strong>Discuss the Budget:</strong>
        <span className="ml-10">
          Set a realistic wedding budget by factoring in personal savings,
          family contributions and the loan amount. Indian weddings can
          typically cost between ₹10 lakh and ₹50 lakh. This is why couples
          should agree on how much they're comfortable borrowing and spending.
        </span>
      </div>
      <div className="mt-3">
        <strong>Plan the Repayment:</strong>
        <span className="ml-10">
          Plan how the loan will be repaid post-wedding, ensuring both partners
          agree on responsibilities. For example, with interest rates between
          10% and 30%, a ₹5-lakh loan at 12% over five years would result in
          EMIs of around ₹11,000.
        </span>
      </div>
      <div>&nbsp;</div>
      <span className="subheading">2. The Family</span>
      <div className="mt-2">
        In Indian weddings, families play a significant role in the planning and
        financing process. However, managing family expectations regarding the
        wedding budget is essential to avoid over-borrowing. The key is to
        ensure that family involvement doesn’t push the wedding budget beyond
        what the couple can manage post-wedding, especially if they are
        responsible for repaying the loan. Here’s how:
      </div>
      <div className="mt-3">
        <strong>Open Communication:</strong>
        <span className="ml-10">
          Discuss contributions and expectations with both families, especially
          if they prefer larger ceremonies or guest lists. Some families may
          insist on extravagant ceremonies or large guest lists, but these
          expectations need to be managed within the agreed-upon budget. For
          example, the cost per guest for catering can range from ₹1,000 to
          ₹3,000. Keeping this in mind can help control expenses when dealing
          with family input.
        </span>
      </div>
      <div className="mt-3">
        <strong>Clarifying Contributions:</strong>
        <span className="ml-10">
          Families often contribute financially to the wedding. Clear
          communication about how much each side is contributing can avoid
          confusion. Family contributions may reduce the overall loan amount
          needed, easing the couple’s debt burden.
        </span>
      </div>
      <div>&nbsp;</div>
      <span className="subheading">3. The Vendors</span>
      <div className="mt-2">
      Vendors, from caterers to photographers, are crucial stakeholders in the wedding planning process. Many of these vendors will require deposits and will have strict payment schedules. Keep these in mind when dealing with wedding vendors:
      </div>
      <div className="mt-3">
        <strong>Negotiating Contracts:</strong>
        <span className="ml-10">
        Ensure vendor payment terms match your wedding loan disbursement. Many require a 50% deposit upfront, with the balance due before or right after the wedding. Banks like IDFC, HDFC and SBI offer quick approval, usually within 24 to 72 hours, helping you meet payment deadlines on time.
        </span>
      </div>
      <div className="mt-3">
        <strong>Vendor Relationships:</strong>
        <span className="ml-10">
        Good vendor relationships are crucial for a successful wedding. By clearly communicating the budget constraints and payment schedules, couples can avoid last-minute financial stress. Some vendors might offer flexible payment plans, which can be useful when managing a wedding loan.
        </span>
      </div>
      <div>&nbsp;</div>
      <span className="subheading">4. The Lender</span>
      <div className="mt-2">
      When taking out a wedding loan, the lender becomes one of the most important stakeholders in the financial process.  It’s important to manage your relationship with the lender to ensure a smooth loan-acquiring experience.
      </div>
      <div className="mt-3">
        <strong>Choosing the Right Lender:</strong>
        <span className="ml-10">
            Compare different loan options from banks and Non-Banking Financial Companies (NBFCs). Banks like IDFC, Axis Bank or NBFCs like TATA Capital, L&T Finance, Bajaj Finserv offer personal loans for weddings with quick disbursement times and competitive interest rates. Also, look for lenders that allow for flexible repayment options.
        </span>
      </div>
      <div className="mt-3">
        <strong>Managing Repayment:</strong>
        <span className="ml-10">
            Keep track of your monthly EMIs and make sure they fit within your post-wedding financial situation. Late payments can lead to penalties and increased interest rates. Automated payment systems can help ensure you never miss a due date. 
        </span>
      </div>
      <div>&nbsp;</div>

      <span className="subheading">5. The Guests</span>
      <div className="mt-2">
      While guests aren’t directly involved in the loan or planning process, managing their expectations is crucial to sticking to your budget. The size of your guest list will directly impact major expenses like catering and venue size. Reducing the number of guests is one of the most effective ways to manage costs. The guest list determines catering costs, which can account for 25-35% of the total budget. Keeping the guest list within a manageable size ensures you don’t need to borrow more than necessary.
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>

        <div>To sum up, effectively handling key stakeholders in the wedding loan process involves clear communication, setting expectations, and careful planning. By coordinating with family, vendors, and lenders, couples can stay on budget and prevent overspending. With proper planning, a wedding loan can make the day special without creating long-term financial pressure.</div>
    </section>
  );
};
