import { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FormControl, InputLabel, useMediaQuery } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { components } from 'react-select';

const AsyncSelect = ({ onChange, value, placeholder, isMulti=false, loadOptions }: any) => {
  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); 

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: '56px',
      padding: '8px 5px', 
      fontSize: '1rem', 
      borderRadius: '4px',
      borderColor: focused ? '#020164' : '#ced4da',
      boxShadow: focused ? '0 0 0 2px rgba(2, 1, 100, 0.2)' : 'none',
      '&:hover': {
        borderColor: '#020164',
      },
      transition: 'box-shadow 0.3s, border-color 0.3s',
      border: focused ? '2px solid #020164' : '1px solid #ced4da',
      cursor:'pointer'
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      borderRadius: '4px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      marginTop: 0,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgba(2, 1, 100, 0.08)' : '#fff',
      color: '#000',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: '1rem',
    }),
    placeholder: (base: any, state: any) => ({
      ...base,
      display: state.isFocused && 'none',
    }),
    valueContainerCSS:(provided:any) => {
      return {
        ...provided,
        padding:0
      }
    },
    indicatorsContainerCSS:(provided:any)=>{
      return {
        ...provided,
      }
    }
  };



  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel
        shrink={focused || value?.label || inputValue ? true : false}
        htmlFor="async-select"
        sx={{
          color: focused ? '#020164' : '#6c757d',
          transform: focused || value?.label || inputValue ? 'translate(12px, -0.5rem) scale(0.75)' : 'translate(14px, 18px) scale(1)',
          transition: 'transform 0.3s ease, color 0.3s ease',
          background: "#ffffff",
          transformOrigin: 'top left',
          fontWeight:400
        }}
      >
        {placeholder}
      </InputLabel>
      <AsyncPaginate
        id="async-select"
        loadOptions={loadOptions}
        onChange={(selectedOption) => {
          onChange(selectedOption);
          setInputValue(selectedOption?.label || '');
        }}
        value={value}
        isMulti={isMulti}
        debounceTimeout={300}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props} className='pointer-events-none'>
              {menuOpen ? <ArrowDropUpIcon htmlColor='#6c757d' /> : <ArrowDropDownIcon htmlColor='#6c757d' />}
            </components.DropdownIndicator>
          ),
        }}
        styles={customStyles}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMenuOpen={() => setMenuOpen(true)} 
        onMenuClose={() => setMenuOpen(false)} 
        placeholder=""
        isClearable={true} 
      />
    </FormControl>
  );
};

export default AsyncSelect;
