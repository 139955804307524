import { gql } from '@apollo/client';

export const ApplyTataCapital = gql`
	mutation ApplyTataCapitalLoan($input: ApplyTataCapitalLoanInput) {
		applyTataCapitalLoan(input: $input) {
			applyTataCapitalLoanErrors {
				message
			}
			message
			status
		}
	}
`;
