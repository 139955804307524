import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, Select, TextField, MenuItem, Snackbar } from "@mui/material";
import AppButton from "../../components/AppButton";
import Navbar from "../../components/Navbar/Navbar";
import leftArrow from "../../assets/leftArrow.svg";
import { useCallback, useEffect, useState } from "react";
import { checkBoxInputStyle } from "../../pages/DefaultCheckboxInputStyle";
import { CITIESLIST, RESIDENTTYPELIST, STATESLIST } from "../../query/queries";
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { PROFILEDETAILSQUERY } from "../../query/ProfileDetails/profileDetailsQuery";
import {UPDATELOCATIONDETAILS } from "../../query/UserDetails/userDetailsQuery";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent } from '@mui/material/Select';

let initialAddressState = {
    residenceType: "",
    address: "",
    addressType: "1",
    city: "",
    pincode: "",
    state: "",
    id: "",
    cities:[]
};

const addresssTypeList = [
    { name: "Current address", type: "1" },
    { name: "Permanent address", type: "2" }
];


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const LocationDetailsView = () => {
    const [addressType, setAddressType] = useState("1"); // Address type toggle
    const [currentAddress, setCurrentAddress] = useState(initialAddressState); // State for current address
    const [permanentAddress, setPermanentAddress] = useState(initialAddressState); // State for permanent address
    const { data: residentTypeList } = useQuery(RESIDENTTYPELIST, { fetchPolicy: "network-only" });
    const { data: profileDetailsResponse,refetch } = useQuery(PROFILEDETAILSQUERY, { 
        fetchPolicy: "network-only",
        onCompleted: (data) => updateLocalState(data)
    });
    const { data: stateList } = useQuery(STATESLIST);
    const [saveLocationDetails] = useMutation(UPDATELOCATIONDETAILS);
    const [isPermanentAdressSame, setIsPermanentAdressSame] = useState(false); // Checkbox state
    const [toast, setToast] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const [loading,setLoading] = useState(false)

    const navigate = useNavigate();


    const [citiesList,setCitiesList] = useState([])


    const [fetchCities] = useLazyQuery(CITIESLIST, {
        fetchPolicy: 'network-only',
        onCompleted: (response: any) => {
           if(response?.city){

            if (addressType === "1") {
                setCurrentAddress({ ...currentAddress, cities: response?.city });
            } else {
                setPermanentAddress({ ...permanentAddress, cities: response?.city });
            }

           }
        }
    });


    const  callAddress = useCallback(()=> {

        if (profileDetailsResponse?.userDetails?.address.length > 0) {
            const currentAddressData = profileDetailsResponse.userDetails.address.find((item: any) => item.addresstypeId === "1");
            const permanentAddressData = profileDetailsResponse.userDetails.address.find((item: any) => item.addresstypeId === "2");
    
            if (addressType === "1") {
                setCurrentAddress({
                    ...initialAddressState,
                    residenceType: currentAddressData.residenceType?.id || "",
                    address: currentAddressData.address || "",
                    addressType: currentAddressData.addresstypeId || "1",
                    city: currentAddressData.city || "",
                    pincode: currentAddressData.pincode || "",
                    state: currentAddressData.state || "",
                    id: currentAddressData.id
                });
    
                fetchCities({variables:{stateId:+currentAddressData.state}})
            }else{
                setPermanentAddress({
                    ...initialAddressState,
                    residenceType: permanentAddressData.residenceType?.id || "",
                    address: permanentAddressData.address || "",
                    addressType: permanentAddressData.addresstypeId || "2",
                    city: permanentAddressData.city || "",
                    pincode: permanentAddressData.pincode || "",
                    state: permanentAddressData.state || "",
                    id: permanentAddressData.id
                });
    
                fetchCities({variables:{stateId:+permanentAddressData.state}})
            }
    
        }
    
       },[profileDetailsResponse,fetchCities,addressType])
    

    useEffect(() => {

        callAddress()
      
    }, [callAddress]);



 



    useEffect(() => {

        if (profileDetailsResponse?.userDetails?.address.length > 0) {

            setIsPermanentAdressSame(profileDetailsResponse?.userDetails?.address.every((item: any) => item.isSameAsPermanent));
        }

    }, [profileDetailsResponse])

    const handleInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if(name === "address"){
            setCurrentAddress({ ...currentAddress, [name]: value?.trimStart() });
            setPermanentAddress({ ...permanentAddress, [name]: value?.trimStart() });
            return ;
        }
        if (addressType === "1") {
            setCurrentAddress({ ...currentAddress, [name]: value });
        } else {
            setPermanentAddress({ ...permanentAddress, [name]: value });
        }
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;

        if (name === "state"){


            if (addressType === "1") {
                setCurrentAddress({ ...currentAddress, city: '',[name]:value });
            } else {
                setPermanentAddress({ ...permanentAddress, city: '',[name]:value });
            }

            fetchCities({
                variables:{
                    stateId:+value
                }
            })

            return 
        }

        if (addressType === "1") {
            setCurrentAddress({ ...currentAddress, [name]: value });
        } else {
            setPermanentAddress({ ...permanentAddress, [name]: value });
        }
    };

    const submitFn = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setLoading(true)
        saveLocationDetail().then((response)=>{

            setToast((prev) => {
                return {
                    ...prev,
                    open: true
                }
            })
            setLoading(false)


        });
    };

    const saveLocationDetail = async () => {
        const locationDetailsToSave = addressType === "1" ? currentAddress : permanentAddress;
        // console.log(locationDetailsToSave)
        await saveLocationDetails({
            variables: {
                input: { ...locationDetailsToSave,cities:undefined, isSameAsPermanent: isPermanentAdressSame }
            }
        });

        await refetch()

    };


    const updateLocalState = (updatedData:any) => {
        const { addresstypeId, ...rest } = updatedData;
        if (addresstypeId === "1") {
            setCurrentAddress({
                ...currentAddress,
                ...rest,
                addressType: addresstypeId
            });
        } else {
            setPermanentAddress({
                ...permanentAddress,
                ...rest,
                addressType: addresstypeId
            });
        }
        if (addresstypeId === "1" && updatedData.isSameAsPermanent) {
            setPermanentAddress({
                ...permanentAddress,
                ...rest,
                addressType: "2"
            });
        }
    };

    const togglePermanentAddressCheckBox = () => {
        setIsPermanentAdressSame(!isPermanentAdressSame);
    };

    const handleAddressTypeToggle = (type: string) => {
        setAddressType(type);
        callAddress()
    };

    const makePermanentAddressDisable = () => isPermanentAdressSame && addressType === "2";

    


    const isDisabledButton = () => {
        const isCurrentAddressValid = 
            currentAddress.residenceType !== "" && 
            currentAddress.address !== "" && 
            currentAddress.state !== "" &&
            currentAddress.city !== "" && 
            currentAddress.pincode !== "";
    
        // If addresses are same, only validate current address
        if (isPermanentAdressSame) {
            return !isCurrentAddressValid || loading;
        }
    
        // If addresses are different, validate both
        const isPermanentAddressValid = 
            permanentAddress.residenceType !== "" && 
            permanentAddress.address !== "" && 
            permanentAddress.state !== "" &&
            permanentAddress.city !== "" && 
            permanentAddress.pincode !== "";
    
        return !isCurrentAddressValid || !isPermanentAddressValid || loading;
    }


    const locationDetailsView = () => {
        const locationDetails = addressType === "1" ? currentAddress : permanentAddress;


        return (
            <>
                <div className="profile-menu-card-view">
                    <div className="d-flex gap-10">
                        {addresssTypeList.map((item, index) => (
                            <div key={index}>
                                <AppButton
                                    text={item.name[0].toUpperCase() + item.name.slice(1).toLowerCase()}
                                    className={addressType === item.type ? "btn-type active-btn" : "btn-type"}
                                    onClick={() => handleAddressTypeToggle(item.type)}
                                />
                            </div>
                        ))}
                    </div>

                    {/* Residence type */}
                    <FormControl fullWidth className="mt-25">
                        <InputLabel>Residence type</InputLabel>
                        <Select
                            required
                            name="residenceType"
                            label="Residence type"
                            onChange={handleSelectChange}
                            value={locationDetails.residenceType}
                            disabled={makePermanentAddressDisable()}
                        >
                            {(residentTypeList?.residenceType || []).map((residenceType: any) => (
                                <MenuItem key={residenceType.id} value={residenceType.id}>{residenceType.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Address */}
                    <FormControl fullWidth className="mt-25">
                        <TextField
                            // inputProps={{ readOnly: true }}
                            label="Address"
                            name="address"
                            multiline
                            rows={2}
                            value={locationDetails.address}
                            onChange={handleInfoChange}
                            disabled={makePermanentAddressDisable()}
                        />
                    </FormControl>

                    {/* State */}
                    <FormControl fullWidth className="mt-25">
                        <InputLabel>State</InputLabel>
                        <Select
                            required
                            name="state"
                            label="State"
                            value={locationDetails.state}
                            onChange={handleSelectChange}
                            MenuProps={MenuProps}
                            disabled={makePermanentAddressDisable()

                            }
                        >
                            {(stateList?.state || []).map((state: any) => (
                                <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>



                    {/* City */}
                    <FormControl fullWidth className="mt-25">
                        <InputLabel>City / Town</InputLabel>
                        <Select
                            required
                            name="city"
                            label="City / Town"
                            value={locationDetails.city}
                            onChange={handleSelectChange}
                            MenuProps={MenuProps}
                            disabled={makePermanentAddressDisable() || !Boolean(locationDetails.state)}
                        >
                            {(locationDetails.cities || []).map((city: any) => (
                                <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                    {/* Pincode */}
                    <FormControl fullWidth className="mt-25">
                        <TextField
                            // inputProps={{ readOnly: true }}
                            label="Pincode"
                            name="pincode"
                            value={locationDetails.pincode}
                            onChange={handleInfoChange}
                            disabled={makePermanentAddressDisable()}
                        />
                    </FormControl>

                    {/* Checkbox for permanent address */}
                    <FormGroup className="mt-2">
                        <FormControlLabel
                            control={<Checkbox checked={isPermanentAdressSame} sx={checkBoxInputStyle} disableRipple onChange={togglePermanentAddressCheckBox} />}
                            label="Current address is same as permanent address"
                        />
                    </FormGroup>

                    <AppButton  isDisabled={isDisabledButton()}  type="submit" text="Save Changes" className="w-100 app-btn mt-3" />
                </div>
            </>
        );
    };

    const goToPrev = () => {
        navigate(-1);
    };

    return (
        <>
            <Navbar />
            <div className="d-flex jc-center align-center flex-col mt-4">
                <div>
                    <div className="d-flex">
                        <img src={leftArrow} alt="leftArrow" className="cursor-pointer" onClick={() => goToPrev()} />
                        <span className="ml-2">Location details</span>
                    </div>
                    <form noValidate onSubmit={submitFn}>
                        {locationDetailsView()}
                    </form>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={toast.open}
                autoHideDuration={3000}
                onClose={() => setToast((prev) => {
                    return { ...prev, open: false }
                })}
                message="Location Details Updated SuccessFully"
                key={toast.vertical + toast.horizontal}
            />
        </>
    );
};

export default LocationDetailsView;
