import Navbar from "../../components/Navbar/Navbar";
import "./profilepage.scss";
import { FormControl, TextField } from "@mui/material";
import leftArrow from "../../assets/leftArrow.svg";
import { PROFILEDETAILSQUERY } from "../../query/ProfileDetails/profileDetailsQuery";
import { useQuery } from '@apollo/client';
import AppButton from "../../components/AppButton";
import { localTimeZoneFormat } from "../../utils";
import { useNavigate } from "react-router-dom";



const BasicDetailsView = () => {
    const { data: profileDetailsResponse } = useQuery(PROFILEDETAILSQUERY,{fetchPolicy: "network-only"});
    const navigate = useNavigate();




    const basicDetailsView = () => {
        const formattedDate = localTimeZoneFormat(+profileDetailsResponse?.userDetails?.dateOfBirth);
        const name = profileDetailsResponse?.userDetails?.firstName +" " + profileDetailsResponse?.userDetails?.middleName +
        " " + profileDetailsResponse?.userDetails?.lastName;
        return (
            <>
                <div className="profile-menu-card-view">
                    <FormControl fullWidth>
                        <TextField
                            inputProps={{ readOnly: true }}
                            label="Mobile Number" variant="outlined"
                            name='mobileNumber'
                            InputLabelProps={{ shrink: profileDetailsResponse ? true : false }}
                            value={profileDetailsResponse?.userDetails?.mobileNumber}
                        />
                    </FormControl>
                    <FormControl fullWidth className="mt-25">
                        <TextField
                            inputProps={{ readOnly: true }}
                            label="PAN name" variant="outlined"
                            name='name'
                            InputLabelProps={{ shrink: profileDetailsResponse ? true : false }}
                            value={name}
                        />
                    </FormControl>
                    <FormControl fullWidth className="mt-25">
                        <TextField
                            inputProps={{ readOnly: true }}
                            label="PAN card number" variant="outlined"
                            name='panCardNumber'
                            InputLabelProps={{ shrink: profileDetailsResponse ? true : false }}
                            value={profileDetailsResponse?.userDetails?.panCardNumber}
                        />
                    </FormControl>
                    <FormControl fullWidth className="mt-25">
                        <TextField
                            inputProps={{ readOnly: true }}
                            label="Date of birth" variant="outlined"
                            name='dob'
                            InputLabelProps={{ shrink: profileDetailsResponse ? true : false }}
                            value={formattedDate}
                        />
                    </FormControl>
                    <div className="mt-4">
                        <AppButton text="Details cannot be edited!" className="w-100 disabled-btn" />
                    </div>
                </div>
            </>
        )
    }

    const goToPrev = () =>{
        navigate(-1);
    }

    return (
        <>
            <Navbar />
            <div className="d-flex jc-center align-center flex-col mt-4">
                <div>
                    <div className="d-flex">
                        <img src={leftArrow} alt="leftArrow" className="cursor-pointer" onClick={() => goToPrev()}/>
                        <span className="ml-2">Basic details</span>
                    </div>
                    {basicDetailsView()}
                </div>
            </div>
        </>
    )
}

export default BasicDetailsView;