
import {Box, Modal} from '@mui/material';

interface Modalprops {
    children: JSX.Element;
    openModal: boolean;
    handleClose?: () => void
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius:7,
    outline:"none",
    width:400
  };
  
const AppModal = ({children,openModal,handleClose}:Modalprops) => {

    return (
        <Modal open={openModal}>
            <Box sx={style}>
                {children}
            </Box>
        </Modal>
    )
}

export default AppModal;