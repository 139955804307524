interface userDetailsProps{
    id:string
}



export function formatRelativeTime(dateString: string): string {
    const now = new Date();
    const past = new Date(dateString);
    const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);
    if (diffInSeconds < 30) return 'now';
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''} ago`;
    
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 30) return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
    
    const diffInYears = Math.floor(diffInMonths / 12);
    return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;
}



export function validateMobileNumber(mobileNumber:string):boolean{
    if(!mobileNumber) return false
    const pattern = /^[6-9][0-9]{9}$/;
    return pattern.test(mobileNumber)
}

export const validateName = (name: string): boolean => {
    if(!name) return false
    let pattern = /^[a-zA-Z. ]{2,50}$/;
    // const pattern = /^[a-zA-Z\s]+$/;
    return pattern.test(name)
}

export const validateDob = (dob: string): boolean => {
    if (!dob) return false
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    // Adjust age if the current month is before the birth month or the same month but before the birth day
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    if(age > 17 && age < 71){
        return true
    }
    return false;
}

export const validateEmail = (email:string) => {
    var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/;
    return pattern.test(email);
};
  
export function validatePAN(panNumber:string):boolean{
   var pattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
   return pattern.test(panNumber);
}

export const setToken = (token:string):void =>{
    sessionStorage.setItem("AuthToken",token);
}

export const getToken  = () =>{
    return sessionStorage.getItem("AuthToken");
}

export const setUserDetails = (userDetails:userDetailsProps) =>{
    localStorage.setItem("userDetails",JSON.stringify(userDetails));
}

export const getUserDetails = () =>{
    return JSON.parse(localStorage.getItem("userDetails")!);
}


export const  formatMonthYear= (date:Date) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    
    return `${month} ${year}`;
  }



export const formatDate = (date:Date)  => {
    const yyyy = date.getFullYear();
    let mm: any = date.getMonth() + 1;
    let dd: any = date.getDate();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    let formattedDate = yyyy + '-' + mm + '-' + dd;
    return { defaultDateFormat: dd + '-' + mm + '-' + yyyy, bedate:formattedDate };
}

export function validateLoanAmount(expectedLoanAmount:any,maxAmount:number):boolean{
    if(!expectedLoanAmount) return false
    const pattern = /^\d{5,9}$/;

    if(pattern.test(expectedLoanAmount) && +expectedLoanAmount >=10000 && +expectedLoanAmount <= maxAmount){
        console.log("validateLoanAmount",)
        return true
    }
    return false
}

export function validatePinCode(pin:string){
    let pattern = /^[1-9][0-9]{5}$/;
    return pattern.test(pin);
}

export function validateAddress(str:string){
    return /^[a-zA-Z0-9\s](.*[a-zA-Z0-9\s])?$/.test(str)
}

export const convertNumberToWords = (num:any) => {
    var ones = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ", "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];
    var tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    if ((num = num.toString()).length > 9) return "Overflow: Maximum 9 digits supported";
    let n:any = ("000000000" + num).slice(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str += n[1] != 0 ? (ones[Number(n[1])] || tens[n[1][0]] + " " + ones[n[1][1]]) + "Crore " : "";
    str += n[2] != 0 ? (ones[Number(n[2])] || tens[n[2][0]] + " " + ones[n[2][1]]) + "Lakh " : "";
    str += n[3] != 0 ? (ones[Number(n[3])] || tens[n[3][0]] + " " + ones[n[3][1]]) + "Thousand " : "";
    str += n[4] != 0 ? (ones[Number(n[4])] || tens[n[4][0]] + " " + ones[n[4][1]]) + "Hundred " : "";
    str += n[5] != 0 ? (str != "" ? "and " : "") + (ones[Number(n[5])] || tens[n[5][0]] + " " + ones[n[5][1]]) : "";
    return str;
}

export const logOut = () =>{
    localStorage.clear();
    sessionStorage.clear();
}

export const localTimeZoneFormat  = (timestamp:any) =>{
    const date_not_formatted = new Date(+timestamp);
    var formatted_string = "";
    if (date_not_formatted.getMonth() < 9) {
        formatted_string += "0";
    }
    formatted_string += (date_not_formatted.getMonth() + 1);
    formatted_string += "-";

    if (date_not_formatted.getDate() < 10) {
        formatted_string += "0";
    }
    formatted_string += date_not_formatted.getDate() + "-";
    formatted_string += date_not_formatted.getFullYear();
    return formatted_string;
}