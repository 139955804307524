const MobileLogo = () => (

<svg xmlns="http://www.w3.org/2000/svg" width="160" height="30" viewBox="0 0 160 30" fill="none">
  <path d="M95.5052 23.6816C96.0166 23.6816 96.4308 23.8388 96.7479 24.153C97.065 24.4621 97.2235 24.9103 97.2235 25.4976V27.9936H96.5331V25.598C96.5331 25.1756 96.4283 24.8536 96.2186 24.6321C96.0089 24.4054 95.7225 24.2921 95.3594 24.2921C94.9912 24.2921 94.6972 24.408 94.4773 24.6398C94.2625 24.8717 94.1551 25.2091 94.1551 25.6521V27.9936H93.457V23.7589H94.1551V24.3617C94.2932 24.1453 94.4798 23.9779 94.7151 23.8594C94.9554 23.7409 95.2188 23.6816 95.5052 23.6816Z" fill="#020164"/>
  <path d="M87.168 25.8589C87.168 25.4262 87.2549 25.0475 87.4288 24.723C87.6027 24.3933 87.8405 24.1383 88.1422 23.958C88.449 23.7777 88.7891 23.6875 89.1624 23.6875C89.5306 23.6875 89.8503 23.7674 90.1213 23.9271C90.3923 24.0868 90.5944 24.2877 90.7273 24.5298V23.757H91.433V27.9917H90.7273V27.2035C90.5892 27.4508 90.3821 27.6568 90.106 27.8217C89.8349 27.9814 89.5179 28.0612 89.1548 28.0612C88.7814 28.0612 88.4439 27.9685 88.1422 27.783C87.8405 27.5976 87.6027 27.3374 87.4288 27.0026C87.2549 26.6677 87.168 26.2865 87.168 25.8589ZM90.7273 25.8666C90.7273 25.5472 90.6634 25.269 90.5355 25.0321C90.4077 24.7951 90.2338 24.6148 90.0139 24.4912C89.7991 24.3624 89.5613 24.298 89.3005 24.298C89.0397 24.298 88.8019 24.3598 88.5871 24.4834C88.3723 24.6071 88.201 24.7874 88.0731 25.0243C87.9453 25.2613 87.8814 25.5395 87.8814 25.8589C87.8814 26.1835 87.9453 26.4668 88.0731 26.7089C88.201 26.9459 88.3723 27.1288 88.5871 27.2576C88.8019 27.3812 89.0397 27.443 89.3005 27.443C89.5613 27.443 89.7991 27.3812 90.0139 27.2576C90.2338 27.1288 90.4077 26.9459 90.5355 26.7089C90.6634 26.4668 90.7273 26.186 90.7273 25.8666Z" fill="#020164"/>
  <path d="M83.5161 28.0612C83.1223 28.0612 82.7643 27.9711 82.4421 27.7908C82.1251 27.6105 81.8745 27.3555 81.6904 27.0258C81.5114 26.6909 81.4219 26.3045 81.4219 25.8666C81.4219 25.4339 81.5139 25.0527 81.698 24.723C81.8872 24.3881 82.143 24.1331 82.4651 23.958C82.7873 23.7777 83.1478 23.6875 83.5467 23.6875C83.9456 23.6875 84.3062 23.7777 84.6284 23.958C84.9505 24.1331 85.2037 24.3855 85.3878 24.7152C85.577 25.045 85.6716 25.4287 85.6716 25.8666C85.6716 26.3045 85.5744 26.6909 85.3801 27.0258C85.1909 27.3555 84.9326 27.6105 84.6053 27.7908C84.278 27.9711 83.915 28.0612 83.5161 28.0612ZM83.5161 27.443C83.7666 27.443 84.0019 27.3838 84.2218 27.2653C84.4417 27.1468 84.6181 26.9691 84.7511 26.7321C84.8892 26.4951 84.9582 26.2066 84.9582 25.8666C84.9582 25.5266 84.8917 25.2381 84.7588 25.0012C84.6258 24.7642 84.4519 24.589 84.2371 24.4757C84.0223 24.3572 83.7897 24.298 83.5391 24.298C83.2834 24.298 83.0481 24.3572 82.8333 24.4757C82.6237 24.589 82.4549 24.7642 82.3271 25.0012C82.1992 25.2381 82.1353 25.5266 82.1353 25.8666C82.1353 26.2118 82.1966 26.5029 82.3194 26.7398C82.4472 26.9768 82.616 27.1545 82.8257 27.273C83.0353 27.3864 83.2655 27.443 83.5161 27.443Z" fill="#020164"/>
  <path d="M79.6441 22.2754V27.9937H78.9453V22.2754H79.6441Z" fill="#020164"/>
  <path d="M69.7695 25.8589C69.7695 25.4262 69.8565 25.0475 70.0303 24.723C70.2042 24.3933 70.442 24.1383 70.7437 23.958C71.0506 23.7777 71.3907 23.6875 71.764 23.6875C72.1322 23.6875 72.4518 23.7674 72.7229 23.9271C72.9939 24.0868 73.1959 24.2877 73.3289 24.5298V23.757H74.0346V27.9917H73.3289V27.2035C73.1908 27.4508 72.9837 27.6568 72.7075 27.8217C72.4365 27.9814 72.1194 28.0612 71.7563 28.0612C71.383 28.0612 71.0455 27.9685 70.7437 27.783C70.442 27.5976 70.2042 27.3374 70.0303 27.0026C69.8565 26.6677 69.7695 26.2865 69.7695 25.8589ZM73.3289 25.8666C73.3289 25.5472 73.265 25.269 73.1371 25.0321C73.0093 24.7951 72.8354 24.6148 72.6155 24.4912C72.4007 24.3624 72.1629 24.298 71.9021 24.298C71.6413 24.298 71.4035 24.3598 71.1887 24.4834C70.9739 24.6071 70.8026 24.7874 70.6747 25.0243C70.5469 25.2613 70.4829 25.5395 70.4829 25.8589C70.4829 26.1835 70.5469 26.4668 70.6747 26.7089C70.8026 26.9459 70.9739 27.1288 71.1887 27.2576C71.4035 27.3812 71.6413 27.443 71.9021 27.443C72.1629 27.443 72.4007 27.3812 72.6155 27.2576C72.8354 27.1288 73.0093 26.9459 73.1371 26.7089C73.265 26.4668 73.3289 26.186 73.3289 25.8666Z" fill="#020164"/>
  <path d="M64.356 24.3354V26.8314C64.356 27.0374 64.3995 27.1842 64.4864 27.2718C64.5733 27.3542 64.7242 27.3955 64.939 27.3955H65.4529V27.9905H64.8239C64.4352 27.9905 64.1438 27.9003 63.9494 27.72C63.7551 27.5397 63.6579 27.2435 63.6579 26.8314V24.3354H63.1133V23.7558H63.6579V22.6895H64.356V23.7558H65.4529V24.3354H64.356Z" fill="#020164"/>
  <path d="M60.1402 28.0612C59.818 28.0612 59.5291 28.0071 59.2734 27.899C59.0177 27.7856 58.8157 27.6311 58.6673 27.4353C58.519 27.2344 58.4372 27.0051 58.4219 26.7476H59.143C59.1634 26.9588 59.2606 27.1314 59.4344 27.2653C59.6134 27.3992 59.8461 27.4662 60.1325 27.4662C60.3984 27.4662 60.6081 27.407 60.7615 27.2885C60.9149 27.17 60.9917 27.0206 60.9917 26.8403C60.9917 26.6548 60.9098 26.5183 60.7462 26.4307C60.5825 26.338 60.3294 26.2479 59.9868 26.1603C59.6748 26.0779 59.4191 25.9954 59.2197 25.913C59.0253 25.8254 58.8566 25.6992 58.7134 25.5344C58.5753 25.3644 58.5063 25.1428 58.5063 24.8698C58.5063 24.6534 58.5702 24.4551 58.698 24.2748C58.8259 24.0945 59.0074 23.9528 59.2427 23.8498C59.4779 23.7416 59.7464 23.6875 60.0481 23.6875C60.5135 23.6875 60.8894 23.806 61.1758 24.043C61.4621 24.2799 61.6156 24.6045 61.636 25.0166H60.938C60.9226 24.7951 60.8331 24.6174 60.6695 24.4834C60.5109 24.3495 60.2962 24.2825 60.0251 24.2825C59.7745 24.2825 59.5751 24.3366 59.4268 24.4448C59.2785 24.553 59.2043 24.6946 59.2043 24.8698C59.2043 25.0089 59.2478 25.1248 59.3347 25.2175C59.4268 25.3051 59.5393 25.3772 59.6722 25.4339C59.8103 25.4854 59.9995 25.5447 60.2399 25.6116C60.5416 25.6941 60.7871 25.7765 60.9763 25.8589C61.1655 25.9362 61.3266 26.0547 61.4596 26.2144C61.5977 26.3741 61.6693 26.5827 61.6744 26.8403C61.6744 27.0721 61.6105 27.2808 61.4826 27.4662C61.3548 27.6517 61.1732 27.7985 60.938 27.9067C60.7078 28.0097 60.4419 28.0612 60.1402 28.0612Z" fill="#020164"/>
  <path d="M56.6297 23.7578V27.9924H55.9317V27.3665C55.7987 27.5829 55.6121 27.7529 55.3717 27.8765C55.1365 27.995 54.8756 28.0543 54.5893 28.0543C54.262 28.0543 53.9679 27.9873 53.7071 27.8534C53.4463 27.7143 53.2392 27.5082 53.0857 27.2352C52.9374 26.9621 52.8633 26.6298 52.8633 26.2383V23.7578H53.5537V26.1456C53.5537 26.5629 53.6585 26.8848 53.8682 27.1115C54.0779 27.333 54.3642 27.4438 54.7273 27.4438C55.1007 27.4438 55.3947 27.3279 55.6095 27.0961C55.8243 26.8642 55.9317 26.5268 55.9317 26.0838V23.7578H56.6297Z" fill="#020164"/>
  <path d="M50.533 23.0695C50.4001 23.0695 50.2876 23.0232 50.1955 22.9304C50.1086 22.8377 50.0651 22.7244 50.0651 22.5904C50.0651 22.4565 50.1086 22.3432 50.1955 22.2504C50.2876 22.1577 50.4001 22.1113 50.533 22.1113C50.666 22.1113 50.776 22.1577 50.8629 22.2504C50.9549 22.3432 51.001 22.4565 51.001 22.5904C51.001 22.7244 50.9549 22.8377 50.8629 22.9304C50.776 23.0232 50.666 23.0695 50.533 23.0695ZM50.8782 28.9115C50.8782 29.2875 50.7836 29.5632 50.5944 29.7383C50.4052 29.9135 50.129 30.001 49.7659 30.001H49.3594V29.406H49.6509C49.8452 29.406 49.9807 29.3674 50.0574 29.2901C50.1393 29.2128 50.1802 29.0815 50.1802 28.896V23.7573H50.8782V28.9115Z" fill="#020164"/>
  <path d="M43.5638 23.6816C44.0752 23.6816 44.4894 23.8388 44.8065 24.153C45.1236 24.4621 45.2821 24.9103 45.2821 25.4976V27.9936H44.5917V25.598C44.5917 25.1756 44.4869 24.8536 44.2772 24.6321C44.0675 24.4054 43.7811 24.2921 43.418 24.2921C43.0498 24.2921 42.7558 24.408 42.5359 24.6398C42.3211 24.8717 42.2137 25.2091 42.2137 25.6521V27.9936H41.5156V23.7589H42.2137V24.3617C42.3518 24.1453 42.5384 23.9779 42.7737 23.8594C43.014 23.7409 43.2774 23.6816 43.5638 23.6816Z" fill="#020164"/>
  <path d="M35.2305 25.8589C35.2305 25.4262 35.3174 25.0475 35.4913 24.723C35.6652 24.3933 35.903 24.1383 36.2047 23.958C36.5115 23.7777 36.8516 23.6875 37.2249 23.6875C37.5931 23.6875 37.9128 23.7674 38.1838 23.9271C38.4548 24.0868 38.6569 24.2877 38.7898 24.5298V23.757H39.4956V27.9917H38.7898V27.2035C38.6517 27.4508 38.4446 27.6568 38.1685 27.8217C37.8974 27.9814 37.5804 28.0612 37.2173 28.0612C36.8439 28.0612 36.5064 27.9685 36.2047 27.783C35.903 27.5976 35.6652 27.3374 35.4913 27.0026C35.3174 26.6677 35.2305 26.2865 35.2305 25.8589ZM38.7898 25.8666C38.7898 25.5472 38.7259 25.269 38.598 25.0321C38.4702 24.7951 38.2963 24.6148 38.0764 24.4912C37.8616 24.3624 37.6238 24.298 37.363 24.298C37.1022 24.298 36.8644 24.3598 36.6496 24.4834C36.4348 24.6071 36.2635 24.7874 36.1356 25.0243C36.0078 25.2613 35.9439 25.5395 35.9439 25.8589C35.9439 26.1835 36.0078 26.4668 36.1356 26.7089C36.2635 26.9459 36.4348 27.1288 36.6496 27.2576C36.8644 27.3812 37.1022 27.443 37.363 27.443C37.6238 27.443 37.8616 27.3812 38.0764 27.2576C38.2963 27.1288 38.4702 26.9459 38.598 26.7089C38.7259 26.4668 38.7898 26.186 38.7898 25.8666Z" fill="#020164"/>
  <path d="M31.8248 23.6818C32.1419 23.6818 32.4282 23.7513 32.6839 23.8904C32.9396 24.0244 33.1391 24.2279 33.2823 24.5009C33.4306 24.7739 33.5047 25.1062 33.5047 25.4977V27.9937H32.8144V25.5982C32.8144 25.1758 32.7095 24.8538 32.4998 24.6323C32.2902 24.4056 32.0038 24.2923 31.6407 24.2923C31.2725 24.2923 30.9784 24.4082 30.7585 24.64C30.5437 24.8718 30.4363 25.2092 30.4363 25.6523V27.9937H29.7383V22.2754H30.4363V24.3618C30.5744 24.1454 30.7636 23.978 31.004 23.8595C31.2495 23.741 31.5231 23.6818 31.8248 23.6818Z" fill="#020164"/>
  <path d="M26.9536 24.3354V26.8314C26.9536 27.0374 26.9971 27.1842 27.084 27.2718C27.171 27.3542 27.3219 27.3955 27.5366 27.3955H28.0506V27.9905H27.4216C27.0329 27.9905 26.7414 27.9003 26.5471 27.72C26.3527 27.5397 26.2556 27.2435 26.2556 26.8314V24.3354H25.7109V23.7558H26.2556V22.6895H26.9536V23.7558H28.0506V24.3354H26.9536Z" fill="#020164"/>
  <path d="M21.444 25.7121C21.444 25.846 21.4363 25.9877 21.421 26.1371H18.0611C18.0866 26.5544 18.2273 26.8815 18.483 27.1185C18.7438 27.3503 19.0583 27.4662 19.4265 27.4662C19.7282 27.4662 19.9788 27.3967 20.1783 27.2576C20.3828 27.1133 20.526 26.9227 20.6078 26.6857H21.3596C21.2471 27.0927 21.0221 27.425 20.6845 27.6826C20.347 27.935 19.9277 28.0612 19.4265 28.0612C19.0276 28.0612 18.6696 27.9711 18.3526 27.7908C18.0406 27.6105 17.7951 27.3555 17.6161 27.0258C17.4372 26.6909 17.3477 26.3045 17.3477 25.8666C17.3477 25.4287 17.4346 25.045 17.6085 24.7152C17.7823 24.3855 18.0253 24.1331 18.3372 23.958C18.6543 23.7777 19.0174 23.6875 19.4265 23.6875C19.8254 23.6875 20.1783 23.7751 20.4851 23.9502C20.7919 24.1254 21.0272 24.3675 21.1908 24.6766C21.3596 24.9806 21.444 25.3257 21.444 25.7121ZM20.7229 25.5653C20.7229 25.2974 20.6641 25.0681 20.5465 24.8775C20.4288 24.6818 20.2678 24.5349 20.0632 24.4371C19.8637 24.334 19.6413 24.2825 19.3958 24.2825C19.0429 24.2825 18.7412 24.3958 18.4906 24.6225C18.2452 24.8492 18.1045 25.1634 18.0687 25.5653H20.7229Z" fill="#020164"/>
  <path d="M14.6043 24.4467C14.727 24.2045 14.9009 24.0165 15.1259 23.8826C15.3561 23.7486 15.6348 23.6816 15.9621 23.6816V24.408H15.778C14.9955 24.408 14.6043 24.8356 14.6043 25.6908V27.9936H13.9062V23.7589H14.6043V24.4467Z" fill="#020164"/>
  <path d="M9.98872 28.0612C9.59494 28.0612 9.23696 27.9711 8.91478 27.7908C8.59771 27.6105 8.34712 27.3555 8.16302 27.0258C7.98403 26.6909 7.89453 26.3045 7.89453 25.8666C7.89453 25.4339 7.98658 25.0527 8.17069 24.723C8.35991 24.3881 8.61561 24.1331 8.93779 23.958C9.25997 23.7777 9.62051 23.6875 10.0194 23.6875C10.4183 23.6875 10.7788 23.7777 11.101 23.958C11.4232 24.1331 11.6763 24.3855 11.8604 24.7152C12.0497 25.045 12.1443 25.4287 12.1443 25.8666C12.1443 26.3045 12.0471 26.6909 11.8528 27.0258C11.6636 27.3555 11.4053 27.6105 11.078 27.7908C10.7507 27.9711 10.3876 28.0612 9.98872 28.0612ZM9.98872 27.443C10.2393 27.443 10.4745 27.3838 10.6944 27.2653C10.9144 27.1468 11.0908 26.9691 11.2237 26.7321C11.3618 26.4951 11.4309 26.2066 11.4309 25.8666C11.4309 25.5266 11.3644 25.2381 11.2314 25.0012C11.0985 24.7642 10.9246 24.589 10.7098 24.4757C10.495 24.3572 10.2623 24.298 10.0117 24.298C9.75603 24.298 9.52078 24.3572 9.306 24.4757C9.09632 24.589 8.92756 24.7642 8.79971 25.0012C8.67186 25.2381 8.60793 25.5266 8.60793 25.8666C8.60793 26.2118 8.6693 26.5029 8.79204 26.7398C8.91989 26.9768 9.08865 27.1545 9.29833 27.273C9.508 27.3864 9.73813 27.443 9.98872 27.443Z" fill="#020164"/>
  <path d="M6.12653 22.6445V27.9919H5.42847V24.0046L3.66413 27.9919H3.17319L1.40119 23.9968V27.9919H0.703125V22.6445H1.45488L3.41866 27.0646L5.38244 22.6445H6.12653Z" fill="#020164"/>
  <path d="M159.994 9.48438V14.8549H159.12V11.1767L157.493 14.8549H156.887L155.253 11.1767V14.8549H154.379V9.48438H155.322L157.194 13.6958L159.058 9.48438H159.994Z" fill="#020164"/>
  <path d="M149.954 14.9083C149.458 14.9083 149 14.7924 148.581 14.5605C148.166 14.3236 147.836 13.9964 147.591 13.5792C147.351 13.1567 147.23 12.6828 147.23 12.1573C147.23 11.6318 147.351 11.1605 147.591 10.7432C147.836 10.3259 148.166 10.0013 148.581 9.76952C149 9.53255 149.458 9.41406 149.954 9.41406C150.455 9.41406 150.913 9.53255 151.327 9.76952C151.746 10.0013 152.076 10.3259 152.316 10.7432C152.557 11.1605 152.677 11.6318 152.677 12.1573C152.677 12.6828 152.557 13.1567 152.316 13.5792C152.076 13.9964 151.746 14.3236 151.327 14.5605C150.913 14.7924 150.455 14.9083 149.954 14.9083ZM149.954 14.1433C150.307 14.1433 150.621 14.0634 150.897 13.9037C151.173 13.7389 151.388 13.507 151.542 13.2082C151.7 12.9043 151.779 12.554 151.779 12.1573C151.779 11.7606 151.7 11.4129 151.542 11.1141C151.388 10.8153 151.173 10.5861 150.897 10.4264C150.621 10.2667 150.307 10.1868 149.954 10.1868C149.601 10.1868 149.286 10.2667 149.01 10.4264C148.734 10.5861 148.517 10.8153 148.358 11.1141C148.205 11.4129 148.128 11.7606 148.128 12.1573C148.128 12.554 148.205 12.9043 148.358 13.2082C148.517 13.507 148.734 13.7389 149.01 13.9037C149.286 14.0634 149.601 14.1433 149.954 14.1433Z" fill="#020164"/>
  <path d="M140.453 12.1573C140.453 11.6318 140.573 11.1605 140.814 10.7432C141.059 10.3259 141.389 10.0013 141.803 9.76952C142.223 9.53255 142.68 9.41406 143.176 9.41406C143.744 9.41406 144.248 9.55573 144.688 9.83907C145.132 10.1173 145.455 10.5139 145.654 11.0291H144.603C144.465 10.7458 144.273 10.5345 144.028 10.3954C143.782 10.2564 143.499 10.1868 143.176 10.1868C142.823 10.1868 142.509 10.2667 142.233 10.4264C141.957 10.5861 141.739 10.8153 141.581 11.1141C141.427 11.4129 141.351 11.7606 141.351 12.1573C141.351 12.554 141.427 12.9017 141.581 13.2005C141.739 13.4993 141.957 13.7311 142.233 13.896C142.509 14.0557 142.823 14.1355 143.176 14.1355C143.499 14.1355 143.782 14.066 144.028 13.9269C144.273 13.7878 144.465 13.5766 144.603 13.2932H145.654C145.455 13.8084 145.132 14.2051 144.688 14.4833C144.248 14.7614 143.744 14.9005 143.176 14.9005C142.675 14.9005 142.217 14.7846 141.803 14.5528C141.389 14.3158 141.059 13.9887 140.814 13.5714C140.573 13.1541 140.453 12.6828 140.453 12.1573Z" fill="#020164"/>
  <path d="M138.404 14.9075C138.245 14.9075 138.112 14.8534 138.005 14.7452C137.897 14.637 137.844 14.5031 137.844 14.3434C137.844 14.1837 137.897 14.0498 138.005 13.9416C138.112 13.8334 138.245 13.7793 138.404 13.7793C138.557 13.7793 138.688 13.8334 138.795 13.9416C138.902 14.0498 138.956 14.1837 138.956 14.3434C138.956 14.5031 138.902 14.637 138.795 14.7452C138.688 14.8534 138.557 14.9075 138.404 14.9075Z" fill="#020164"/>
  <path d="M130.845 4.39648C132.048 4.39648 133.006 4.79213 133.717 5.58342C134.442 6.36234 134.804 7.438 134.804 8.8104V14.8563H131.674V9.23695C131.674 8.54457 131.496 8.00674 131.14 7.62346C130.784 7.24018 130.305 7.04854 129.704 7.04854C129.103 7.04854 128.624 7.24018 128.268 7.62346C127.912 8.00674 127.734 8.54457 127.734 9.23695V14.8563H124.586V4.50776H127.734V5.88015C128.053 5.42269 128.483 5.06413 129.023 4.80449C129.563 4.53249 130.17 4.39648 130.845 4.39648Z" fill="#020164"/>
  <path d="M111.098 9.66154C111.098 8.59824 111.294 7.66477 111.687 6.86111C112.092 6.05746 112.638 5.43926 113.325 5.00653C114.013 4.57379 114.78 4.35742 115.627 4.35742C116.351 4.35742 116.983 4.50579 117.523 4.80252C118.075 5.09926 118.499 5.48872 118.793 5.97091V4.50579H121.941V14.8544H118.793V13.3893C118.486 13.8714 118.057 14.2609 117.504 14.5576C116.964 14.8544 116.332 15.0027 115.608 15.0027C114.774 15.0027 114.013 14.7864 113.325 14.3536C112.638 13.9085 112.092 13.2842 111.687 12.4805C111.294 11.6645 111.098 10.7248 111.098 9.66154ZM118.793 9.68008C118.793 8.88879 118.572 8.26441 118.13 7.80695C117.701 7.34949 117.173 7.12075 116.547 7.12075C115.921 7.12075 115.387 7.34949 114.945 7.80695C114.516 8.25205 114.301 8.87025 114.301 9.66154C114.301 10.4528 114.516 11.0834 114.945 11.5532C115.387 12.0107 115.921 12.2394 116.547 12.2394C117.173 12.2394 117.701 12.0107 118.13 11.5532C118.572 11.0957 118.793 10.4714 118.793 9.68008Z" fill="#020164"/>
  <path d="M93.6365 12.4071H97.7604V14.8551H90.4883V1.83594H93.6365V12.4071Z" fill="#020164"/>
  <path d="M81.8649 4.35742C82.589 4.35742 83.2211 4.50579 83.7612 4.80252C84.3135 5.09926 84.7369 5.48872 85.0315 5.97091V4.50579H88.1797V14.8358C88.1797 15.7878 87.9894 16.6471 87.609 17.4137C87.2407 18.1926 86.67 18.8108 85.8968 19.2683C85.1358 19.7257 84.1846 19.9545 83.0432 19.9545C81.5212 19.9545 80.2877 19.5897 79.3427 18.8603C78.3976 18.1432 77.8576 17.1664 77.7226 15.93H80.8339C80.9321 16.3257 81.1653 16.6348 81.5335 16.8573C81.9017 17.0922 82.3558 17.2097 82.8959 17.2097C83.5464 17.2097 84.0619 17.0181 84.4424 16.6348C84.8351 16.2639 85.0315 15.6642 85.0315 14.8358V13.3707C84.7247 13.8529 84.3012 14.2485 83.7612 14.5576C83.2211 14.8544 82.589 15.0027 81.8649 15.0027C81.018 15.0027 80.2509 14.7864 79.5636 14.3536C78.8763 13.9085 78.3301 13.2842 77.9251 12.4805C77.5323 11.6645 77.3359 10.7248 77.3359 9.66154C77.3359 8.59824 77.5323 7.66477 77.9251 6.86111C78.3301 6.05746 78.8763 5.43926 79.5636 5.00653C80.2509 4.57379 81.018 4.35742 81.8649 4.35742ZM85.0315 9.68008C85.0315 8.88879 84.8106 8.26441 84.3687 7.80695C83.9391 7.34949 83.4114 7.12075 82.7854 7.12075C82.1595 7.12075 81.6256 7.34949 81.1837 7.80695C80.7541 8.25205 80.5394 8.87025 80.5394 9.66154C80.5394 10.4528 80.7541 11.0834 81.1837 11.5532C81.6256 12.0107 82.1595 12.2394 82.7854 12.2394C83.4114 12.2394 83.9391 12.0107 84.3687 11.5532C84.8106 11.0957 85.0315 10.4714 85.0315 9.68008Z" fill="#020164"/>
  <path d="M71.4431 4.39648C72.646 4.39648 73.6033 4.79213 74.3152 5.58342C75.0393 6.36234 75.4014 7.438 75.4014 8.8104V14.8563H72.2716V9.23695C72.2716 8.54457 72.0936 8.00674 71.7377 7.62346C71.3818 7.24018 70.9031 7.04854 70.3017 7.04854C69.7003 7.04854 69.2216 7.24018 68.8657 7.62346C68.5097 8.00674 68.3318 8.54457 68.3318 9.23695V14.8563H65.1836V4.50776H68.3318V5.88015C68.6509 5.42269 69.0805 5.06413 69.6205 4.80449C70.1605 4.53249 70.7681 4.39648 71.4431 4.39648Z" fill="#020164"/>
  <path d="M60.9716 3.43098C60.4193 3.43098 59.9652 3.27025 59.6092 2.94879C59.2656 2.61497 59.0938 2.20696 59.0938 1.72476C59.0938 1.23021 59.2656 0.8222 59.6092 0.500738C59.9652 0.166913 60.4193 0 60.9716 0C61.5117 0 61.9535 0.166913 62.2972 0.500738C62.6531 0.8222 62.8311 1.23021 62.8311 1.72476C62.8311 2.20696 62.6531 2.61497 62.2972 2.94879C61.9535 3.27025 61.5117 3.43098 60.9716 3.43098ZM62.5365 4.50664V14.8552H59.3883V4.50664H62.5365Z" fill="#020164"/>
  <path d="M30.095 9.51317C30.095 9.8099 30.0766 10.119 30.0398 10.4405H22.9149C22.964 11.0834 23.1665 11.5779 23.5225 11.9241C23.8907 12.258 24.3387 12.4249 24.8664 12.4249C25.6519 12.4249 26.1981 12.091 26.505 11.4234H29.8557C29.6838 12.1034 29.3708 12.7154 28.9167 13.2594C28.4749 13.8034 27.9164 14.23 27.2414 14.5391C26.5663 14.8482 25.8115 15.0027 24.9769 15.0027C23.9705 15.0027 23.0745 14.7864 22.289 14.3536C21.5035 13.9209 20.8898 13.3027 20.4479 12.4991C20.0061 11.6954 19.7852 10.7557 19.7852 9.68008C19.7852 8.60442 19.9999 7.66477 20.4295 6.86111C20.8714 6.05746 21.4851 5.43926 22.2706 5.00653C23.0561 4.57379 23.9582 4.35742 24.9769 4.35742C25.9711 4.35742 26.8548 4.56761 27.628 4.98798C28.4012 5.40835 29.0026 6.008 29.4322 6.78693C29.8741 7.56585 30.095 8.4746 30.095 9.51317ZM26.8732 8.67861C26.8732 8.13459 26.6891 7.70186 26.3209 7.3804C25.9526 7.05893 25.4924 6.8982 24.9401 6.8982C24.4123 6.8982 23.9643 7.05275 23.5961 7.36185C23.2402 7.67095 23.0193 8.10987 22.9333 8.67861H26.8732Z" fill="#020164"/>
  <path d="M18.4657 1.83594L15.0965 14.8551H11.2856L9.22362 6.28694L7.08801 14.8551H3.27706L0 1.83594H3.36911L5.22856 11.3129L7.52986 1.83594H10.991L13.2003 11.3129L15.0781 1.83594H18.4657Z" fill="#020164"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M104.409 14.7785C107.248 14.7785 109.549 12.4605 109.549 9.60114C109.549 6.74179 107.248 4.42383 104.409 4.42383C101.571 4.42383 99.2695 6.74179 99.2695 9.60114C99.2695 12.4605 101.571 14.7785 104.409 14.7785ZM105.259 7.72653H106.55V6.80859H102.488V7.76245H103.337C103.588 7.76245 103.793 7.80369 103.952 7.88617C104.11 7.96865 104.225 8.0804 104.296 8.22141H102.488V9.13935H104.267C104.191 9.27504 104.077 9.38546 103.927 9.4706C103.777 9.55308 103.58 9.59432 103.337 9.59432H102.488V10.4723H103.337C103.443 10.4723 103.525 10.4896 103.583 10.5242C103.64 10.5562 103.692 10.608 103.738 10.6799L104.73 12.3681C104.832 12.5384 104.998 12.6235 105.231 12.6235H106.485L105.214 10.6001C105.14 10.4883 105.056 10.3965 104.96 10.3247C104.867 10.2502 104.765 10.1903 104.653 10.1451C104.909 10.0333 105.118 9.89099 105.28 9.71805C105.444 9.5451 105.56 9.3522 105.628 9.13935H106.55V8.22141H105.628C105.595 8.12829 105.549 8.04049 105.489 7.95801C105.431 7.87287 105.355 7.79571 105.259 7.72653Z" fill="#020164"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M43.1252 0.369141H40.3828V4.55223C39.5499 4.05725 38.5786 3.77326 37.5415 3.77326C34.4487 3.77326 31.9414 6.29896 31.9414 9.41457C31.9414 12.5302 34.4487 15.0559 37.5415 15.0559C40.6344 15.0559 43.1416 12.5302 43.1416 9.41457C43.1416 9.26806 43.1361 9.12286 43.1252 8.97916V0.369141ZM40.6383 9.414C40.6383 11.1346 39.2537 12.5293 37.5457 12.5293C35.8377 12.5293 34.4531 11.1346 34.4531 9.414C34.4531 7.69344 35.8377 6.29865 37.5457 6.29865C39.2537 6.29865 40.6383 7.69344 40.6383 9.414Z" fill="#020164"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M56.7463 0.369141H54.0039V4.54992C53.1718 4.05636 52.202 3.77326 51.1665 3.77326C48.0737 3.77326 45.5664 6.29896 45.5664 9.41457C45.5664 12.5302 48.0737 15.0559 51.1665 15.0559C54.2594 15.0559 56.7666 12.5302 56.7666 9.41457C56.7666 9.25146 56.7598 9.08997 56.7463 8.93038V0.369141ZM54.2594 9.414C54.2594 11.1346 52.8748 12.5293 51.1668 12.5293C49.4588 12.5293 48.0742 11.1346 48.0742 9.414C48.0742 7.69344 49.4588 6.29865 51.1668 6.29865C52.8748 6.29865 54.2594 7.69344 54.2594 9.414Z" fill="#020164"/>
</svg>
)
export default MobileLogo;