import { gql } from '@apollo/client';

export const SAVEBASICDETAILS = gql`mutation BasicDetail($input: BasicDetailInput) {
  basicDetail(input: $input) {
    basicDetailErrors {
      message
    }
    message
    status
  }
}`

export const SAVELOCATIONDETAILS = gql`mutation UserLocationDetail($input: UserLocationDetailInput) {
  userLocationDetail(input: $input) {
    message
    status
    userLocationDetailErrors {
      message
    }
  }
}
`

export const UPDATELOCATIONDETAILS = gql`
mutation UpdateLocationDetails($input: UpdateLocationDetailsInput) {
  updateLocationDetails(input: $input) {
    UpdateLocationDetailError {
      message
    }
    message
    status
  }
}
`


export const SAVEEMPLOYEEDETAILS = gql`mutation UserEmploymentDetail($input: UserEmploymentDetailInput) {
  userEmploymentDetail(input: $input) {
    message
    saveUserEmploymentDetailsErrors {
      message
    }
    status
  }
}`


export const UPDATE_EMPLOYEE_DETAILS = gql`
mutation UpdateEmploymentDetail($input: UserEmploymentDetailInput) {
  updateEmploymentDetail(input: $input) {
    saveUserEmploymentDetailsErrors {
      message
    }
    message
    status
  }
}
`





export const SAVEEMPLOYMENTDETAILS = gql`mutation SaveUserEmploymentDetails($input: SaveUserEmploymentDetailsInput) {
  saveUserEmploymentDetails(input: $input) {
    message
    saveUserEmploymentDetailsErrors {
      message
    }
    status
    user {
      email
      id
      is_active
      name
      employment_type_id
      company_id
      mobile_number
      mobile_number_hash
      annual_income
    }
  }
}`

