
const ToolsNav = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M17.937 4.41406H15.1342C14.1022 4.41406 13.2656 5.25066 13.2656 6.28265V9.08553C13.2656 10.1175 14.1022 10.9541 15.1342 10.9541H17.937C18.9689 10.9541 19.8055 10.1175 19.8055 9.08553V6.28265C19.8055 5.25066 18.9689 4.41406 17.937 4.41406Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.47602 4.41406H5.67322C4.64126 4.41406 3.80469 5.25066 3.80469 6.28265V9.08553C3.80469 10.1175 4.64126 10.9541 5.67322 10.9541H8.47602C9.50798 10.9541 10.3446 10.1175 10.3446 9.08553V6.28265C10.3446 5.25066 9.50798 4.41406 8.47602 4.41406Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.937 13.874H15.1342C14.1022 13.874 13.2656 14.7106 13.2656 15.7426V18.5455C13.2656 19.5775 14.1022 20.4141 15.1342 20.4141H17.937C18.9689 20.4141 19.8055 19.5775 19.8055 18.5455V15.7426C19.8055 14.7106 18.9689 13.874 17.937 13.874Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.47602 13.874H5.67322C4.64126 13.874 3.80469 14.7106 3.80469 15.7426V18.5455C3.80469 19.5775 4.64126 20.4141 5.67322 20.4141H8.47602C9.50798 20.4141 10.3446 19.5775 10.3446 18.5455V15.7426C10.3446 14.7106 9.50798 13.874 8.47602 13.874Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ToolsNav;