import { ApolloClient, InMemoryCache, createHttpLink,ApolloLink,from } from '@apollo/client';
import { getToken,logOut } from './utils';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error'; 
import env from './config/config';


const httpLink = createHttpLink({
  uri: env.API_BASE_URL
});

const authLink = new ApolloLink((operation, forward) => {
    // get the authentication token from local storage if it exists
    const token = getToken();
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }));

  return forward(operation);
})

const errorLink =  onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
          console.log(
              " [GraphQL error]: Message", message, ", Location: ", locations, ", Path: ", path)
      );
  }
  if (networkError) {
    let final_data = JSON.parse(JSON.stringify(networkError));
    if(final_data && final_data.status === 401){
      logOut();
    }
      console.log(" [Network error]:", networkError)
  };

});

export const client = new ApolloClient({
  link: from([authLink,httpLink,errorLink]),
  cache: new InMemoryCache(),
});


