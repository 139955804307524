import { gql } from "@apollo/client";

export const ApplyTVSLoan = gql`
mutation ApplyTVSLoan($input: ApplyLTLoanInput) {
  applyTVSLoan(input: $input) {
    applyTVSLoanErrors {
      message
    }
    message
    status
  }
}
`