import { gql } from '@apollo/client';


export const PROFILEDETAILSQUERY = gql`query UserDetails {
  userDetails {
    id
    mobileNumber
    firstName
    middleName
    lastName
    email
    appId
    panCardNumber
    dateOfBirth
    userstatusId
    annualIncome
    expectedLoanAmount
    tenure
    organisationId
    organisationName
    employmentType {
      id
      name
      isActive
    }
    address {
      id
      address
      pincode
      city
      state
      isSameAsPermanent
      residenceTypeId
      addresstypeId
      residenceType {
        id
        name
        isActive
      }
      addresstype {
        id
        name
      }
    }
    sourcelogin {
      id
      name
    }
    salaryMode{
      id
      name
    }
  }
}
`