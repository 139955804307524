import { gql } from '@apollo/client';

export const BANKLIST = gql`query LenderMatch($loanAmount: Int, $tenure: Int) {
  lenderMatch(loanAmount: $loanAmount, tenure: $tenure) {
    message
    status
    data {
      id
      lenderName
      lenderTitle
      maxLoanEligibilityAmount
      minLoanEligibilityAmount
      interestRate
      discountedProcessingFee
      processingFee
      monthlyEmi
      isResolvingCredit
    }
    revolvingCredits {
      id
      lenderName
      lenderTitle
      maxLoanEligibilityAmount
      interestRate
      processingFee
      discountedProcessingFee
      monthlyEmi
      isResolvingCredit
      prePaymentCharges
    }
    total
  }
}`